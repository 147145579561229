import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";

const getUsers = async (group_id: number) => {
  const params = group_id ? `?group_id=${group_id}` : "";
  const resp = await request(`/v1/user${params}`, "GET");
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const createUser = async (body: string) => {
  const resp = await request(`/v1/create-user`, "POST", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const updateUser = async (body: string) => {
  const resp = await request(`/v1/update-user`, "POST", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const getRoles = async (group_id: number) => {
  const params = `${group_id ? `group_id=${group_id}` : ""}`;
  const resp = await request(`/v1/roles?${params}`, "GET");
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const createRole = async (requestBody: string) => {
  const resp = await request(`/v1/roles`, "POST", requestBody);
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const getUserById = async (user_id?: number | undefined) => {
  const params = user_id ? `?user_id=${user_id}` : "";
  const resp = await request(`/v1/user-by-id${params}`, "GET");
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const getUserByEmail = async (email?: string | undefined) => {
  const params = `?email=${email}`;
  const resp = await request(`/v1/user-by-email${params}`, "GET");
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const deleteRole = async (role_id: number) => {
  const resp = await request(`/v1/roles?role_id=${role_id}`, "DELETE");
  if (!resp.ok && resp.status !== 406) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const editLocalRole = async (role: {
  role_name: string;
  role_id: number;
  scopes: string[];
}) => {
  const body = JSON.stringify(role);
  const resp = await request(`/v1/roles`, "PATCH", body);
  if (!resp.ok && resp.status !== 406) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const getUserScopes = async (group_id: number) => {
  const params = `?group_id=${group_id}`;
  const resp = await request(`/v1/user-scopes${params}`, "GET");
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const users = {
  getUsers,
  createUser,
  updateUser,
  getRoles,
  createRole,
  getUserById,
  getUserByEmail,
  deleteRole,
  editLocalRole,
  getUserScopes,
};
