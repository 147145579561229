import { useAppSelector } from "app/hooks";
import { userRolesEnum } from "lib/scopesEnum";

export const getMainMenu = (
  scopes: { [k: string]: boolean },
  isGlobalAdmin: boolean,
  showControlPanel: boolean
) => {
  // "/control" дешборд может быть выключен флагом в асуно
  // поэтому может быть ситуация когда туда есть доступ, но страница недоступна
  if (isGlobalAdmin || !scopes) {
    return showControlPanel ? "/control" : "/substations";
  }

  const menuMap: { [k: string]: boolean } = {
    "/control": scopes[userRolesEnum.SCHEMA_CONTROL],
    "/substations": scopes[userRolesEnum.SUBSTATIONS_VIEW],
    "/schedule": scopes[userRolesEnum.SHEDULE_VIEW],
    "/chart": scopes[userRolesEnum.CHART_VIEW],
    "/map": scopes[userRolesEnum.MAP_VIEW],
    "/indications": scopes[userRolesEnum.INDICATIONS_VIEW],
    "/burning_hours": scopes[userRolesEnum.BURNING_HOURS_VIEW],
    "/reporting": scopes[userRolesEnum.CONSUMPTION_VIEW],
    "/consumption": scopes[userRolesEnum.CONSUMPTION_VIEW],
    "/users": scopes[userRolesEnum.USERS_VIEW],
  };

  const menu = Object.keys(menuMap).filter((i) => menuMap[i]) ?? "/schedule";

  if (menu[0] === "/control" && !showControlPanel) {
    return menu[1];
  }

  return menu[0];
};

export const useSubMenu = () => {
  const currentUser = useAppSelector((state) => state.currentUser.user);
  const userScopes = useAppSelector((state) => state.currentUser.scopes);
  const current = useAppSelector((state) => state.lightingLineGroups.current);
  const scopes = userScopes[current];

  const isGlobalAdmin = currentUser?.global_admin;

  if (isGlobalAdmin) {
    //суперадмину все и везде можно
    return true;
  } else if (scopes) {
    return (
      scopes[userRolesEnum.INDICATIONS_VIEW] ||
      scopes[userRolesEnum.BURNING_HOURS_VIEW] ||
      scopes[userRolesEnum.CONSUMPTION_VIEW] ||
      scopes[userRolesEnum.CONSUMPTION_VIEW]
    );
  }
};
