import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";

// Получение данных для отчёта по часам горения
const getBurningHoursData = async (body: string) => {
  const resp = await request(`/v1/get-report-burning-hours`, "POST", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Формирование и создание отчёта по часам горения в формате .xlsx
const сreateReportBurningHours = async (body: string) => {
  const resp = await request(`/v1/get-report-burning-hours/xlsx`, "POST", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.blob();
  return data;
};

export const burningHours = {
  getBurningHoursData,
  сreateReportBurningHours,
};
