import { createSlice, current } from "@reduxjs/toolkit";

// actions
import { getLamps, getGroups } from "../actions";
import { changeState } from "feat/substations/actions";

// types
import { IError, ILamp, Maybe, ILampsGroup } from "types";

interface IInitialState {
  loadingLamps: boolean;
  loadingGroups: boolean;
  lamps: Maybe<Record<string, ILamp>>;
  groups: Maybe<Record<string, ILampsGroup>>;
  error: IError;
}

const initialState: IInitialState = {
  loadingLamps: false,
  loadingGroups: false,
  lamps: null,
  groups: null,
  error: null,
};

export const lightningControlSlice = createSlice({
  name: "lightningControl",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Получение списка светильников
    builder.addCase(getLamps.pending, (state) => {
      state.loadingLamps = true;
      state.error = null;
    });
    builder.addCase(getLamps.fulfilled, (state, action) => {
      state.loadingLamps = false;
      state.lamps = action.payload;
    });
    builder.addCase(getLamps.rejected, (state) => {
      state.loadingLamps = false;
      state.error = "Произошла ошибка при загрузке";
    });

    // Получение списка групп
    builder.addCase(getGroups.pending, (state) => {
      state.loadingGroups = true;
      state.error = null;
    });
    builder.addCase(getGroups.fulfilled, (state, action) => {
      state.loadingGroups = false;
      state.groups = action.payload;
    });
    builder.addCase(getGroups.rejected, (state) => {
      state.loadingGroups = false;
      state.error = "Произошла ошибка при загрузке";
    });

    // Получаем обновление с ws
    builder.addCase(changeState.fulfilled, (state, action) => {
      const newColor = action.payload?.color;
      const groupNum = action.payload?.group_num;
      const states: {
        group_num?: number | null | undefined;
        light_on: boolean;
      }[] = action.payload?.states;
      const lineId = action.payload?.line_id;
      const scheduleId = action.payload?.schedule_id;
      const scheduleName = action.payload?.schedule_name;
      const mode = action.payload?.ctl_mode;

      const groups = current(state).groups;
      const lamps = Object.values(current(state).lamps ?? {});

      // Поменяли цвет группы светильников
      if (newColor && groups && groups[groupNum].ll_id === lineId) {
        state.groups = {
          ...groups,
          [groupNum]: { ...groups[groupNum], color: newColor },
        };
        state.lamps = lamps.reduce((acc, lamp) => {
          const color = groups[groupNum].lamps.includes(lamp.id)
            ? newColor
            : lamp.color;
          return { ...acc, [lamp.id]: { ...lamp, color } };
        }, {});
      }

      // управляем реле группы светильников
      if (states && groups) {
        const updatedGroups = states.reduce((acc, { group_num, light_on }) => {
          if (group_num) {
            return {
              ...acc,
              [group_num]: { ...groups[group_num], relay: light_on },
            };
          }
          return acc;
        }, {});

        state.groups = { ...groups, ...updatedGroups };
      }

      // Поменяли режимом работы группы светильников
      if (scheduleId && groups) {
        state.groups = {
          ...groups,
          [groupNum]: {
            ...groups[groupNum],
            schedule_id: scheduleId,
            schedule_name: scheduleName,
            mode,
          },
        };
      }
    });
  },
});
