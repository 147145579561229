import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";

// Получение списка светильников
const getLamps = async (lineId: string) => {
  const resp = await request(
    `/lamps/substation-schema/${lineId}?fmt=object`,
    "GET"
  );
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Получение списка групп
const getGroups = async (lineId: string) => {
  const resp = await request(
    `/lamp-groups/substation-schema/${lineId}?fmt=object`,
    "GET"
  );
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const control = {
  getLamps,
  getGroups,
};
