import { createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";

// actions
import { getLightningLinesState, updateLightningLinesState } from "../actions";
import {
  getCurrentModemState,
  getLightingLineDevices,
} from "feat/devices/actions";
import { changeState } from "feat/substations/actions";

// types
import { IError, ILampsGroup, ILines, ILineState, Maybe } from "types";
import { ILineMode } from "lib/enums";

interface IInitialState {
  currentLine: Maybe<ILines>;
  linesState: Record<string, ILineState>;
  lastStateUpdateTime: string;
  lineModeChange: Maybe<{
    id: string;
    name: string;
    mode?: ILineMode;
    lampGroup?: ILampsGroup;
  }>;
  modemInfoOpen: boolean;
  loading: boolean;
  updatingModemId: Maybe<string>;
  loadingDevices: boolean;
  error: IError;
}

const initialState: IInitialState = {
  currentLine: null,
  linesState: {},
  lastStateUpdateTime: "",
  lineModeChange: null,
  modemInfoOpen: false,
  loading: false,
  updatingModemId: null,
  loadingDevices: false,
  error: null,
};

export const lightingLineSlice = createSlice({
  name: "lightingLine",
  initialState,
  reducers: {
    setLineModeChangeOpen: (
      state,
      action: PayloadAction<
        Maybe<{
          id: string;
          name: string;
          mode?: ILineMode;
          lampGroup?: ILampsGroup;
        }>
      >
    ) => {
      state.lineModeChange = action.payload;
    },
    setModemInfoOpen: (state, action) => {
      state.modemInfoOpen = action.payload;
    },
    setCurrentLine: (state, action) => {
      state.currentLine = action.payload;
    },
    cleanLinesState: (state) => {
      state.linesState = {};
    },
  },
  extraReducers: (builder) => {
    // Получение списка состоянии линии освещения
    builder.addCase(getLightningLinesState.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLightningLinesState.fulfilled, (state, action) => {
      const newState = action.payload;

      state.loading = false;
      state.linesState = newState.reduce(
        (acc, lineState) => ({
          ...acc,
          [lineState.line_id]: lineState,
        }),
        {}
      );
      state.lastStateUpdateTime = moment().format("HH:mm:ss");
    });
    builder.addCase(getLightningLinesState.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    // обновление списка состоянии линии освещения
    builder.addCase(updateLightningLinesState.pending, (state) => {
      state.error = null;
    });
    builder.addCase(updateLightningLinesState.fulfilled, (state, action) => {
      state.linesState = action.payload.reduce(
        (acc, state) => ({ ...acc, [state.line_id]: state }),
        {}
      );
      state.lastStateUpdateTime = moment().format("HH:mm:ss");
    });
    builder.addCase(updateLightningLinesState.rejected, (state) => {
      state.error = "Произошла ошибка при загрузке";
    });

    // Уведомление о изменении состоянии линии освещения и светильников
    builder.addCase(changeState.fulfilled, (state, action) => {
      const lineId = action.payload?.line_id;
      const linesState = current(state).linesState;
      const color = action.payload?.color;
      const mode = action.payload?.mode;
      const groupNum = action.payload?.group_num;
      const states: {
        group_num?: number | null | undefined;
        light_on: boolean;
      }[] = action.payload?.states;
      const ts = action.payload?.ts;

      if (
        action.payload &&
        !(
          action.payload?.isPhotorelay ||
          action.payload?.color ||
          action.payload?.states ||
          groupNum
        )
      ) {
        state.linesState = {
          ...linesState,
          [lineId]: {
            ...action.payload,
          },
        };
      }

      // Поменяли цвет группы светильников
      if (color) {
        state.linesState = {
          ...linesState,
          [lineId]: {
            ...linesState[lineId],
            lamp_groups: {
              ...linesState[lineId].lamp_groups,
              color: {
                ...linesState[lineId].lamp_groups?.color,
                [groupNum]: { ...color, ts },
              },
            },
          },
        };
      }

      // управляем реле группы светильников
      if (states) {
        const updatedRelay = states.reduce((acc, { group_num, light_on }) => {
          if (group_num) {
            return {
              ...acc,
              [group_num]: { val: light_on, ts },
            };
          }
          return acc;
        }, {});

        state.linesState = {
          ...linesState,
          [lineId]: {
            ...linesState[lineId],
            lamp_groups: {
              ...linesState[lineId].lamp_groups,
              relay: {
                ...linesState[lineId].lamp_groups?.relay,
                ...updatedRelay,
              },
            },
          },
        };
      }

      // управление режимом работы группы светильников
      if (mode && groupNum) {
        state.linesState = {
          ...linesState,
          [lineId]: {
            ...linesState[lineId],
            lamp_groups: {
              ...linesState[lineId].lamp_groups,
              mode: {
                ...linesState[lineId].lamp_groups?.mode,
                [groupNum]: mode,
              },
            },
          },
        };
      }
    });

    // Обновляем уровень сигнала модема
    builder.addCase(getCurrentModemState.pending, (state, action) => {
      state.updatingModemId = action.meta.arg.modem_id;
      state.error = null;
    });
    builder.addCase(getCurrentModemState.fulfilled, (state, action) => {
      state.updatingModemId = null;

      let lineId = action.payload?.ll_id;
      let currentLink = action.payload?.link;
      let linesState = current(state).linesState;
      let currentLine = linesState[lineId];
      let link = currentLine.link.map((item) =>
        item.modem.id === currentLink.modem.id ? currentLink : item
      );

      if (action.payload) {
        state.linesState = {
          ...linesState,
          [lineId]: {
            ...currentLine,
            link,
          },
        };
      }
    });
    builder.addCase(getCurrentModemState.rejected, (state) => {
      state.updatingModemId = null;
      state.error = "Произошла ошибка при загрузке";
    });

    // подгружаем устройства ЛО
    builder.addCase(getLightingLineDevices.pending, (state) => {
      state.loadingDevices = true;
      state.error = null;
    });
    builder.addCase(getLightingLineDevices.fulfilled, (state, action) => {
      const currentLine = current(state).currentLine;

      const explData = action.payload.data;

      state.currentLine = { ...currentLine, ...explData };
      state.loadingDevices = false;
    });
    builder.addCase(getLightingLineDevices.rejected, (state) => {
      state.loadingDevices = false;
      state.error = "Произошла ошибка при загрузке";
    });
  },
});
