import { useState } from "react";
import { useAppSelector } from "app/hooks";
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

//components
import { Menu } from "antd";
import { ScheduleIcon } from "ui/Icons";
import {
  AreaChartOutlined,
  GlobalOutlined,
  ThunderboltOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";

//utils
import { useRole } from "utils/useRole";
import { userRolesEnum } from "lib/scopesEnum";
import { useSubMenu } from "utils/getMainMenu";

export const BottomMenu: React.FC = () => {
  const location = useLocation();
  const classes = useStyles();
  const subMenu = useSubMenu();

  const mobileMenu = useAppSelector((state) => state.mobileMenu);
  const [selectedKeys, setSelectedKeys] = useState([location.pathname]);

  const onKeyClick = ({ key }: { key: string }) => setSelectedKeys([key]);

  return (
    <Menu
      theme="light"
      mode="horizontal"
      onClick={onKeyClick}
      selectedKeys={selectedKeys}
      className={classNames(classes.main, {
        [classes.hiddenMenu]: mobileMenu,
      })}
    >
      {useRole(userRolesEnum.SUBSTATIONS_VIEW) ? (
        <Menu.Item key="/substations" icon={<ThunderboltOutlined />}>
          <Link to="/substations">
            <FormattedMessage
              id="app.substations"
              defaultMessage="Substations"
            />
          </Link>
        </Menu.Item>
      ) : null}

      {useRole(userRolesEnum.MAP_VIEW) ? (
        <Menu.Item key="/map" icon={<GlobalOutlined />}>
          <Link to="/map">
            <FormattedMessage id="app.map" defaultMessage="Map" />
          </Link>
        </Menu.Item>
      ) : null}

      {useRole(userRolesEnum.CHART_VIEW) ? (
        <Menu.Item key="/chart" icon={<AreaChartOutlined />}>
          <Link to="/chart">
            <FormattedMessage id="app.chart" defaultMessage="Chart" />
          </Link>
        </Menu.Item>
      ) : null}

      {useRole(userRolesEnum.SHEDULE_VIEW) ? (
        <Menu.Item key="/schedule" icon={<ScheduleIcon />}>
          <Link to="/schedule">
            <FormattedMessage id="app.schedule" defaultMessage="Schedule" />
          </Link>
        </Menu.Item>
      ) : null}

      {subMenu ? (
        <Menu.Item key="/reports" icon={<FileDoneOutlined />}>
          <Link to="/reports">
            <FormattedMessage id="app.reports" defaultMessage="Reports" />
          </Link>
        </Menu.Item>
      ) : null}
    </Menu>
  );
};

const useStyles = makeStyles({
  main: {
    zIndex: 100,
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
    paddingTop: 15,
    borderTop: "1px solid #00000073",
    backgroundColor: "#fafafa",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    "& .ant-menu-item": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "0 0 !important",
    },
    "& .ant-menu-title-content": {
      marginLeft: "0px !important",
    },
    "& span": {
      fontSize: "8pt",
      margin: "-5px 0px !important",
    },
    "& .ant-menu-item a": {
      color: "#00000073 !important",
    },
    "& svg": {
      height: 16,
      width: 16,
      color: "#00000073",
    },
    "&::before, &::after": {
      display: "none",
    },
    "@media (min-width: 768px)": {
      display: "none",
    },
    "& .ant-menu-item-selected": {
      "& svg": {
        color: "var(--antd-wave-shadow-color)",
      },
      "& a": {
        color: "var(--antd-wave-shadow-color) !important",
      },
    },
  },
  hiddenMenu: {
    display: "none",
  },
});
