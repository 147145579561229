import { createSlice, current } from "@reduxjs/toolkit";
import moment from "moment";

// actions
import {
  getLightingLineSchedule,
  deleteLightingLineSchedule,
  getLightingLineSunSchedule,
  setEditDay,
} from "../actions";

// types
import { IError, IGroupSchedule, ISunSchedule } from "types";

// utils
import { parsePolarNightPeriod } from "../utils/parsePolarNightPeriod";

interface IInitialState {
  schedule: IGroupSchedule[];
  sunSchedule: ISunSchedule[];
  sunScheduleLoading: boolean;
  currentMonth: string;
  editDay: number;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  schedule: [],
  sunSchedule: [],
  sunScheduleLoading: false,
  currentMonth: moment().format(),
  editDay: 0,
  loading: false,
  error: null,
};

export const lightingLineScheduleSlice = createSlice({
  name: "lightingLineSchedule",
  initialState,
  reducers: {
    setCurrentMonth: (state, action) => {
      state.currentMonth = action.payload;
    },
    cleanSchedule: (state) => {
      state.schedule = [];
      state.sunSchedule = [];
    },
  },
  extraReducers: (builder) => {
    // Получение списка расписаний по линии освещения
    builder.addCase(getLightingLineSchedule.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLightingLineSchedule.fulfilled, (state, action) => {
      state.loading = false;
      state.schedule = action.payload
        .map((item) => parsePolarNightPeriod(item))
        .flat();
    });
    builder.addCase(getLightingLineSchedule.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    // Получение списка астрорасписаний для заданного периода времени
    builder.addCase(getLightingLineSunSchedule.pending, (state) => {
      state.sunScheduleLoading = true;
      state.error = null;
    });
    builder.addCase(getLightingLineSunSchedule.fulfilled, (state, action) => {
      state.sunScheduleLoading = false;
      state.sunSchedule = action.payload;
    });
    builder.addCase(getLightingLineSunSchedule.rejected, (state) => {
      state.sunScheduleLoading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    // Удаление расписания
    builder.addCase(deleteLightingLineSchedule.fulfilled, (state, action) => {
      state.schedule = current(state).schedule.filter((schedule) => {
        if (schedule?.id) {
          return schedule.id !== action.payload;
        }
        return schedule.sched_id !== action.payload;
      });
    });

    // Открываем модалки на странице расписания
    builder.addCase(setEditDay, (state, action) => {
      state.editDay = action.payload;
    });
  },
});
