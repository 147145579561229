import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";

// Получение всех конфигураций
const getAllConfigurations = async () => {
  const resp = await request("/v1/devices/configurations", "GET");
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Создание конфигурации
const addConfiguration = async (body: string) => {
  const resp = await request("/v1/devices/configurations", "POST", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// редактирование конфигурации
const updConfiguration = async (config_id: number, name: string) => {
  const body = JSON.stringify(name);
  const resp = await request(
    `/v1/devices/configurations/${config_id}`,
    "PUT",
    body
  );
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Удаление конфигурации
const delConfiguration = async (config_id: number) => {
  const resp = await request(
    `/v1/devices/configurations/${config_id}`,
    "DELETE"
  );
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Получить конфигурацию группы
const getGroupConfiguration = async (group_id: number) => {
  const resp = await request(
    `/v1/devices/configurations/group/${group_id}`,
    "GET"
  );
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Настроить конфигурацию группы
const updGroupConfiguration = async (group_id: number, config_id: number) => {
  const body = JSON.stringify(config_id);
  const resp = await request(
    `/v1/devices/configurations/group/${group_id}`,
    "POST",
    body
  );
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Получить конфигурацию Линии освещения
const getLineConfiguration = async (ll_id: string) => {
  const resp = await request(`/v1/devices/configurations/line/${ll_id}`, "GET");
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Настроить конфигурацию Линии освещения
const updLineConfiguration = async (group_id: number, ll_id: string) => {
  const params = `${group_id}?config_id=${ll_id}`;
  const resp = await request(
    `/v1/devices/configurations/line/${params}`,
    "PUT"
  );
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Получить поля, которые необходимо заполнить для подключения устройств по данной конфигурации
const getConnectionFields = async (config_id: number) => {
  const resp = await request(
    `/v1/devices/configurations/${config_id}/connection-fields`,
    "GET"
  );
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Подключить новые устройства к ШУНО.
// Работает только для тех ШУНО, к которым ещё не подключены устройства.
const connectDevices = async (ll_id: string, body: string) => {
  const resp = await request(`/v1/devices/line/${ll_id}/connect`, "POST", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Замена модема
const replaceModem = async (ll_id: string, body: string) => {
  const resp = await request(
    `/v1/devices/line/${ll_id}/replace-modem-id`,
    "POST",
    body
  );
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Меняет серийный номер счётчика и / или управляющего устройства.
// Позволяет сделать замену на устройство той же модели. Для смены модели устройста нужно менять конфигурацию шкафа.
const replaceDevice = async (ll_id: string, body: string) => {
  const resp = await request(
    `/v1/devices/line/${ll_id}/replace-device-ids`,
    "POST",
    body
  );
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Заменить устройства ШУНО, опционально - поменять конфигурацию шкафа.
// Работает только для тех ШУНО, к которым подключены устройства.
const replaceDevices = async (ll_id: string, body: string) => {
  const resp = await request(`/v1/devices/line/${ll_id}/replace`, "POST", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Получить поля, которые необходимо заполнить для замены устройств по данной конфигурации
const getReplacementFields = async (config_id: number) => {
  const resp = await request(
    `/v1/devices/configurations/${config_id}/replacement-fields`,
    "GET"
  );
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const configurations = {
  getAllConfigurations,
  addConfiguration,
  updConfiguration,
  delConfiguration,
  getGroupConfiguration,
  updGroupConfiguration,
  getLineConfiguration,
  updLineConfiguration,
  getConnectionFields,
  connectDevices,
  replaceModem,
  replaceDevice,
  replaceDevices,
  getReplacementFields,
};
