import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";

// components
import { PageHeader as Header, PageHeaderProps } from "antd";

// utils
import { history } from "utils/history";

interface IProps {
  withBack?: boolean;
}

export const PageHeader: React.FC<IProps & PageHeaderProps> = (props) => {
  const classes = useStyles();

  const onArrowButtonClick = () => {
    history.goBack();
  };

  return (
    <Header
      className={classNames(classes.header, {})}
      onBack={props.withBack ? onArrowButtonClick : props.onBack}
      {...props}
    ></Header>
  );
};

const useStyles = makeStyles({
  header: {
    padding: "24px",
    paddingBottom: 0,
    "@media (max-width: 768px)": {
      padding: "8px",
      paddingLeft: 6,
      "& .ant-page-header-heading-title span": {
        fontSize: "18px",
      },
    },
    "@media (max-width: 1239px)": {
      "@media (min-width: 768px)": {
        paddingTop: "16px",
        paddingLeft: "16px",
      },
    },
    "& .ant-page-header-footer": {
      marginTop: 0,
    },
  },
});
