//style
import style from "./AppLayout.module.css";

export const AppLayout: React.FC = ({ children }) => {
  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  };
  window.addEventListener("resize", appHeight);
  appHeight();

  return <div className={style.appLayout}>{children}</div>;
};
