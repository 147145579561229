import { createSlice } from "@reduxjs/toolkit";

//actions
import { сreateReportBurningHours } from "feat/burningHours/actions";
import {
  createReportConsumptionSlices,
  getLightningLineConsForecastXlsx,
} from "feat/reporting/actions";
import { createScheduleToFile } from "feat/schedule/actions";
import { getLightningLineReadoutReport } from "feat/chart/actions";
import { createReportIndications } from "feat/indications/actions";

//types
import { IError } from "types";

interface IInitialState {
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  loading: false,
  error: null,
};

//Лоадер загрузки отчетов
export const downloadingReportsSlice = createSlice({
  name: "downloadingReports",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //часы горения
    builder.addCase(сreateReportBurningHours.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(сreateReportBurningHours.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(сreateReportBurningHours.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //расписание
    builder.addCase(createScheduleToFile.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createScheduleToFile.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createScheduleToFile.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //графики
    builder.addCase(getLightningLineReadoutReport.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLightningLineReadoutReport.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getLightningLineReadoutReport.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //потребление
    builder.addCase(getLightningLineConsForecastXlsx.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLightningLineConsForecastXlsx.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(getLightningLineConsForecastXlsx.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //отчетность
    builder.addCase(createReportConsumptionSlices.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createReportConsumptionSlices.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createReportConsumptionSlices.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //показания
    builder.addCase(createReportIndications.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(createReportIndications.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createReportIndications.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });
  },
});
