import { createAsyncThunk } from "@reduxjs/toolkit";
import { intl } from "lib/locale";
import moment from "moment";

// types
import {
  ThunkAPI,
  ILineRequest,
  IConsumptionSliceReqest,
  ILightningLineConsForecast,
  ITariff,
} from "types";

// Получение среза показаний по энергопотреблению за выбранный период
// Потребление - факт
export const getLightningLineConsumptionSlices = createAsyncThunk<
  IConsumptionSliceReqest,
  ILineRequest,
  ThunkAPI
>(
  "objects/getLightningLineConsumptionSlices",
  async ({ from, to, lines, tariff }, { extra: { api }, rejectWithValue }) => {
    const summ = tariff === ITariff.ONEZONE ? true : false;
    const body = JSON.stringify({ from, to, lines, summ });

    const request =
      tariff === "default"
        ? api.reporting.getLightningLineConsumptionSlicesDefault
        : api.reporting.getLightningLineConsumptionSlices;

    try {
      const data = await request(body);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Формирование и создание отчётности в формате .xlsx
// Потребление - факт
export const createReportConsumptionSlices = createAsyncThunk<
  null,
  ILineRequest,
  ThunkAPI
>(
  "objects/createReportConsumptionSlices",
  async (
    { lines, from, to, tariff },
    { extra: { api }, rejectWithValue, getState }
  ) => {
    const { group_name } = getState().lightingLineGroups.currentGroup;

    const summ = tariff === ITariff.ONEZONE ? true : false;
    const reqBody = JSON.stringify({ lines, from, to, summ });
    const name = `${group_name} - ${intl.formatMessage({
      id: "reporting.fact-name",
      defaultMessage: "Consumption - fact",
    })} ${moment(from).format("DD.MM.YYYY")}-${moment(to).format(
      "DD.MM.YYYY"
    )}.xlsx`;

    const request =
      tariff === "default"
        ? api.reporting.createReportConsumptionSlicesDefault
        : api.reporting.createReportConsumptionSlices;

    try {
      const data = await request(reqBody);

      var xlsxURL = window.URL.createObjectURL(data);
      let tempLink = document.createElement("a");
      tempLink.href = xlsxURL;
      tempLink.setAttribute("download", name);
      tempLink.click();

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получение прогноза потребления по линиям освещения
// Потребление - прогноз
export const getLightningLineConsForecast = createAsyncThunk<
  ILightningLineConsForecast,
  ILineRequest,
  ThunkAPI
>(
  "objects/getLightningLineConsForecast",
  async (
    { from, to, lines, forecast },
    { extra: { api }, rejectWithValue }
  ) => {
    const body = JSON.stringify({
      against: from,
      to: to,
      month: forecast,
      lines,
    });
    try {
      const data = await api.reporting.getLightningLineConsForecast(body);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Формирование и создание отчётности в формате .xlsx
// Потребление - прогноз
export const getLightningLineConsForecastXlsx = createAsyncThunk<
  null,
  ILineRequest,
  ThunkAPI
>(
  "objects/getLightningLineConsForecastXlsx",
  async (
    { lines, from, to, forecast },
    { extra: { api }, rejectWithValue, getState }
  ) => {
    const { group_name } = getState().lightingLineGroups.currentGroup;

    const body = JSON.stringify({
      against: from,
      to: to,
      month: forecast,
      lines,
    });

    const name = `${group_name} - ${intl.formatMessage({
      id: "reporting.forecast-name",
      defaultMessage: "Consumption - forecast",
    })} ${moment(from).format("DD.MM.YYYY")}-${moment(to).format(
      "DD.MM.YYYY"
    )}.xlsx`;

    try {
      const data = await api.reporting.getLightningLineConsForecastXlsx(body);

      var xlsxURL = window.URL.createObjectURL(data);
      let tempLink = document.createElement("a");
      tempLink.href = xlsxURL;
      tempLink.setAttribute("download", name);
      tempLink.click();

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
