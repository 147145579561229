import { useAppSelector, useAppDispatch } from "app/hooks";
import { makeStyles } from "@material-ui/styles";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

//utils
import { useWebsocket } from "common/WebsocketProvider";
import { setRootPage } from "utils/setRootPage";

interface IProps {
  mobileMenu?: boolean;
}

export const GroupSelect: React.FC<IProps> = ({ mobileMenu = false }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { wsClose } = useWebsocket();

  const current = useAppSelector(
    (state) => state.lightingLineGroups.currentGroupId
  );
  const currentGroup = useAppSelector(
    (state) => state.lightingLineGroups.currentGroup
  );
  const groups = useAppSelector((state) => state.lightingLineGroups.groups);

  //если у юзера только одна асуно - не нужна кнопка изменить
  const hasOneGroup = Object.keys(groups).length === 1;

  const onChangeGroup = () => {
    setRootPage(dispatch, wsClose);
  };

  if (!current) {
    return null;
  }

  return (
    <div className={classes.box}>
      <span
        className={classNames(classes.title, {
          [classes.mobileTitle]: mobileMenu,
          [classes.desctopTitle]: !mobileMenu,
        })}
      >
        {currentGroup.group_name}
      </span>

      {!hasOneGroup ? (
        <span
          className={classNames(classes.change, {
            [classes.desctopTitle]: !mobileMenu,
            [classes.hiddenChange]: !mobileMenu,
          })}
          onClick={onChangeGroup}
        >
          <FormattedMessage
            id="app.change-group-button"
            defaultMessage="Change"
          />
        </span>
      ) : null}
    </div>
  );
};

const useStyles = makeStyles({
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  title: {
    paddingLeft: "24px",
    paddingRight: "8px",
  },
  change: {
    cursor: "pointer",
    textDecoration: "underline",
  },
  mobileTitle: {
    color: "#000",
    fontWeight: 600,
  },
  desctopTitle: {
    color: "#fff",
    "@media (max-width: 768px)": {
      fontSize: "16px",
      fontWeight: 600,
      paddingTop: 4,
    },
  },
  hiddenChange: {
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
});
