import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";

// types
import { ILineCommand } from "types";

// Отправка команды на управление объектом (ЛО или светильником)
const setLinesOnOff = async (body: ILineCommand) => {
  const resp = await request(`/v1/control-lines`, "POST", JSON.stringify(body));
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Задание режима управления линиям освещения и светильникам
const setLinesMode = async (body: ILineCommand) => {
  const resp = await request(`/v1/mode-lines`, "POST", JSON.stringify(body));
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Редактировать несколько групп светильников.
const updateLampGroups = async (body: string) => {
  const resp = await request(`/lamp-groups`, "PUT", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const substations = {
  setLinesMode,
  setLinesOnOff,
  updateLampGroups,
};
