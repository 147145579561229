import { createAsyncThunk } from "@reduxjs/toolkit";

// types
import { ThunkAPI, ILampBase } from "types";

// Получение списка светильников
export const getAsunoLamps = createAsyncThunk<
  Array<ILampBase>,
  undefined,
  ThunkAPI
>(
  "lamps/getAsunoLamps",
  async (_, { extra: { api }, rejectWithValue, getState }) => {
    const groupId = getState().lightingLineGroups.currentGroupId;
    try {
      const data = await api.lamps.getAsunoLamps(groupId);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
