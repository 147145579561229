import moment from "moment";

export const getPeriod = (currentMonth: string) => {
  const period = {
    min: moment(currentMonth).startOf("month").toISOString(),

    max: moment(currentMonth).endOf("month").toISOString(),
  };

  return period;
};
