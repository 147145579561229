import moment from "moment";
import { IGroupSchedule } from "types";

// Расчет периода для занесения расписания через модалку Создание расписания Период
// применяется для расчета периода для полярных ночей
// предусматривается непрерывное горение от начальной даты до конечной
// polarNight чекбокс должен быть true

const params = 172800000; // 2 days

export const parsePolarNightPeriod = (item: IGroupSchedule) => {
  if (
    parseInt(moment(item.ts_end).format("x")) -
      parseInt(moment(item.ts_st).format("x")) <
    params
  ) {
    return item;
  }

  const days = Math.round(
    (parseInt(moment(item.ts_end).format("x")) -
      parseInt(moment(item.ts_st).format("x"))) /
      8.64e7
  );

  let k = [
    {
      // Добавляем первый день периода
      ...item,
      ts_st: item.ts_st,
      ts_end: moment(item.ts_st)
        .add(1, "d")
        .startOf("day")
        .add(12, "hours")
        .toISOString(),
    },
  ];

  let index = 0;

  do {
    k.push({
      ...item,
      ts_st: k[index].ts_end,
      ts_end: moment(k[index].ts_end).add(1, "d").toISOString(),
    });

    index = ++index;
  } while (index < days - 2);

  // Добавляем последний день периода
  if (parseInt(moment(item.ts_end).format("H")) <= 12) {
    k.push({
      ...item,
      ts_st: moment(k[index].ts_end)
        .startOf("day")
        .add(12, "hours")
        .toISOString(),
      ts_end: item.ts_end,
    });
  } else {
    k.push(
      {
        ...item,
        ts_st: moment(k[index].ts_end)
          .startOf("day")
          .add(12, "hours")
          .toISOString(),
        ts_end: moment(item.ts_end)
          .startOf("day")
          .add(12, "hours")
          .toISOString(),
      },

      {
        ...item,
        ts_st: moment(item.ts_end)
          .endOf("day")
          .subtract(12, "hours")
          .toISOString(),
        ts_end: item.ts_end,
      }
    );
  }

  return k;
};
