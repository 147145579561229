import { useState, useEffect } from "react";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { makeStyles } from "@material-ui/styles";
import { useLocation } from "react-router-dom";
import classNames from "classnames";

// components
import { Layout, Tag } from "antd";
import { MainMenu } from "common/MainMenu";
import { GroupSelect } from "common/GroupSelect";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { UserMenu } from "common/UserMenu";
import { HeaderMenu } from "common/HeaderMenu";
import { MobileMainMenu } from "common/MobileMainMenu";
import { PageFooter } from "./Footer";
import { BottomMenu } from "common/MobileMainMenu/BottomMenu";
import { DocsMenu } from "feat/docs/components/DocsMenu";
import { DocsHeaderMenu } from "common/DocsHeaderMenu";
import { AdminMenu } from "./AdminMenu";
import { ServiceMenu } from "./ServiceMenu";

// utils
import { useQuery } from "utils/useQuery";
import { useWebsocket } from "common/WebsocketProvider";
import { useWidth } from "utils/useWidth";
import { setRootPage } from "utils/setRootPage";

const { Header, Content, Sider } = Layout;

export const withMenuLayout = (Page: React.ComponentType<{}>) => {
  const innerPage = (props: {}) => (
    <MenuLayout>
      <Page {...props} />
    </MenuLayout>
  );
  return innerPage;
};

const docsPages = ["/docs", "/changelog", "/help"];

const MenuLayout: React.FC = ({ children }) => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const { width } = useWidth();
  const query = useQuery();
  const { wsClose } = useWebsocket();

  const loggedUser = useAppSelector((state) => state.currentUser.loggedUser);
  const currentUser = useAppSelector((state) => state.currentUser.user);
  const isAsunoChecked = useAppSelector((state) => state.asunoChecked);
  const locale = useAppSelector((state) => state.locale);

  const isAdminPage = pathname.includes("/admin");
  const isSuperAdmin = currentUser?.global_admin ?? false;

  const state = localStorage.getItem("collapsed");
  const collapsedState = state ? JSON.parse(state) : false;
  const [collapsed, setCollapsed] = useState(collapsedState);

  const mode = query.get("mode") ?? "";
  const isRoot = pathname === "/";
  const isLogin = pathname === "/login";
  const isMyProfile = pathname === "/users/profile";
  const isCreateAsuno = pathname === "/asuno" && mode === "create";
  const isSuperAdminPage = isAdminPage && isSuperAdmin;
  const isMobile = width < 768;
  const isBeta = locale === "en";
  const isDocsPages = docsPages.includes(pathname);
  const isServicePages = pathname.includes("/service");

  const toggleCollapsed = () => {
    setCollapsed((prev: boolean) => !prev);
    localStorage.setItem("collapsed", JSON.stringify(!collapsed));
  };

  const onLogoClick = () => {
    setRootPage(dispatch, wsClose);
  };

  const pagesWithoutMainMenu =
    isRoot || isLogin || isMyProfile || isCreateAsuno;
  const pagesWithoutAsuno =
    isRoot || isLogin || isSuperAdminPage || isCreateAsuno;

  useEffect(() => {
    if (width >= 768 && width < 1280) {
      setCollapsed(true);
      localStorage.setItem("collapsed", JSON.stringify(true));
    } else {
      setCollapsed(false);
      localStorage.setItem("collapsed", JSON.stringify(false));
    }
  }, [width]);

  const getMenu = () => {
    if (isDocsPages) {
      return <DocsMenu />;
    }
    if (isSuperAdminPage) {
      return <AdminMenu />;
    }
    if (isServicePages) {
      return <ServiceMenu />;
    }

    return <MainMenu />;
  };

  return (
    <Layout className={classes.root}>
      <Header className={classes.header}>
        <div className={classes.logoBox} onClick={onLogoClick}>
          <img
            className={classes.mainLogo}
            src="/logo.svg"
            width="28px"
            height="28px"
            alt=""
          />

          {isMobile && isAsunoChecked ? null : isBeta ? (
            <>
              <h2 className={classes.title}>USPherum</h2>
              <Tag className={classes.tag} color="grey">
                beta
              </Tag>
            </>
          ) : (
            <h2 className={classes.title}>USPherum</h2>
          )}

          {pagesWithoutAsuno || isDocsPages ? null : <GroupSelect />}
        </div>

        <div className={classes.box}>
          <DocsHeaderMenu />
          <div className={classes.menusBox}>
            {loggedUser ? <HeaderMenu /> : null}
            {loggedUser ? <UserMenu /> : null}
          </div>
        </div>
      </Header>

      <Layout>
        {pagesWithoutMainMenu ? null : (
          <Sider
            className={classes.sider}
            collapsible
            collapsed={collapsed}
            trigger={
              <div
                className={classes.collapsedButton}
                onClick={() => toggleCollapsed()}
              >
                {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </div>
            }
            theme="light"
          >
            {getMenu()}
          </Sider>
        )}

        <Content
          className={classNames(classes.content, {
            [classes.mobileLoginContent]:
              pagesWithoutMainMenu || isDocsPages || isSuperAdminPage,
          })}
        >
          <MobileMainMenu isSuperAdmin={isSuperAdmin} />

          {children}

          <div className={classes.footer}>
            <PageFooter />
          </div>

          {(isLogin || isDocsPages) && isMobile ? (
            <PageFooter mobileFooter />
          ) : null}
        </Content>

        {!pagesWithoutMainMenu && !isDocsPages && !isSuperAdminPage ? (
          <BottomMenu />
        ) : null}
      </Layout>
    </Layout>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },

  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "24px",
    paddingRight: "24px",
    height: "48px",
    backgroundColor: "#000",
  },
  logoBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  mainLogo: {
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  title: {
    color: "#fff",
    margin: 0,
    paddingLeft: "24px",
    "@media (max-width: 768px)": {
      fontSize: "14pt",
    },
    // "@media (max-width: 840px)": {
    //   display: "none",
    // },
  },
  box: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  menusBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "@media (max-width: 768px)": {
      display: "none",
    },
  },

  sider: {
    "@media (max-width: 768px)": {
      display: "none",
    },
  },

  content: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "0px",
    minHeight: "100%",
    "@media (max-width: 768px)": {
      paddingBottom: 59,
    },
  },
  mobileLoginContent: {
    paddingBottom: 0,
  },

  collapsedButton: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    paddingLeft: 33,
    backgroundColor: "#fff",
    borderTop: "1px solid black",
    opacity: "0.6",
    "& svg": {
      height: 16,
      width: 16,
      color: "#000",
    },
  },
  footer: {
    "@media (max-width: 768px)": {
      display: "none",
    },
  },
  tag: {
    marginLeft: 8,
  },
});
