import { makeStyles } from "@material-ui/styles";
interface IProps {
  type: "on" | "off" | string;
}

export const PolesIcons: React.FC<IProps> = ({ type }) => {
  const classes = useStyles();

  if (type === "on") {
    return <div className={classes.poleOn}></div>;
  }

  return <div className={classes.poleOff}></div>;
};

const useStyles = makeStyles({
  poleOn: {
    height: 20,
    width: 20,
    border: "1px solid #000",
    borderRadius: "50%",
    background: "orange",
  },
  poleOff: {
    height: 20,
    width: 20,
    border: "1px solid #000",
    borderRadius: "50%",
    background: "#e4f2f0",
  },
});
