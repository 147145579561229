import { useEffect } from "react";
import { useAppSelector, useAppDispatch } from "./hooks";
import "moment-duration-format";

//components
import { Routes } from "./Routes";
import { AppLayout } from "common/AppLayout";
import { ErrorBoundary } from "common/ErrorBoundary";
import { Loading } from "common/Loading";

//types
import { ILightingLineGroup } from "types";

//actions
import {
  getLightingLineGroups,
  getLightingLineGroup,
} from "feat/objects/actions";
import { getLightningLinesState } from "feat/indications/actions";

//styles
import "antd/dist/antd.less";
import "common/style.css";

//utils
import { setTimezone } from "feat/asuno/utils/setTimezone";

export const App = () => {
  const dispatch = useAppDispatch();

  const collapsed = localStorage.getItem("collapsed");
  const width = window.innerWidth;

  const loggedUser = useAppSelector((state) => state.currentUser.loggedUser);
  const user = useAppSelector((state) => state.currentUser.user);
  const groups = useAppSelector((state) => state.lightingLineGroups.groups);
  const currentGroup: ILightingLineGroup = useAppSelector(
    (state) => state.lightingLineGroups.currentGroup
  );

  const currentGroupId = useAppSelector(
    (state) => state.lightingLineGroups.currentGroupId
  );

  const hasGroups = user?.global_admin || user?.roles.length;

  const currentGroupTZ = currentGroup.timezone;

  useEffect(() => {
    //Проверяем и загружаем список групп
    if (loggedUser && !Object.keys(groups).length && hasGroups) {
      dispatch(getLightingLineGroups());
    }
  }, [dispatch, groups, hasGroups, loggedUser]);

  useEffect(() => {
    //Если выбрана группа то нужно загрузить состояние линий
    if (currentGroupId && loggedUser) {
      dispatch(getLightingLineGroup());
      dispatch(getLightningLinesState());
    }
  }, [currentGroupId, dispatch, loggedUser]);

  useEffect(() => {
    //Устанавливаем временную зону на основании текущей группы
    if (currentGroupTZ) {
      setTimezone(currentGroupTZ);
    }
  }, [currentGroupTZ]);

  //выставляем подменю отчетов открытым
  if (!collapsed && width > 768) {
    localStorage.setItem("openKeys", JSON.stringify(["reports"]));
  }

  //загрузка текущего юзера и его прав
  const { loading, loadingScopes } = useAppSelector(
    (state) => state.currentUser
  );

  const loadingGroups = useAppSelector(
    (state) => state.lightingLineGroups.loading
  );

  if (loading || loadingScopes || loadingGroups) {
    return <Loading />;
  }

  return (
    <AppLayout>
      <ErrorBoundary>
        <Routes />
      </ErrorBoundary>
    </AppLayout>
  );
};
