import { createSlice } from "@reduxjs/toolkit";

// actions
import { getGroupConfiguration } from "../actions";

// types
import { IError, IConfiguration, Maybe } from "types";

interface IInitialState {
  configuration: Maybe<IConfiguration>;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  configuration: null,
  loading: false,
  error: null,
};

export const groupConfigurationSlice = createSlice({
  name: "groupConfiguration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Получить конфигурацию группы
    builder.addCase(getGroupConfiguration.pending, (state) => {
      state.loading = true;
      state.configuration = null;
      state.error = null;
    });
    builder.addCase(getGroupConfiguration.fulfilled, (state, action) => {
      state.loading = false;
      state.configuration = action.payload;
    });
    builder.addCase(getGroupConfiguration.rejected, (state) => {
      state.loading = false;
      state.configuration = null;
      state.error = "Произошла ошибка при загрузке";
    });
  },
});
