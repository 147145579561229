import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { intl } from "lib/locale";

// actions
import { getLightingLineNotifyParams } from "feat/asuno/actions";

// types
import { ThunkAPI, IChartData, ILineRequest } from "types";

const point_quantity = () => {
  const width = window.innerWidth;
  if (width >= 1024) {
    return width / 3;
  }
  if (width >= 760 && width < 1024) {
    return width / 2;
  }
  if (width < 760) {
    return width;
  }
};

//Получение показаний по линиям освещения
export const getLightningLineReadout = createAsyncThunk<
  { data: IChartData[]; period: { from: string; to: string }; line: string },
  ILineRequest,
  ThunkAPI
>(
  "lightingLineReadout/getLightningLineReadout",
  async (
    { from, to, lines },
    { extra: { api }, rejectWithValue, dispatch }
  ) => {
    try {
      const data = await api.chart.getLightningLineReadout({
        from,
        to,
        lines,
        point_quantity: point_quantity(),
      });

      await dispatch(getLightingLineNotifyParams(lines[0]));

      return { data, period: { from, to }, line: lines[0] };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получение показаний по линиям освещения для зума графика
export const getCrartZoomData = createAsyncThunk<
  IChartData[],
  ILineRequest,
  ThunkAPI
>(
  "lightingLineReadout/getCrartZoomData",
  async ({ from, to }, { extra: { api }, rejectWithValue, getState }) => {
    const line = getState().lightingLineReadout.initLine;

    try {
      const data = await api.chart.getLightningLineReadout({
        from,
        to,
        lines: [line],
        point_quantity: point_quantity(),
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получение показаний по линиям освещения
export const getLightningLineReadoutReport = createAsyncThunk<
  null,
  ILineRequest,
  ThunkAPI
>(
  "lightingLineReadout/getLightningLineReadoutReport",
  async (
    { from, to, lines },
    { extra: { api }, rejectWithValue, getState }
  ) => {
    const { group_id, group_name } = getState().lightingLineGroups.currentGroup;

    const name = `${group_name} - ${intl.formatMessage({
      id: "charts.chart-file-name",
      defaultMessage: "Chart",
    })} ${moment(from).format("DD.MM.YYYY")}-${moment(to).format(
      "DD.MM.YYYY"
    )}.xlsx`;

    try {
      const data = await api.chart.getLightningLineReadoutReport(group_id, {
        from,
        to,
        lines,
      });

      var xlsxURL = window.URL.createObjectURL(data);
      let tempLink = document.createElement("a");
      tempLink.href = xlsxURL;
      tempLink.setAttribute("download", name);
      tempLink.click();

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
