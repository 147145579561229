import { makeStyles } from "@material-ui/styles";
interface IProps {
  type: "on" | "off" | "poleOn" | string;
  isDemo?: boolean;
}

export const MarkersIcons: React.FC<IProps> = ({ type, isDemo }) => {
  const classes = useStyles();

  switch (type) {
    case "on":
      return (
        <svg
          enableBackground="new 0 0 32 32"
          height={isDemo ? "50" : "30"}
          version="1.1"
          viewBox="0 0 32 32"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g>
            <path
              d="M26.87,12.49l-10,18C16.69,30.81,16.36,31,16,31c-0.08,0-0.17-0.01-0.25-0.03C15.31,30.85,15,30.46,15,30    V19H6c-0.34,0-0.65-0.17-0.84-0.45c-0.18-0.28-0.21-0.64-0.08-0.95l7-16C12.24,1.24,12.6,1,13,1h6c0.33,0,0.64,0.16,0.83,0.44    c0.18,0.27,0.22,0.62,0.1,0.93L16.48,11H26c0.35,0,0.68,0.19,0.86,0.49C27.04,11.8,27.05,12.18,26.87,12.49z"
              fill="#FFB841"
            />
          </g>
        </svg>
      );

    case "off":
      return (
        <svg
          version="1.1"
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
          height={isDemo ? "50" : "30"}
        >
          <g>
            <path d="M235.56,482.98c2.19,1.03,4.5,1.52,6.8,1.52c5.05,0,9.96-2.39,13.04-6.73l189.3-266.04    c3.47-4.88,3.93-11.29,1.18-16.61c-2.75-5.32-8.23-8.67-14.22-8.67H270.9l65.54-136.02c2.39-4.96,2.06-10.8-0.87-15.46    c-2.93-4.66-8.04-7.49-13.55-7.49H169.85c-6.69,0-12.67,4.16-15,10.42L65.34,278.66c-1.83,4.91-1.13,10.41,1.86,14.71    c2.99,4.3,7.9,6.87,13.14,6.87h190.23L226.9,464.39C224.91,471.86,228.56,479.69,235.56,482.98z M103.36,268.23L180.97,59.5    h115.58l-65.54,136.02c-2.39,4.96-2.06,10.8,0.87,15.46c2.93,4.66,8.04,7.49,13.55,7.49h155.21l-120.11,168.8l26.31-98.91    c1.28-4.8,0.25-9.93-2.78-13.86c-3.03-3.94-7.72-6.25-12.68-6.25H103.36z" />
          </g>
        </svg>
      );

    case "poleOn":
      return <div className={classes.poleOn}></div>;

    default:
      return (
        <svg viewBox="0 0 24 24" fill="none" height={isDemo ? "50" : "30"}>
          <path
            stroke="#ff0000"
            strokeWidth="2"
            strokeLinejoin="round"
            strokeLinecap="round"
            d="m19.778417,4.289651c4.284852,4.269319 4.284852,11.191305 0,15.4606m0,0l-3.44829,-3.43577m3.44829,3.43577l3.213609,3.201941m-6.661898,-15.226868c2.380582,2.371853 2.380582,6.21734 0,8.589157m0,0l-3.448168,-3.435648m-5.172288,3.435648c-0.97052,-0.966899 -1.54536,-2.178923 -1.724523,-3.436013m-1.723682,6.871783c-3.046247,-3.035284 -3.926832,-7.411108 -2.64173,-11.221393m9.538139,2.632151c0.220661,-0.21986 0.525442,-0.355785 0.862042,-0.355785c0.673322,0 1.219123,0.54382 1.219123,1.214697c0,0.335378 -0.13642,0.639052 -0.357081,0.858912m-1.724084,-1.717824l-10.110066,-10.073359m10.110066,10.073359l1.724084,1.717824"
          />
        </svg>
      );
  }
};

const useStyles = makeStyles({
  poleOn: {
    height: 2,
    width: 20,
    border: "1px solid #000",
    borderRadius: "50%",
    background: "orange",
  },
});
