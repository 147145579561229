export const userRolesEnum = {
  SHEDULE_VIEW: "schedule_view",
  CHART_VIEW: "chart_view",
  SUBSTATIONS_VIEW: "substations_view",
  MAP_VIEW: "map_view",
  INDICATIONS_VIEW: "indications_view",
  BURNING_HOURS_VIEW: "burning_hours_view",
  CONSUMPTION_VIEW: "consumption_view",
  ARCHIVE_REPORTS_VIEW: "archive_reports_view",
  USERS_VIEW: "users_view",
  LOGS_VIEW: "logs_view",
  LAMPS_VIEW: "lamps_view",

  SHEDULE_EDIT: "schedule_edit",
  SUBSTATIONS_EDIT: "substations_edit",
  ARCHIVE_REPORTS_EDIT: "archive_reports_edit",
  USERS_EDIT: "users_edit",

  SCHEMA_CONTROL: "schema_control",
  SUBSTATIONS_CONTROL: "substations_control",
  MAP_CONTROL: "map_control",
};
