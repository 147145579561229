export const devicesPageType = {
  PHOTORELAYS: "photorelays",
  MODEMS: "modems",
};

export enum ISettingsCheckbox {
  NONE = "none",
  NUMBER = "number",
  ALL = "all",
}

export enum ISettingsRoles {
  USERS = "users",
  ASUNO = "asuno",
  GLOBAL = "global",
}

export enum ISettingsAsuno {
  AVAILABLE = "available",
  CURRENT = "current",
  ALL = "all",
}

export enum ILocale {
  RU = "ru",
  EN = "en",
}

export enum IProfileSteps {
  BASE = "base",
  SECURITY = "security",
  ROLES = "roles",
  LOCALE = "locale",
}

export enum ILineMode {
  MANUAL = "manual",
  AUTO = "auto:sch",
  SWITCHER = "switcher",
  MIXED = "mixed",
}

export enum IAsunoPageMode {
  ASUNO = "asuno",
  LINES = "lines",
  REPORTS = "reports",
  CREATE = "create",
  CONFIGURATION = "configuration",
}

export enum ISubstationSorter {
  CRASH = "crash",
  ALL = "all",
  ON = "on",
  OFF = "off",
  DISCONNECT = "disconnect",
  NOT_CONNECTED = "not_connected",
}

export enum IMobileLegendType {
  CHART = "chart",
  MAP = "map",
  SUBSTATIONS = "substations",
}

export enum IDirectories {
  CUSTOMERS = "customers",
  SERVICES = "services",
}

export enum EmeterTypes {
  C272xA = "C272xA",
  mercury_20x = "mercury_20x",
  mercury_23x = "mercury_23x",
}

export enum ControlDeviceTypes {
  C272xA = "C272xA",
  mercury_23x = "mercury_23x",
  wb_mr6_lv = "wb_mr6_lv",
  mrwl3 = "mrwl3",
}

export const EmeterType: Record<string, string> = {
  C272xA: "ЗиП ЦЭ2726А, ЦЭ2727А",
  mercury_20x: "Меркурий 200, 201, 203, 206",
  mercury_23x: "Меркурий 203.2TD, 204, 208, 230, 231, 234, 236, 238",
};

export const ControlDeviceType: Record<string, string> = {
  C272xA: "ЗиП ЦЭ2726А, ЦЭ2727А",
  mercury_23x: "Меркурий 203.2TD, 204, 208, 230, 231, 234, 236, 238",
  wb_mr6_lv: "WB-MR6-LV",
  mrwl3: "",
};

export const ModemType: Record<string, string> = {
  elfin: "Elfin 2G",
  elfin_wifi: "Elfin WiFi",
};
