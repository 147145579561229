import React from "react";
import * as Sentry from "@sentry/react";
import { FormattedMessage } from "react-intl";

//components
import { Result, Button } from "antd";

//utils
import { isProduction } from "utils/env";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  backClick = () => {
    window.location.reload();
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    Sentry.captureException(errorInfo);
    if (isProduction) {
      setTimeout(() => this.backClick(), 4000);
    }
  }

  render() {
    if (this.state.error) {
      return (
        <Result
          status="500"
          title={
            <FormattedMessage
              id="error.has-error"
              defaultMessage="An error has occurred"
            />
          }
          subTitle={
            <FormattedMessage
              id="error.try-refreshing-msg"
              defaultMessage="Try refreshing the page"
            />
          }
          extra={
            <div>
              <Button type="primary" onClick={() => this.backClick()}>
                <FormattedMessage
                  id="error.update-button"
                  defaultMessage="Update"
                />
              </Button>
            </div>
          }
        />
      );
    }

    return this.props.children;
  }
}
