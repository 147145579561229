import { makeStyles } from "@material-ui/styles";

export const ContentLayout: React.FC = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.content}>{children}</div>;
};

const useStyles = makeStyles({
  content: {
    position: "relative",
    padding: "24px",
    margin: "24px",
    marginBottom: 0,
    backgroundColor: "#fff",
    flex: "1",

    "@media (max-width: 768px)": {
      padding: "6px",
      margin: 0,
    },

    "@media (max-width: 1239px)": {
      "@media (min-width: 768px)": {
        padding: "16px",
        margin: "16px",
      },
    },
  },
});
