import { createSlice } from "@reduxjs/toolkit";

//actions
import {
  getPhotoRelayHistory,
  getModemHistory,
  getAllModemHistory,
  getLineHistory,
  updateLineHistory,
  startTest,
  getLineHistoryLast,
} from "../actions";

//types
import {
  IError,
  IPhotoRelayHistory,
  ILineHistory,
  IModemHistoryData,
} from "types";

interface IInitialState {
  photoRelayHistory: IPhotoRelayHistory[];
  modemHistory: IModemHistoryData;
  allModemHistory: IModemHistoryData;
  linesHistory: ILineHistory[];
  testingStarted: boolean;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  photoRelayHistory: [],
  modemHistory: [],
  allModemHistory: [],
  linesHistory: [],
  testingStarted: false,
  loading: false,
  error: null,
};

export const devicesWorkingHistorySlice = createSlice({
  name: "devicesWorkingHistory",
  initialState,
  reducers: {
    cleanHistory: (state) => {
      state.photoRelayHistory = [];
    },
    setTestingStarted: (state, action) => {
      state.testingStarted = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Получение истории фотореле
    builder.addCase(getPhotoRelayHistory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getPhotoRelayHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.photoRelayHistory = action.payload;
    });
    builder.addCase(getPhotoRelayHistory.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //Получение истории модемов
    builder.addCase(getModemHistory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getModemHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.modemHistory = action.payload;
    });
    builder.addCase(getModemHistory.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //Получение всей истории модема для графика
    builder.addCase(getAllModemHistory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllModemHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.allModemHistory = action.payload;
    });
    builder.addCase(getAllModemHistory.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //Получение истории тестирования модема
    builder.addCase(getLineHistory.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLineHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.linesHistory = action.payload;
    });
    builder.addCase(getLineHistory.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //Получение истории тестирования модема
    builder.addCase(getLineHistoryLast.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLineHistoryLast.fulfilled, (state, action) => {
      state.loading = false;
      const lastState = action.payload;
      if (lastState) {
        state.linesHistory = [lastState];
      } else {
        state.linesHistory = [];
      }
    });
    builder.addCase(getLineHistoryLast.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //Обновление истории тестирования модема
    builder.addCase(updateLineHistory.pending, (state) => {
      state.error = null;
    });
    builder.addCase(updateLineHistory.fulfilled, (state, action) => {
      state.linesHistory = action.payload;
    });
    builder.addCase(updateLineHistory.rejected, (state) => {
      state.error = "Произошла ошибка при загрузке";
    });

    //Запуск тестирования линии
    builder.addCase(startTest.fulfilled, (state) => {
      state.testingStarted = true;
    });
  },
});
