import { request } from "lib/api";
import { IUserLogin } from "types";
import { handleErrors } from "lib/handleErrors";

const login = async ({ email, password }: IUserLogin) => {
  const resp = await request(
    "/v1/token-json",
    "POST",
    JSON.stringify({ username: email, password })
  );
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const logout = async () => {
  const resp = await request(`/v1/logout`, "DELETE");
  if (!resp.ok) {
    handleErrors(resp);
  }

  localStorage.removeItem("id");
  localStorage.removeItem("user");

  return null;
};

export const auth = {
  login,
  logout,
};
