import moment from "moment-timezone";

interface IZones {
  name: string;
  offset: number;
}

export const setTimezone = (value: number) => {
  const zones: IZones[] = moment.tz.zonesForCountry("RU", true);

  const zonesObj = zones.reduce<Record<string, string>>(
    (acc, zone) => ({ ...acc, [Math.abs(zone.offset) * 60]: zone.name }),
    {}
  );

  moment.tz.setDefault(zonesObj[value]);
};
