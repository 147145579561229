import { makeStyles } from "@material-ui/styles";

//components
import { Spin } from "antd";

export const Loading: React.FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.loading}>
      <Spin size="large" />
    </div>
  );
};

const useStyles = makeStyles({
  loading: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});
