import * as Sentry from "@sentry/react";
class ApiError extends Error {
  constructor(resp: any) {
    super(resp);
    this.name = "ApiError";
    //@ts-ignore
    this.code = resp.status;
    this.message = resp.statusText;
    //@ts-ignore
    this.data = resp;
  }
}

export const handleErrors = (resp: any) => {
  const user = localStorage.getItem("user");
  Sentry.captureException(`${resp.status} ${user} ${resp.url}`);
  throw new ApiError(resp);
};
