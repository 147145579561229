/* eslint-disable jsx-a11y/anchor-is-valid */
import { useAppSelector, useAppDispatch } from "app/hooks";
import { makeStyles } from "@material-ui/styles";

// components
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";

// actions
import { localeSlice } from "common/reducers";
import { updateUser } from "feat/users/actions";

// utils
import { availableLocales } from "lib/locale";

// types
import { ILocale } from "lib/enums";

export const LangSelect: React.FC = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { setLocale } = localeSlice.actions;

  const currentLang = useAppSelector((state) => state.locale);
  const user = useAppSelector((state) => state.currentUser.user);
  const userId = user?.id;

  const setLanguage = (key: ILocale) => {
    dispatch(setLocale(key));
    dispatch(
      updateUser({
        id: userId!,
        lang: key,
      })
    );
  };

  const menu = (
    <Menu selectedKeys={[currentLang]}>
      <Menu.Item key={ILocale.RU} onClick={() => setLanguage(ILocale.RU)}>
        Русский
      </Menu.Item>
      <Menu.Item key={ILocale.EN} onClick={() => setLanguage(ILocale.EN)}>
        English
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={["click"]} className={classes.root}>
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        {availableLocales[currentLang]}&nbsp;
        <DownOutlined />
      </a>
    </Dropdown>
  );
};

const useStyles = makeStyles({
  root: {
    paddingLeft: 16,
  },
});
