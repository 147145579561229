//utils
import { isDevelopment } from "utils/env";

export const websocket = (url: string) => {
  const host = isDevelopment
    ? "wss://asuno-test.tiram.icu"
    : `${document.location.protocol === "https:" ? "wss" : "ws"}://${
        document.location.host
      }`;

  const ws = new WebSocket(`${host}/api${url}`);

  return ws;
};
