import { createSlice } from "@reduxjs/toolkit";

// actions
import { getAsunoLamps } from "../actions";

// types
import { IError, ILampBase } from "types";

interface IInitialState {
  loading: boolean;
  lamps: Array<ILampBase>;
  error: IError;
}

const initialState: IInitialState = {
  loading: false,
  lamps: [],
  error: null,
};

export const lampsSlice = createSlice({
  name: "lamps",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Получение списка светильников
    builder.addCase(getAsunoLamps.pending, (state) => {
      state.loading = true;
      state.lamps = [];
      state.error = null;
    });
    builder.addCase(getAsunoLamps.fulfilled, (state, action) => {
      state.loading = false;
      state.lamps = action.payload;
    });
    builder.addCase(getAsunoLamps.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });
  },
});
