import { createSlice, current } from "@reduxjs/toolkit";

//actions
import { loadSchedule, checkSchedule } from "../actions";

//types
import { IError } from "types";

interface IInitialState {
  loading: string[];
  error: IError;
}

const initialState: IInitialState = {
  loading: [],
  error: null,
};

export const loadScheduleSlice = createSlice({
  name: "lightingLineSchedule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadSchedule.pending, (state, action) => {
      const lineId = action.meta.arg.lineId;
      state.loading = [...current(state.loading), lineId];
      state.error = null;
    });
    builder.addCase(loadSchedule.fulfilled, (state, action) => {
      const lineId = action.payload;
      state.loading = current(state.loading).filter((id) => id !== lineId);
    });
    builder.addCase(loadSchedule.rejected, (state, action) => {
      const lineId = action.meta.arg.lineId;
      state.loading = current(state.loading).filter((id) => id !== lineId);
      state.error = "Произошла ошибка при загрузке";
    });

    builder.addCase(checkSchedule.pending, (state, action) => {
      const lineId = action.meta.arg.lineId;
      state.loading = [...current(state.loading), lineId];
      state.error = null;
    });
    builder.addCase(checkSchedule.fulfilled, (state, action) => {
      const lineId = action.payload;
      state.loading = current(state.loading).filter((id) => id !== lineId);
    });
    builder.addCase(checkSchedule.rejected, (state, action) => {
      const lineId = action.meta.arg.lineId;
      state.loading = current(state.loading).filter((id) => id !== lineId);
      state.error = "Произошла ошибка при загрузке";
    });
  },
});
