import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";

interface IServiceLinkItem {
  service_id: number;
  group_id: number;
}

//Получение всех заказчиков
const getCustomers = async () => {
  const resp = await request("/v1/customer", "GET");
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

//Создание заказчика
const addCustomer = async (name: string) => {
  const body = JSON.stringify({ name });
  const resp = await request(`/v1/customer`, "POST", body);
  if (!resp.ok) {
    handleErrors(resp);
  }

  return null;
};

//Удаление заказчика
const delCustomer = async (id: number) => {
  const resp = await request(`/v1/customer/${id}`, "DELETE");
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

//Изменение заказчика
const updCustomer = async (name: string, id: number) => {
  const body = JSON.stringify({ name });
  const resp = await request(`/v1/customer/${id}`, "PUT", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

/////////////////////ОБСЛУЖИВАЮЩИЕ ОРГАНИЗАЦИИ//////////////////////////////

//Получение всех обслуживающих организаций 🔧
const getServices = async () => {
  const resp = await request("/v1/services", "GET");
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

//Создание обслуживающей организации 🔧
const addService = async (name: string) => {
  const body = JSON.stringify({ name });
  const resp = await request("/v1/services", "POST", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

//Изменение обслуживающей организации
const updService = async (id: number, name: string) => {
  const body = JSON.stringify({ name });
  const resp = await request(`/v1/services/${id}`, "PUT", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

//Удаление обслуживающей организации
const delService = async (id: number) => {
  const resp = await request(`/v1/services/${id}`, "DELETE");
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

//Привязка обслуживающей организации к группе 🔧
const addServicesLinks = async (reqBody: IServiceLinkItem[]) => {
  const body = JSON.stringify(reqBody);
  const resp = await request(`/v1/services_links`, "POST", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

//Удаление привязки обслуживающей организации к группе
const delServicesLinks = async (reqBody: IServiceLinkItem[]) => {
  const body = JSON.stringify(reqBody);
  const resp = await request(`/v1/services_links`, "DELETE", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

export const directories = {
  getCustomers,
  addCustomer,
  delCustomer,
  updCustomer,
  getServices,
  addService,
  updService,
  delService,
  addServicesLinks,
  delServicesLinks,
};
