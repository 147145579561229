import { createSlice } from "@reduxjs/toolkit";

// actions
import { getState } from "./actions";

// types
import { IError, IEmeterState, Maybe } from "types";

interface IInitialState {
  emeterState: Maybe<IEmeterState>;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  emeterState: null,
  loading: false,
  error: null,
};

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    updateState: (state, action) => {
      state.emeterState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getState.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getState.fulfilled, (state, action) => {
      state.loading = false;
      state.emeterState = action.payload;
    });
    builder.addCase(getState.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });
  },
});
