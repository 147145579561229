import { useAppSelector } from "app/hooks";
import { Helmet } from "react-helmet-async";
import moment from "moment";

//components
import { Space } from "antd";
import { PageHeader } from "ui/PageHeader";
import { ContentLayout } from "common/ContentLayout";

export const TestPage: React.FC = () => {
  const user = useAppSelector((state) => state.currentUser.user);
  const currentGroup = useAppSelector(
    (state) => state.lightingLineGroups.currentGroup
  );

  const info = window.navigator?.userAgent;

  return (
    <>
      <Helmet>
        <title>Информация</title>
      </Helmet>

      <PageHeader title="Информация" />

      <ContentLayout>
        <Space direction="vertical">
          <span>{moment().format("L LT")}</span>

          <span>
            {user?.name} {user?.surname}
          </span>

          <span>{user?.email}</span>

          <span>{currentGroup.group_name}</span>

          <span>{info}</span>
        </Space>
      </ContentLayout>
    </>
  );
};
