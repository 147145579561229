import { Key, useState } from "react";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// components
import { Menu } from "antd";
import { ScheduleIcon } from "ui/Icons";
import {
  AreaChartOutlined,
  ThunderboltOutlined,
  FileDoneOutlined,
  TeamOutlined,
  SettingOutlined,
  ClusterOutlined,
  UnorderedListOutlined,
  ControlOutlined,
  BulbOutlined,
  GlobalOutlined,
} from "@ant-design/icons";

// utils
import { useRole } from "utils/useRole";
import { userRolesEnum } from "lib/scopesEnum";
import { useSubMenu } from "utils/getMainMenu";
import { mobileMenuSlice } from "common/reducers";
import { devicesPageType } from "lib/enums";

// types
import { ILightingLineGroup } from "types";

const { SubMenu } = Menu;
const { setMobileMenuOpen } = mobileMenuSlice.actions;

export const MainMenu: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const subMenu = useSubMenu();

  const currentUser = useAppSelector((state) => state.currentUser.user);
  const userScopes = useAppSelector((state) => state.currentUser.scopes);
  const current = useAppSelector((state) => state.lightingLineGroups.current);

  const currentGroup: ILightingLineGroup = useAppSelector(
    (state) => state.lightingLineGroups.currentGroup
  );
  const { use_photorelay, show_control_panel, use_new_schedules_system } =
    currentGroup.flags ?? {};
  const hasPhotorelay = use_photorelay && currentGroup?.photorelays?.length;

  const scopes = userScopes[current];
  const isGlobalAdmin = currentUser?.global_admin;

  const [selectedKeys, setSelectedKeys] = useState([location.pathname]);
  const [openKeys, setOpenKeys] = useState(() => {
    const initialState: string[] = [];
    const storageKeys = localStorage.getItem("openKeys");

    if (!storageKeys) {
      localStorage.setItem("openKeys", JSON.stringify(initialState));
      return initialState;
    }

    return JSON.parse(storageKeys);
  });

  const onKeyClick = ({ key }: { key: string }) => {
    dispatch(setMobileMenuOpen(false));
    setSelectedKeys([key]);
  };

  const onSubMenuClick = (arg: Key[]) => {
    localStorage.setItem("openKeys", JSON.stringify(arg));
    setOpenKeys(arg);
  };

  return (
    <Menu
      theme="light"
      mode="inline"
      triggerSubMenuAction="click"
      onClick={onKeyClick}
      selectedKeys={selectedKeys}
      onOpenChange={onSubMenuClick}
      openKeys={openKeys}
    >
      {useRole(userRolesEnum.SCHEMA_CONTROL) && show_control_panel ? (
        <Menu.Item key="/control" icon={<ControlOutlined />}>
          <Link to="/control">
            <FormattedMessage id="app.lighting-control" />
          </Link>
        </Menu.Item>
      ) : null}

      {useRole(userRolesEnum.SUBSTATIONS_VIEW) ? (
        <Menu.Item key="/substations" icon={<ThunderboltOutlined />}>
          <Link to="/substations">
            <FormattedMessage
              id="app.substations"
              defaultMessage="Substations"
            />
          </Link>
        </Menu.Item>
      ) : null}

      {useRole(userRolesEnum.MAP_VIEW) ? (
        <Menu.Item key="/map" icon={<GlobalOutlined />}>
          <Link to="/map">
            <FormattedMessage id="app.map" defaultMessage="Map" />
          </Link>
        </Menu.Item>
      ) : null}

      {useRole(userRolesEnum.CHART_VIEW) ? (
        <Menu.Item key="/chart" icon={<AreaChartOutlined />}>
          <Link to="/chart">
            <FormattedMessage id="app.chart" defaultMessage="Chart" />
          </Link>
        </Menu.Item>
      ) : null}

      {useRole(userRolesEnum.SHEDULE_VIEW) && use_new_schedules_system ? (
        <Menu.Item key="/schedules" icon={<ScheduleIcon />}>
          <Link to="/schedules">
            <FormattedMessage id="app.schedule" defaultMessage="Schedule" />
          </Link>
        </Menu.Item>
      ) : null}

      {useRole(userRolesEnum.SHEDULE_VIEW) && !use_new_schedules_system ? (
        <Menu.Item key="/schedule" icon={<ScheduleIcon />}>
          <Link to="/schedule">
            <FormattedMessage id="app.schedule" defaultMessage="Schedule" />
          </Link>
        </Menu.Item>
      ) : null}

      {subMenu ? (
        <SubMenu
          key="reports"
          icon={<FileDoneOutlined />}
          title={<FormattedMessage id="app.reports" defaultMessage="Reports" />}
        >
          {isGlobalAdmin || scopes[userRolesEnum.INDICATIONS_VIEW] ? (
            <Menu.Item key="/indications">
              <Link to="/indications">
                <FormattedMessage
                  id="app.indications"
                  defaultMessage="Indications"
                />
              </Link>
            </Menu.Item>
          ) : null}

          {isGlobalAdmin || scopes[userRolesEnum.BURNING_HOURS_VIEW] ? (
            <Menu.Item key="/burning_hours">
              <Link to="/burning_hours">
                <FormattedMessage
                  id="app.burning-hours"
                  defaultMessage="Burning hours"
                />
              </Link>
            </Menu.Item>
          ) : null}

          {isGlobalAdmin || scopes[userRolesEnum.CONSUMPTION_VIEW] ? (
            <Menu.Item key="/consumption_fact">
              <Link to="/consumption_fact">
                <FormattedMessage
                  id="app.consumption"
                  defaultMessage="Consumption"
                />
              </Link>
            </Menu.Item>
          ) : null}
        </SubMenu>
      ) : null}

      {useRole(userRolesEnum.SUBSTATIONS_VIEW) && hasPhotorelay ? (
        <Menu.Item key="/devices" icon={<ClusterOutlined />}>
          <Link to={`/devices?type=${devicesPageType.PHOTORELAYS}`}>
            <FormattedMessage id="app.devices" defaultMessage="Devices" />
          </Link>
        </Menu.Item>
      ) : null}

      {useRole(userRolesEnum.LAMPS_VIEW) && use_new_schedules_system ? (
        <Menu.Item key="/lamps" icon={<BulbOutlined />}>
          <Link to="/lamps">
            <FormattedMessage id="app.lamps" defaultMessage="Lamps" />
          </Link>
        </Menu.Item>
      ) : null}

      {useRole(userRolesEnum.LOGS_VIEW) ? (
        <Menu.Item key="/logs" icon={<UnorderedListOutlined />}>
          <Link to="/logs">
            <FormattedMessage id="app.logs" defaultMessage="Logs" />
          </Link>
        </Menu.Item>
      ) : null}

      {useRole(userRolesEnum.USERS_VIEW) ? (
        <Menu.Item key="/users" icon={<TeamOutlined />}>
          <Link to="/users">
            <FormattedMessage id="app.users" defaultMessage="Staff" />
          </Link>
        </Menu.Item>
      ) : null}

      {useRole("") ? (
        <Menu.Item key="/asuno" icon={<SettingOutlined />}>
          <Link to="/asuno?mode=asuno">
            <FormattedMessage id="app.settings" defaultMessage="Settings" />
          </Link>
        </Menu.Item>
      ) : null}
    </Menu>
  );
};
