import { createAsyncThunk } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

// components
import { notification } from "antd";

// actions
import { localeSlice } from "common/reducers";

// types
import { IUser, ThunkAPI, ICreateUser, IRole, IUpdateUser } from "types";

const { setLocale } = localeSlice.actions;
// загрузка списка пользователей
export const getUsers = createAsyncThunk<IUser[], boolean, ThunkAPI>(
  "users/getUsers",
  async (isAdmin, { extra: { api }, rejectWithValue, getState }) => {
    //если админ => не передаем группу и идет запрос на всех юзеров
    const group_id = isAdmin
      ? null
      : getState().lightingLineGroups.currentGroup.group_id;

    try {
      const data = await api.users.getUsers(group_id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// создание пользователя
export const createUser = createAsyncThunk<any, ICreateUser, ThunkAPI>(
  "users/createUser",
  async (body, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.users.createUser(JSON.stringify(body));

      notification["success"]({
        message: `${intl.formatMessage({
          id: "users.user",
        })} ${body.name} ${body.surname} ${intl.formatMessage({
          id: "users.created",
        })}`,
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// редактирование пользователя
// включает в себя удаление
export const updateUser = createAsyncThunk<IUpdateUser, IUpdateUser, ThunkAPI>(
  "users/updateUser",
  async (body, { extra: { api }, rejectWithValue }) => {
    try {
      await api.users.updateUser(JSON.stringify({ ...body }));

      notification["success"]({
        message: intl.formatMessage({
          id: "users.saved",
        }),
      });

      return body;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// получение списка ролей в текущей группе
export const getRoles = createAsyncThunk<IRole[], undefined, ThunkAPI>(
  "users/getRoles",
  async (_, { extra: { api }, rejectWithValue, getState }) => {
    const group_id = getState().lightingLineGroups.currentGroup.group_id;

    try {
      const data = await api.users.getRoles(group_id);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// создание роли в текущей группе
export const createRole = createAsyncThunk<
  any,
  { role_name: string; scopes: string[] },
  ThunkAPI
>(
  "users/createRole",
  async (body, { extra: { api }, rejectWithValue, getState }) => {
    const group_id = getState().lightingLineGroups.currentGroup.group_id;

    const requestBody = JSON.stringify({ ...body, group_id });

    try {
      const data = await api.users.createRole(requestBody);

      notification["success"]({
        message: `${intl.formatMessage({
          id: "users.role",
        })} ${body.role_name} ${intl.formatMessage({
          id: "users.role-created",
        })}`,
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// запрос пользователя по его id
export const getUserById = createAsyncThunk<
  IUser,
  number | undefined,
  ThunkAPI
>("users/getUserById", async (user_id, { extra: { api }, rejectWithValue }) => {
  try {
    const data = await api.users.getUserById(user_id);
    return data;
  } catch (error) {
    return rejectWithValue(error);
  }
});

// запрос пользователя
// применяется для текущего пользователя
// сразу после логина
export const getCurrentUser = createAsyncThunk<IUser, undefined, ThunkAPI>(
  "users/getCurrentUser",
  async (_, { extra: { api }, rejectWithValue, getState, dispatch }) => {
    const groupSelected = getState().lightingLineGroups.currentGroupId;
    const currentLocale = getState().locale;

    try {
      const data: IUser = await api.users.getUserById();

      if (data.lang !== currentLocale) {
        await dispatch(setLocale(data.lang));
      }

      if (groupSelected) {
        await dispatch(getUserScopes());
      }
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// проверка юзера в базе по email
export const checkUserByEmail = createAsyncThunk<IUser, string, ThunkAPI>(
  "users/checkUserByEmail",
  async (email, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.users.getUserByEmail(email);
      return data;
    } catch (error) {
      //@ts-ignore
      if (error.code === 404) {
        return {};
      } else return rejectWithValue(error);
    }
  }
);

// удаление локальной роли
export const deleteRole = createAsyncThunk<any, number, ThunkAPI>(
  "users/deleteRole",
  async (role_id, { extra: { api }, rejectWithValue, getState }) => {
    const rolesObjects = getState().roles.rolesObjects;

    try {
      const data = await api.users.deleteRole(role_id);

      if (data.success) {
        notification["success"]({
          message: intl.formatMessage({
            id: "users.role-deleted",
          }),
        });

        return role_id;
      }

      if (data.length) {
        const users = data.map((user: IUser) => `${user.name} ${user.surname}`);

        notification["error"]({
          message: `${intl.formatMessage({
            id: "users.unable-delete-role",
          })} ${rolesObjects[role_id].role_name}`,
          description: `${intl.formatMessage({
            id: "users.has-roles-msg",
          })} ${users.join(", ")}`,
          duration: 10,
        });
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// редактирование локальной роли
export const editLocalRole = createAsyncThunk<
  any,
  { role_id: number; scopes: string[]; role_name: string },
  ThunkAPI
>(
  "users/editLocalRole",
  async (role, { extra: { api }, rejectWithValue, getState, dispatch }) => {
    const rolesObjects = getState().roles.rolesObjects;

    try {
      const data = await api.users.editLocalRole(role);

      notification["success"]({
        message: `${rolesObjects[role.role_id].role_name}`,
        description: intl.formatMessage({
          id: "users.role-updated",
        }),
      });

      dispatch(getRoles());

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получение скопов ролей пользователя по заданной группе
export const getUserScopes = createAsyncThunk<
  { [k: string]: { [k: string]: boolean } },
  undefined,
  ThunkAPI
>(
  "users/getUserScopes",
  async (_, { extra: { api }, rejectWithValue, getState }) => {
    const group_id = getState().lightingLineGroups.currentGroupId;
    try {
      const data = await api.users.getUserScopes(group_id);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
