import { useAppSelector, useAppDispatch } from "app/hooks";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/styles";
import { Link } from "react-router-dom";

// components
import { DeviceSearch } from "../DeviceSearch";

// utils
import { useWebsocket } from "common/WebsocketProvider";

// actions
import { lightingLineGroupsSlice } from "feat/objects/reducers/lightingLineGroupsSlice";
import { asunoCheckedSlice } from "common/reducers";

const { setAsunoChecked } = asunoCheckedSlice.actions;
const { setCurrentGroup } = lightingLineGroupsSlice.actions;

export const HeaderMenu: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { wsClose } = useWebsocket();

  const currentUser = useAppSelector((state) => state.currentUser.user);
  const isGlobalAdmin = currentUser?.global_admin;

  if (!isGlobalAdmin) {
    return null;
  }

  const setRoot = () => {
    wsClose();
    dispatch(setAsunoChecked(false));
    dispatch(setCurrentGroup({}));
  };

  return (
    <div className={classes.root}>
      <div className={classes.headerBlocks} onClick={setRoot}>
        <Link to="/service" className={classes.title}>
          <FormattedMessage id="admin.service" />
        </Link>
      </div>

      <div className={classes.headerBlocks} onClick={setRoot}>
        <Link to="/" className={classes.title}>
          <FormattedMessage id="app.asuno" defaultMessage="Objects" />
        </Link>
      </div>

      {isGlobalAdmin ? <DeviceSearch /> : null}

      <div className={classes.headerBlocks} onClick={setRoot}>
        <Link to="/admin/users" className={classes.title}>
          <FormattedMessage id="app.control" defaultMessage="Control" />
        </Link>
      </div>
    </div>
  );
};

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  headerBlocks: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: "12px",
    paddingRight: "12px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#252a3d",
    },
  },
  title: {
    paddingLeft: 8,
    color: "#fff",
  },
});
