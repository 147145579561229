import { intl } from "lib/locale";

// components
import { notification } from "antd";

// types
import { IChangeStateWS, ILightingLineGroup } from "types";

const moreMsg = intl.formatMessage({
  id: "substations.more",
});

export const parseWsMsg = (
  messages: IChangeStateWS[],
  currentGroup: ILightingLineGroup
) => {
  const lines = currentGroup.lines.map((line) => line.id);

  // фильтруем сообщения
  const messagesMap = messages.reduce(
    (
      acc: Record<
        | "relayOnMsg"
        | "relayOffMsg"
        | "modeMsg"
        | "photorelaysMsg"
        | "groupOnMsg"
        | "groupOffMsg"
        | "colorGroup",
        IChangeStateWS[]
      >,
      curr
    ) => {
      const states = curr.changedStates?.states;
      // Если нет group_num то это изменение состояния ЛО
      const isLineRelayChange = states?.length === 1 && !states[0].group_num;
      const isLineGroupRelayChange = states?.length && states[0].group_num;

      return {
        relayOnMsg:
          lines.includes(curr.objId) && isLineRelayChange && states[0].light_on
            ? [...acc.relayOnMsg, curr]
            : acc.relayOnMsg,
        relayOffMsg:
          lines.includes(curr.objId) &&
          isLineRelayChange &&
          states[0].light_on === false
            ? [...acc.relayOffMsg, curr]
            : acc.relayOffMsg,
        modeMsg:
          lines.includes(curr.objId) && curr.changedStates?.ctl_mode
            ? [...acc.modeMsg, curr]
            : acc.modeMsg,
        photorelaysMsg: curr.changedStates?.photorelayState
          ? [...acc.photorelaysMsg, curr]
          : acc.photorelaysMsg,
        groupOnMsg:
          lines.includes(curr.objId) &&
          isLineGroupRelayChange &&
          states[0].light_on
            ? [...acc.groupOnMsg, curr]
            : acc.groupOnMsg,
        groupOffMsg:
          lines.includes(curr.objId) &&
          isLineGroupRelayChange &&
          states[0].light_on === false
            ? [...acc.groupOnMsg, curr]
            : acc.groupOnMsg,
        colorGroup:
          lines.includes(curr.objId) && curr.changedStates?.color
            ? [...acc.colorGroup, curr]
            : acc.colorGroup,
      };
    },
    {
      relayOnMsg: [],
      relayOffMsg: [],
      modeMsg: [],
      photorelaysMsg: [],
      groupOnMsg: [],
      groupOffMsg: [],
      colorGroup: [],
    }
  );

  const {
    relayOnMsg,
    relayOffMsg,
    modeMsg,
    photorelaysMsg,
    groupOnMsg,
    groupOffMsg,
    colorGroup,
  } = messagesMap;

  // Ищем имя первой линии в списке линии по ее ID
  const getLineName = (objId: string) =>
    currentGroup.lines.find((line) => line.id === objId)?.name ??
    intl.formatMessage({
      id: "substations.successfully",
    });

  const getPhotorelayName = (objId: string) =>
    currentGroup.photorelays.find((item) => item.id === objId)?.name;

  const getLampGroupName = (objId: string, groupNum: string | number) => {
    const [line] = currentGroup.lines.filter((line) => line.id === objId);
    const lampGroups = line?.lamp_groups ?? [];
    const [currentLampGroup] = lampGroups.filter(
      ({ group_num }) => groupNum.toString() === group_num
    );

    return currentLampGroup?.group_name || " ";
  };

  if (relayOnMsg.length) {
    // выводим список включенных линий
    const more =
      relayOnMsg.length > 1 ? `${moreMsg} ${relayOnMsg.length}...` : "";

    notification["success"]({
      message: intl.formatMessage({
        id: "substations.line-enabled",
      }),
      description: `${getLineName(relayOnMsg[0].objId)} ${more}`,
    });
  }

  if (relayOffMsg.length) {
    // выводим список выключенных линий
    const more =
      relayOffMsg.length > 1 ? `${moreMsg} ${relayOffMsg.length}...` : "";

    notification["success"]({
      message: intl.formatMessage({
        id: "substations.line-disabled",
      }),
      description: `${getLineName(relayOffMsg[0].objId)} ${more}`,
    });
  }

  if (photorelaysMsg.length) {
    // выводим обновления фотореле
    const more =
      photorelaysMsg.length > 1 ? `${moreMsg} ${photorelaysMsg.length}...` : "";

    notification["success"]({
      message: intl.formatMessage({
        id: "substations.state-changed",
      }),
      description: `${getPhotorelayName(photorelaysMsg[0].objId)} ${more}`,
    });
  }

  if (modeMsg.length) {
    const more = modeMsg.length > 1 ? `${moreMsg} ${modeMsg.length}...` : "";
    const groupNum = modeMsg[0].changedStates?.group_num;
    const name = groupNum
      ? getLampGroupName(modeMsg[0].objId, groupNum)
      : getLineName(modeMsg[0].objId);

    notification["success"]({
      message: intl.formatMessage({
        id: "substations.mode-changed",
      }),
      description: `${name}  ${more}`,
    });
  }

  if (groupOnMsg.length) {
    const more =
      groupOnMsg.length > 1 ? `${moreMsg} ${groupOnMsg.length}...` : "";

    const firstGroupNum =
      groupOnMsg[0].changedStates?.states?.[0]?.group_num ?? "";

    notification["success"]({
      message: intl.formatMessage({
        id: "substations.group-enabled",
      }),
      description: `${getLampGroupName(
        groupOnMsg[0].objId,
        firstGroupNum
      )}  ${more}`,
    });
  }

  if (groupOffMsg.length) {
    const more =
      groupOffMsg.length > 1 ? `${moreMsg} ${groupOffMsg.length}...` : "";

    const firstGroupNum =
      groupOffMsg[0].changedStates?.states?.[0]?.group_num ?? "";

    notification["success"]({
      message: intl.formatMessage({
        id: "substations.group-disabled",
      }),
      description: `${getLampGroupName(
        groupOffMsg[0].objId,
        firstGroupNum
      )}  ${more}`,
    });
  }

  if (colorGroup.length) {
    const more =
      colorGroup.length > 1 ? `${moreMsg} ${colorGroup.length}...` : "";

    notification["success"]({
      message: intl.formatMessage({
        id: "substations.color-changed",
      }),
      description: `${getLampGroupName(
        colorGroup[0].objId,
        colorGroup[0].changedStates.group_num ?? ""
      )}  ${more}`,
    });
  }
};
