interface IProps {
  className?: string;
}

export const IndicationLinkIcon: React.FC<IProps> = ({ className }) => (
  <svg
    id="Layer_1"
    version="1.1"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    height="25px"
    width="25px"
    className={className}
  >
    <g>
      <path d="M114,25H90V1H14v126h100V25z M106,119H22V9h60v24h24V119z" />
      <rect height="20" width="8" x="42" y="79" />
      <rect height="40" width="8" x="60" y="59" />
      <rect height="30" width="8" x="78" y="69" />
    </g>
  </svg>
);
