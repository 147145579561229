import { createSlice, current } from "@reduxjs/toolkit";

//actions
import { login, logout } from "./actions";
import { getCurrentUser, getUserScopes, updateUser } from "feat/users/actions";

//types
import { IError, IUser, Maybe } from "types";

interface IInitialState {
  loggedUser: boolean;
  user: Maybe<IUser>;
  scopes: Record<string, Record<string, boolean>>;
  loading: boolean;
  loadingScopes: boolean;
  error: IError;
}

const initialState: IInitialState = {
  loggedUser: !!localStorage.getItem("user") ?? false,
  user: null,
  scopes: {},
  loading: false,
  loadingScopes: false,
  error: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //login
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loggedUser = true;
      state.loading = false;
    });
    builder.addCase(login.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //получаем текущего юзера на основании email
    builder.addCase(getCurrentUser.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.user = action.payload;
      state.loading = false;
    });
    builder.addCase(getCurrentUser.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //получаем права доступа текущего пользователя
    builder.addCase(getUserScopes.pending, (state) => {
      state.loadingScopes = true;
      state.error = null;
    });
    builder.addCase(getUserScopes.fulfilled, (state, action) => {
      state.scopes = action.payload;
      state.loadingScopes = false;
    });
    builder.addCase(getUserScopes.rejected, (state) => {
      state.loadingScopes = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //Редактируем профиль пользователя
    builder.addCase(updateUser.fulfilled, (state, action) => {
      const updated = action.payload;
      const user = current(state).user;

      if (updated.id === user?.id) {
        state.user = { ...user, ...updated };
      }
    });

    //logout
    builder.addCase(logout.fulfilled, (state, action) => {
      state.loggedUser = false;
    });
  },
});
