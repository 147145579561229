import { useAppSelector } from "app/hooks";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/styles";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

const docPages = ["/docs", "/changelog", "/help"];

export const DocsHeaderMenu: React.FC = () => {
  const classes = useStyles();
  const location = useLocation();
  const isLoggedUser = useAppSelector((state) => state.currentUser.loggedUser);

  const isDocsPages = docPages.includes(location.pathname);

  if (isDocsPages && !isLoggedUser) {
    return (
      <div className={classes.headerBlocks}>
        <Link to="/login" className={classes.title}>
          <FormattedMessage id="app.log-in" defaultMessage="Log in" />
        </Link>
      </div>
    );
  }

  return (
    <div className={classes.headerBlocks}>
      <Link to="/docs" className={classes.title}>
        <FormattedMessage id="app.help" defaultMessage="Help" />
      </Link>
    </div>
  );
};

const useStyles = makeStyles({
  headerBlocks: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: "12px",
    paddingRight: "12px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#252a3d",
    },
  },
  title: {
    paddingLeft: 8,
    color: "#fff",
  },
});
