export const EmeterLinkIcon: React.FC = () => (
  <svg
    height="25px"
    width="25px"
    version="1.1"
    viewBox="0 0 128 128"
    xmlns="http://www.w3.org/2000/svg"
    stroke="#d9d9d9"
    strokeWidth="12"
    strokeLinecap="square"
    strokeMiterlimit="10"
    style={{ transform: "rotate(90deg)" }}
  >
    <g>
      <g>
        <g>
          <line x1="63.185" x2="37.821" y1="84.061" y2="109.423" />
          <line x1="37.821" x2="12.456" y1="109.423" y2="84.061" />
        </g>
        <line x1="37.821" x2="37.821" y1="109.423" y2="9.801" />
      </g>
      <g>
        <g>
          <line x1="64.815" x2="90.181" y1="44.241" y2="18.877" />
          <line x1="90.181" x2="115.544" y1="18.877" y2="44.241" />
        </g>
        <line x1="90.181" x2="90.181" y1="18.877" y2="118.199" />
      </g>
    </g>
  </svg>
);
