import { createSlice, current } from "@reduxjs/toolkit";

// actions
import { getAllConfigurations, delConfiguration } from "../actions";

// types
import { IError, IConfiguration } from "types";

interface IInitialState {
  configurations: Array<IConfiguration>;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  configurations: [],
  loading: false,
  error: null,
};

export const configurationsSlice = createSlice({
  name: "configurations",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Получение всех конфигураций
    builder.addCase(getAllConfigurations.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAllConfigurations.fulfilled, (state, action) => {
      state.loading = false;
      state.configurations = action.payload.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
    });
    builder.addCase(getAllConfigurations.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    // Удаление конфигурации
    builder.addCase(delConfiguration.fulfilled, (state, action) => {
      state.loading = false;
      state.configurations = current(state).configurations.filter(
        (conf) => conf.id !== action.payload
      );
    });
  },
});
