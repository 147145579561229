import { combineReducers, configureStore, AnyAction } from "@reduxjs/toolkit";
import api from "lib/api/requests";

// slices
import {
  localeSlice,
  paginationSlice,
  mobileMenuSlice,
  messagesSlice,
  asunoCheckedSlice,
} from "common/reducers";
import { lightingLineGroupsSlice } from "feat/objects/reducers";
import { lightingLineSlice } from "feat/indications/reducers";
import { lightingLineWorkingHistorySlice } from "feat/burningHours/reducers";
import {
  schedulesSlice,
  lightingLineScheduleSlice,
  loadScheduleSlice,
} from "feat/schedule/reducers";
import { lightingLineReadoutSlice } from "feat/chart/reducers";
import {
  lightingLineConsumptionSlice,
  downloadingReportsSlice,
} from "feat/reporting/reducers";
import { usersSlice, rolesSlice } from "feat/users/reducers";
import { authSlice } from "feat/auth/reducers";
import {
  devicesStatesSlice,
  devicesWorkingHistorySlice,
  deviceInfoSlice,
} from "feat/devices/reducers";
import {
  notifyParamsSlice,
  configurationsSlice,
  groupConfigurationSlice,
  lineConfigurationSlice,
} from "feat/asuno/reducers";
import { logsSlice } from "feat/logs/reducers";
import { directorySlice } from "feat/admin/reducers";
import { lightningControlSlice } from "feat/control/reducers";
import { lampsSlice } from "feat/lamps/reducers";
import { serviceSlice } from "feat/service/reducers";

// middlewares
import { authMiddleware } from "lib/middlewares";

const rootReducer = combineReducers({
  locale: localeSlice.reducer,
  pagination: paginationSlice.reducer,
  asunoChecked: asunoCheckedSlice.reducer,
  mobileMenu: mobileMenuSlice.reducer,
  currentUser: authSlice.reducer,
  lightingLineGroups: lightingLineGroupsSlice.reducer,
  lightingLine: lightingLineSlice.reducer,
  devicesStates: devicesStatesSlice.reducer,
  lightingLineWorkingHistory: lightingLineWorkingHistorySlice.reducer,
  devicesWorkingHistory: devicesWorkingHistorySlice.reducer,
  schedules: schedulesSlice.reducer,
  lightingLineSchedule: lightingLineScheduleSlice.reducer,
  lightingLineReadout: lightingLineReadoutSlice.reducer,
  consumption: lightingLineConsumptionSlice.reducer,
  users: usersSlice.reducer,
  roles: rolesSlice.reducer,
  service: serviceSlice.reducer,
  messages: messagesSlice.reducer,
  downloadingReports: downloadingReportsSlice.reducer,
  notifyParams: notifyParamsSlice.reducer,
  directory: directorySlice.reducer,
  logs: logsSlice.reducer,
  configurations: configurationsSlice.reducer,
  groupConfiguration: groupConfigurationSlice.reducer,
  lineConfiguration: lineConfigurationSlice.reducer,
  loadSchedule: loadScheduleSlice.reducer,
  lightningControl: lightningControlSlice.reducer,
  lamps: lampsSlice.reducer,
  deviceInfo: deviceInfoSlice.reducer,
});

const resettableRootReducer = (state: any, action: AnyAction) => {
  if (action.type === "auth/logout/fulfilled") {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

const store = configureStore({
  reducer: resettableRootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: { api },
      },
    }).concat(authMiddleware),
});

export default store;

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
