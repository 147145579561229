/* eslint-disable no-template-curly-in-string */
import { setLocale } from "yup";

setLocale({
  mixed: {
    required: "Поле обязательно",
    notType: "Неправильный формат",
  },
  string: {
    email: "Введите правильную почту",
    min: "Минимум ${min} символов",
    max: "Максимум ${max} символов",
    url: "Неправильная ссылка",
  },
  number: {
    min: "Минимум ${min} символов",
    max: "Максимум ${max} символов",
  },
});
