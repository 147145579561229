import { createSlice } from "@reduxjs/toolkit";

//actions
import { getLightingLineNotifyParams } from "../actions";

//types
import { IError, INotifyParams, Maybe } from "types";

interface IInitialState {
  notifyParams: Maybe<INotifyParams>;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  notifyParams: null,
  loading: false,
  error: null,
};

export const notifyParamsSlice = createSlice({
  name: "notifyParams",
  initialState,
  reducers: {
    cleanNotifyParams: (state) => {
      state.notifyParams = null;
    },
  },
  extraReducers: (builder) => {
    //Получение параметров уведомления линии освещения
    builder.addCase(getLightingLineNotifyParams.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLightingLineNotifyParams.fulfilled, (state, action) => {
      state.loading = false;
      state.notifyParams = action.payload;
    });
    builder.addCase(getLightingLineNotifyParams.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });
  },
});
