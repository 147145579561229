import { createSlice, current } from "@reduxjs/toolkit";

// actions
import { getRoles, deleteRole } from "../actions";

// types
import { IError, IRole } from "types";

interface IInitialState {
  rolesList: IRole[];
  rolesObjects: Record<string, IRole>;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  rolesList: [],
  rolesObjects: {},
  loading: false,
  error: null,
};

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    cleanRoles: (state) => {
      state.rolesList = [];
      state.rolesObjects = {};
    },
  },
  extraReducers: (builder) => {
    // Возвращает список ролей пользователей.
    builder.addCase(getRoles.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getRoles.fulfilled, (state, action) => {
      state.loading = false;
      state.rolesList = action.payload;
      state.rolesObjects = action.payload.reduce(
        (acc, role) => ({ ...acc, [role.role_id!]: role }),
        {}
      );
    });
    builder.addCase(getRoles.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    // удаление локальной роли
    builder.addCase(deleteRole.fulfilled, (state, action) => {
      state.loading = false;
      state.rolesList = current(state).rolesList.filter(
        (role) => role.role_id !== action.payload
      );
    });
  },
});
