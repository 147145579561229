import { createSlice } from "@reduxjs/toolkit";

//actions
import { getCustomers, getServices, addService, addCustomer } from "../actions";

//types
import { IError, ICustomer, IService } from "types";

interface IInitialState {
  customers: ICustomer[];
  services: IService[];
  loading: boolean;
  updating: boolean;
  error: IError;
}

const initialState: IInitialState = {
  customers: [],
  services: [],
  loading: false,
  updating: false,
  error: null,
};

export const directorySlice = createSlice({
  name: "directory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Получение всех заказчиков
    builder.addCase(getCustomers.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getCustomers.fulfilled, (state, action) => {
      state.loading = false;
      state.customers = action.payload?.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
    });
    builder.addCase(getCustomers.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //Получение всех заказчиков
    builder.addCase(getServices.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getServices.fulfilled, (state, action) => {
      state.loading = false;
      state.services = action.payload?.sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
    });
    builder.addCase(getServices.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //Показываем процесс
    builder.addCase(addService.pending, (state) => {
      state.updating = true;
      state.error = null;
    });
    builder.addCase(addService.fulfilled, (state, action) => {
      state.updating = false;
    });
    builder.addCase(addService.rejected, (state) => {
      state.updating = false;
      state.error = "Произошла ошибка при загрузке";
    });
    builder.addCase(addCustomer.pending, (state) => {
      state.updating = true;
      state.error = null;
    });
    builder.addCase(addCustomer.fulfilled, (state, action) => {
      state.updating = false;
    });
    builder.addCase(addCustomer.rejected, (state) => {
      state.updating = false;
      state.error = "Произошла ошибка при загрузке";
    });
  },
});
