import { createAsyncThunk } from "@reduxjs/toolkit";

//types
import { ThunkAPI, ILightingLineGroup } from "types";

export const getLightingLineGroups = createAsyncThunk<
  ILightingLineGroup[],
  undefined,
  ThunkAPI
>(
  "objects/getLightingLineGroups",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.objects.getLightingLineGroups();

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getLightingLineGroup = createAsyncThunk<
  ILightingLineGroup,
  undefined,
  ThunkAPI
>(
  "objects/getLightingLineGroup",
  async (_, { extra: { api }, rejectWithValue, getState }) => {
    const group_id = getState().lightingLineGroups.currentGroupId;
    try {
      const data = await api.objects.getLightingLineGroup(group_id);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
