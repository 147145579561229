import { Key, useState } from "react";
import { useAppDispatch } from "app/hooks";
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";

//components
import { Menu } from "antd";
import {
  BarsOutlined,
  SolutionOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";

//utils
import { mobileMenuSlice } from "common/reducers";

//const { SubMenu } = Menu;
const { setMobileMenuOpen } = mobileMenuSlice.actions;

export const DocsMenu: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [selectedKeys, setSelectedKeys] = useState([location.pathname]);
  const [openKeys, setOpenKeys] = useState(() => {
    const initialState: string[] = [];
    const storageKeys = localStorage.getItem("openKeys");

    if (!storageKeys) {
      localStorage.setItem("openKeys", JSON.stringify(initialState));
      return initialState;
    }

    return JSON.parse(storageKeys);
  });

  const onKeyClick = ({ key }: { key: string }) => {
    dispatch(setMobileMenuOpen(false));
    setSelectedKeys([key]);
  };

  const onSubMenuClick = (arg: Key[]) => {
    localStorage.setItem("openKeys", JSON.stringify(arg));
    setOpenKeys(arg);
  };

  return (
    <Menu
      theme="light"
      mode="inline"
      triggerSubMenuAction="click"
      onClick={onKeyClick}
      selectedKeys={selectedKeys}
      onOpenChange={onSubMenuClick}
      openKeys={openKeys}
    >
      <Menu.Item key="/docs" icon={<SolutionOutlined />}>
        <Link to="/docs">
          <FormattedMessage
            id="app.sustem-description"
            defaultMessage="Sustem description"
          />
        </Link>
      </Menu.Item>

      <Menu.Item key="/help" icon={<FileDoneOutlined />}>
        <Link to="/help">
          <FormattedMessage id="app.help" defaultMessage="Help" />
        </Link>
      </Menu.Item>

      <Menu.Item key="/changelog" icon={<BarsOutlined />}>
        <Link to="/changelog">
          <FormattedMessage id="app.changelog" defaultMessage="Changelog" />
        </Link>
      </Menu.Item>
    </Menu>
  );
};
