// components
import Icon from "@ant-design/icons";

export const InfinityIcon: React.FC = () => (
  <Icon
    component={() => (
      <svg
        enableBackground="new 0 0 100 111.5"
        height="25px"
        id="Capa_1"
        version="1.1"
        viewBox="0 0 100 111.5"
        width="35px"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M79.514,33.75c-10.396,0-21.791,5.792-29.514,14.577C42.275,39.542,30.881,33.75,20.486,33.75  C10.582,33.75,0,39.529,0,55.75c0,16.221,10.582,21.999,20.486,22l0,0c10.397,0,21.789-5.791,29.514-14.574  c7.723,8.783,19.117,14.574,29.512,14.574c9.906,0,20.488-5.779,20.488-22C100,39.529,89.418,33.75,79.514,33.75z M20.486,68.951  c-7.56,0-11.396-4.441-11.396-13.201c0-8.757,3.836-13.2,11.396-13.2c8.361,0,18.07,5.427,24.031,13.2  C38.557,63.523,28.85,68.951,20.486,68.951z M79.512,68.951c-8.361,0-18.07-5.428-24.029-13.201c5.959-7.773,15.67-13.2,24.031-13.2  c7.562,0,11.396,4.442,11.396,13.2C90.908,64.51,87.074,68.951,79.512,68.951z" />
      </svg>
    )}
  />
);
