import {
  createAsyncThunk,
  ActionCreatorWithPreparedPayload,
  PayloadAction,
} from "@reduxjs/toolkit";
import { intl } from "lib/locale";

// components
import { notification } from "antd";

// types
import {
  ThunkAPI,
  IEditLightingLineGroup,
  INotifyParams,
  ILightingLineGroup,
  ITariff,
} from "types";

// Создание группы освещения
export const сreateLightingLineGroup = createAsyncThunk<
  ILightingLineGroup,
  {
    body: IEditLightingLineGroup;
    setCurrentGroup: ActionCreatorWithPreparedPayload<
      [group: IEditLightingLineGroup],
      PayloadAction<ILightingLineGroup>,
      string,
      never,
      never
    >;
  },
  ThunkAPI
>(
  "objects/сreateLightingLineGroup",
  async (
    { body, setCurrentGroup },
    { extra: { api }, rejectWithValue, dispatch }
  ) => {
    try {
      const { group_id } = await api.asuno.сreateLightingLineGroup(
        JSON.stringify(body)
      );
      const data = await api.objects.getLightingLineGroup(group_id);
      await dispatch(setCurrentGroup(data));

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Редактирование группы освещения
export const editLightingLineGroup = createAsyncThunk<
  ILightingLineGroup,
  IEditLightingLineGroup,
  ThunkAPI
>(
  "objects/editLightingLineGroup",
  async (body, { extra: { api }, rejectWithValue }) => {
    try {
      await api.asuno.editLightingLineGroup(body);
      const data = await api.objects.getLightingLineGroup(body.group_id!);

      notification["success"]({
        message: `${intl.formatMessage({
          id: "asuno.object-changed-msg",
          defaultMessage: "Object edited",
        })} ${body.group_name}`,
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получение параметров уведомления линии освещения
export const getLightingLineNotifyParams = createAsyncThunk<
  INotifyParams,
  string,
  ThunkAPI
>(
  "objects/getLightingLineNotifyParams",
  async (line_id, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.asuno.getLightingLineNotifyParams(line_id);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// редактирование параметров уведомления линии освещения
export const editLightingLineNotifyParams = createAsyncThunk<
  null,
  { line_id: string; body: any },
  ThunkAPI
>(
  "objects/editLightingLineNotifyParams",
  async ({ line_id, body }, { extra: { api }, rejectWithValue }) => {
    try {
      await api.asuno.editLightingLineNotifyParams(line_id, body);

      notification["success"]({
        message: intl.formatMessage({
          id: "asuno.notify-params-changed-msg",
          defaultMessage: "Notification settings saved successfully",
        }),
      });

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// порядок объектов в отчете
export const reorderLightningLines = createAsyncThunk<
  null,
  Record<string, number>,
  ThunkAPI
>(
  "objects/reorderLightningLines",
  async (body, { extra: { api }, rejectWithValue }) => {
    try {
      await api.asuno.reorderLightningLines(body);

      notification["success"]({
        message: intl.formatMessage({
          id: "asuno.objects-order-changed-msg",
          defaultMessage:
            "The order of objects in the report was successfully saved",
        }),
      });

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Обновить информацию о тарифе ШУНО
export const updLightningLineTariff = createAsyncThunk<
  ILightingLineGroup,
  Array<{
    ll_id: string;
    tariff: ITariff;
  }>,
  ThunkAPI
>(
  "objects/updLightningLineTariff",
  async (data, { extra: { api }, rejectWithValue, getState }) => {
    const body = JSON.stringify(data);
    const group_id = getState().lightingLineGroups.currentGroupId;
    try {
      await api.asuno.updLightningLineTariff(body);
      const data = await api.objects.getLightingLineGroup(group_id);

      notification["success"]({
        message: intl.formatMessage({
          id: "devices.saved-success-msg",
        }),
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
