import { useAppDispatch, useAppSelector } from "app/hooks";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/styles";

//components
import { Avatar, Menu, Dropdown } from "antd";

//actions
import { logout } from "feat/auth/actions";

export const UserMenu: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const currentUser = useAppSelector((state) => state.currentUser.user);

  const fullname = `${currentUser?.name ?? ""} ${currentUser?.surname ?? ""}`;
  const init = `${currentUser?.name[0]}${currentUser?.surname[0]}`;

  const onLogoutClick = async () => {
    dispatch(logout());
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Link to="/users/profile">
          <FormattedMessage id="users.profile" defaultMessage="Profile" />
        </Link>
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item key="3" onClick={onLogoutClick}>
        <FormattedMessage id="app.log-out" defaultMessage="Log out" />
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
      <div className={classes.headerBlocks}>
        <Avatar size="small">{init}</Avatar>

        <span className={classes.userName}>{fullname}</span>
      </div>
    </Dropdown>
  );
};

const useStyles = makeStyles({
  headerBlocks: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: "12px",
    paddingRight: "12px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#252a3d",
      "@media (max-width: 768px)": {
        backgroundColor: "#fff",
      },
    },
  },

  userName: {
    paddingLeft: "8px",
    color: "#fff",
    "@media (max-width: 840px)": {
      display: "none",
    },
  },
});
