import { createSlice, current } from "@reduxjs/toolkit";

// actions
import { changeState } from "feat/substations/actions";
import {
  getAsunoSchedules,
  delAsunoSchedule,
  createAsunoSchedules,
  setCurrentSchedule,
  getLightingLineSchedule,
  getLampGroupsWithThisSchedule,
} from "../actions";

// types
import { IError, ISchedule, Maybe } from "types";

interface IInitialState {
  schedules: ISchedule[];
  currentSchedule: Maybe<ISchedule>;
  currentScheduleGroups: string[];
  loadingLampGroups: boolean;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  schedules: [],
  currentSchedule: null,
  currentScheduleGroups: [],
  loadingLampGroups: false,
  loading: false,
  error: null,
};

export const schedulesSlice = createSlice({
  name: "schedules",
  initialState,
  reducers: {
    cleanSchedule: (state) => {
      state.currentSchedule = null;
    },
  },
  extraReducers: (builder) => {
    // Получение списка расписаний по АСУНО
    builder.addCase(getAsunoSchedules.pending, (state) => {
      state.currentScheduleGroups = [];
      state.schedules = [];
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getAsunoSchedules.fulfilled, (state, action) => {
      const schedules = action.payload;
      state.schedules = [...schedules].sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
      state.loading = false;
    });
    builder.addCase(getAsunoSchedules.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    // Удаление расписания АСУНО
    builder.addCase(delAsunoSchedule.fulfilled, (state, action) => {
      state.schedules = current(state).schedules.filter(
        ({ id }) => id !== action.payload
      );
      state.loading = false;
    });

    // coздание расписания АСУНО
    builder.addCase(createAsunoSchedules.fulfilled, (state, action) => {
      const schedules = action.payload;
      state.schedules = [...schedules].sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
          sensitivity: "base",
        })
      );
    });

    // Получение списка расписаний по линии освещения
    builder.addCase(getLightingLineSchedule.pending, (state) => {
      state.schedules = [];
    });

    builder.addCase(setCurrentSchedule, (state, action) => {
      state.currentSchedule = action.payload;
    });

    // Получить ID групп светильников с указанным расписанием
    builder.addCase(getLampGroupsWithThisSchedule.pending, (state) => {
      state.currentScheduleGroups = [];
      state.loadingLampGroups = true;
      state.error = null;
    });
    builder.addCase(
      getLampGroupsWithThisSchedule.fulfilled,
      (state, action) => {
        state.currentScheduleGroups = action.payload;
        state.loadingLampGroups = false;
      }
    );
    builder.addCase(getLampGroupsWithThisSchedule.rejected, (state) => {
      state.loadingLampGroups = false;
      state.error = "Произошла ошибка при загрузке";
    });

    // обновляем счетчики количества групп у расписаний
    builder.addCase(changeState.fulfilled, (state, action) => {
      const scheduleCounters = action.payload.scheduleCounters;
      const schedules = current(state).schedules;
      if (schedules.length && scheduleCounters) {
        state.schedules = schedules.map((schedule) => ({
          ...schedule,
          lamp_groups_count: scheduleCounters[schedule.id],
        }));
      }
    });
  },
});
