import { notification } from "antd";
import { intl } from "lib/locale";

import { IScheduleFileCheckingError } from "types";

export const scheduleFileCheckError = (resp: IScheduleFileCheckingError) => {
  const rows = resp.invalid_rows;
  const errors = rows.map((row) => (
    <div>
      <span>
        Строка {row[0]}: {row[5]}.
      </span>
    </div>
  ));

  notification["error"]({
    duration: 0,
    message: intl.formatMessage({
      id: "error.schedule-not-added",
      defaultMessage: "Schedule not added",
    }),
    description: (
      <>
        <div>
          {intl.formatMessage({
            id: "error.file-has-errors",
            defaultMessage: "The file contains errors:",
          })}
        </div>
        {errors}
      </>
    ),
  });
};
