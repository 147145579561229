import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";
import { scheduleFileCheckError } from "./utils/scheduleFileCheckError";

// types
import { IGroupScheduleParams } from "types";

// Получение списка расписаний по линии освещения v1
const getLightingLineSchedule = async ({
  group_id,
  from,
  to,
}: IGroupScheduleParams) => {
  const params = `group_id=${group_id}&from=${from}&to=${to}`;
  const resp = await request(`/v1/lighting-line-schedule?${params}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Создание нового расписания
// Форма регистрации нового расписания
const createLightingLineSchedule = async (body: string) => {
  const resp = await request(`/v1/lighting-line-schedule`, "POST", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Удаление расписания
const deleteLightingLineSchedule = async (sched_id: number) => {
  const resp = await request(
    `/v1/lighting-line-schedule/${sched_id}`,
    "DELETE"
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Обновление расписания
// Форма редактирования расписания
const editLightingLineSchedule = async (sched_id: number, body: string) => {
  const resp = await request(
    `/v1/lighting-line-schedule/${sched_id}`,
    "PUT",
    body
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Создание списка расписаний
// Форма регистрации расписаний
const createLightingLineScheduleList = async (body: string) => {
  const resp = await request(
    `/v1/lighting-line-schedule-collection`,
    "POST",
    body
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Создание астрорасписания
// Форма добавления астрорасписания расписания
const createLightingLineSunSchedule = async (body: any) => {
  const resp = await request(`/v1/lighting-line-sun-schedule`, "POST", body);

  if (!resp.ok) {
    handleErrors(resp);
  }

  return null;
};

// Создание астрорасписания в новой системе расписаний
// Форма добавления астрорасписания расписания
const addScheduleRecordsUsingSunriseAndSunset = async (
  body: string,
  scheduleId: number
) => {
  const resp = await request(
    `/schedules/${scheduleId}/records/sunrise-sunset`,
    "POST",
    body
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Удаление списка расписаний
const deleteLightingLineScheduleList = async (
  to_ts: string,
  from_ts: string,
  group_id: number
) => {
  const params = `group_id=${group_id}&to_ts=${to_ts}&from_ts=${from_ts}`;
  const resp = await request(
    `/v1/lighting-line-schedule-collection?${params}`,
    "DELETE"
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Проверка файла с расписанием на наличие ошибок
const checkScheduleFile = async (file: FormData, useNewSchedule: boolean) => {
  const url = useNewSchedule
    ? "/api/schedules/file/check"
    : "/api/v1/create-schedule-from-file/check-schedule-file";

  const resp = await fetch(url, {
    method: "POST",
    body: file,
  });

  if (resp.status === 406) {
    const data = await resp.json();
    scheduleFileCheckError(data);
  }

  if (!resp.ok && resp.status !== 406) {
    handleErrors(resp);
  }
  return null;
};

// Запись данных расписания с файла в бд
const uploadScheduleFile = async ({
  formdata,
  group_id,
  useNewSchedule,
  scheduleId,
}: {
  formdata: FormData;
  group_id: number;
  useNewSchedule: boolean;
  scheduleId?: string;
}) => {
  const url = useNewSchedule
    ? `/api/schedules/${scheduleId}/file`
    : `/api/v1/create-schedule-from-file/upload?group_id=${group_id}`;

  const resp = await fetch(url, {
    method: "POST",
    body: formdata,
  });

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Формирование расписания в xlsx файл
const createScheduleToFile = async ({
  group_id,
  from,
  to,
}: IGroupScheduleParams) => {
  const params = `group_id=${group_id}&from=${from}${to ? `&to=${to}` : ""}`;
  const resp = await request(
    `/v1/create-schedule-to-file/download?${params}`,
    "GET"
  );

  if (!resp.ok) {
    handleErrors(resp);
  }

  const data = await resp.blob();
  return data;
};

// Создание астрорасписаний для заданного периода времени (не пишет его в БД)
const getLightingLineSunSchedule = async ({
  group_id,
  from,
  to,
}: IGroupScheduleParams) => {
  const params = `group_id=${group_id}&ts_st=${from}&ts_end=${to}`;
  const resp = await request(`/v1/lighting-line-sun-schedule?${params}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Загрузка расписания в счетчик
const loadSchedule = async (ll_id: string) => {
  const resp = await request(`/temporary/${ll_id}/load-schedule`, "POST");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Проверка загруженного расписания
const checkSchedule = async (ll_id: string) => {
  const resp = await request(`/temporary/${ll_id}/check-schedule`, "POST");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Получение списка расписаний по АСУНО
const getAsunoSchedules = async (group_id: number) => {
  const resp = await request(`/schedules/ll-group/${group_id}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// создание нового расписания АСУНО
const createAsunoSchedule = async (group_id: number, body: string) => {
  const resp = await request(`/schedules/ll-group/${group_id}`, "POST", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Удаление расписания АСУНО
const delAsunoSchedule = async (schedule_id: number) => {
  const resp = await request(`/schedules/${schedule_id}`, "DELETE");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Получение списка расписаний по линии освещения v1
const getScheduleWithRecords = async ({
  scheduleId,
  from,
  to,
}: {
  scheduleId: number | string;
  from: string;
  to: string;
}) => {
  const params = `from=${from}&to=${to}`;
  const resp = await request(`/schedules/${scheduleId}?${params}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Добавление одного отрезка расписания
const addScheduleRecord = async (scheduleId: number, body: string) => {
  const resp = await request(`/schedules/${scheduleId}/record`, "POST", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Добавление отрезков расписания
const addScheduleRecords = async (scheduleId: number, body: string) => {
  const resp = await request(`/schedules/${scheduleId}/records`, "POST", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Удаление списка расписаний
const deleteScheduleRecordsInTimeInterval = async (
  from: string,
  to: string,
  scheduleId: number
) => {
  const params = `ts_st=${from}&ts_end=${to}`;
  const resp = await request(
    `/schedules/${scheduleId}/records?${params}`,
    "DELETE"
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Редактирование отрезка расписания
const updateScheduleRecord = async (
  scheduleId: number,
  recordId: number,
  body: string
) => {
  const resp = await request(
    `/schedules/${scheduleId}/record/${recordId}`,
    "PUT",
    body
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Удаление отрезка расписания
const deleteScheduleRecord = async (scheduleId: number, recordId: number) => {
  const resp = await request(
    `/schedules/${scheduleId}/record/${recordId}`,
    "DELETE"
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

// Установить / отменить указанное расписание в переданных группах светильников.
const setScheduleForLampGroups = async (scheduleId: number, body: string) => {
  const resp = await request(
    `/schedules/${scheduleId}/lamp-groups/set-or-unset-schedule`,
    "POST",
    body
  );

  if (!resp.ok) {
    handleErrors(resp);
  }

  return null;
};

// Получить ID групп светильников с указанным расписанием
const getLampGroupsWithThisSchedule = async (scheduleId: number) => {
  const resp = await request(`/schedules/${scheduleId}/lamp-groups`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }

  const data = await resp.json();
  return data;
};

// Скачать расписание в xlsx
const downloadScheduleFile = async (
  scheduleId: number,
  from: string,
  to?: string
) => {
  const params = `from=${from}${to ? `&to=${to}` : ""}`;
  const resp = await request(`/schedules/${scheduleId}/file?${params}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }

  const data = await resp.blob();
  return data;
};

export const schedule = {
  getAsunoSchedules,
  getLightingLineSchedule,
  deleteLightingLineSchedule,
  deleteLightingLineScheduleList,
  createLightingLineSchedule,
  editLightingLineSchedule,
  createLightingLineScheduleList,
  createLightingLineSunSchedule,
  checkScheduleFile,
  uploadScheduleFile,
  createScheduleToFile,
  getLightingLineSunSchedule,
  loadSchedule,
  checkSchedule,
  delAsunoSchedule,
  createAsunoSchedule,
  getScheduleWithRecords,
  addScheduleRecord,
  addScheduleRecords,
  deleteScheduleRecordsInTimeInterval,
  updateScheduleRecord,
  deleteScheduleRecord,
  setScheduleForLampGroups,
  getLampGroupsWithThisSchedule,
  downloadScheduleFile,
  addScheduleRecordsUsingSunriseAndSunset,
};
