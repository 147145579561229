import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";
import moment from "moment";

// types
import { ILogFilter } from "types";

// Получение логов действий
const getLogs = async (
  group_id: number,
  filter: ILogFilter,
  pageSize: number
) => {
  const { user, asuno, period, page, action } = filter;

  const limit = `limit=${pageSize}`;
  const offset = `&offset=${pageSize * (page - 1)}`;
  const group = group_id
    ? `&group_id=${group_id}`
    : asuno
    ? `&group_id=${asuno}`
    : "";
  const user_id = user ? `&user_id=${user}` : "";
  const actions = action ? `&actions=${action}` : "";
  const from_ts = period[0]
    ? `&from_ts=${encodeURIComponent(
        moment(period[0]).startOf("day").format()
      )}`
    : "";
  const to_ts = period[1]
    ? `&to_ts=${encodeURIComponent(moment(period[1]).startOf("day").format())}`
    : "";

  const params = `?${limit}${group}${offset}${user_id}${from_ts}${to_ts}${actions}`;

  const resp = await request(`/v1/history${params}`, "GET");
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Получение типов действий для фильтра логов
const getLogsActions = async (groupId: number | null) => {
  const params = groupId ? `?group_id=${groupId}` : "";
  const resp = await request(`/v1/history-actions${params}`, "GET");
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Получение пользователей для фильтра логов
const getLogsUsers = async (group_id: number | null) => {
  const params = group_id ? `?group_id=${group_id}` : "";
  const resp = await request(`/v1/history-users${params}`, "GET");
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Получение логов действий в формате xlsx
const getLogFile = async (group_id: number, filter: ILogFilter) => {
  const { user, asuno, period, action } = filter;

  const group = group_id
    ? `&group_id=${group_id}`
    : asuno
    ? `&group_id=${asuno}`
    : "";
  const user_id = user ? `&user_id=${user}` : "";
  const actions = action ? `&actions=${action}` : "";
  const from_ts = period[0]
    ? `&from_ts=${encodeURIComponent(
        moment(period[0]).startOf("day").format()
      )}`
    : "";
  const to_ts = period[1]
    ? `&to_ts=${encodeURIComponent(moment(period[1]).startOf("day").format())}`
    : "";

  const params = `?${group}${user_id}${from_ts}${to_ts}${actions}`;

  const resp = await request(`/v1/history/xlsx${params}`, "GET");
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.blob();
  return data;
};

export const logs = {
  getLogs,
  getLogsActions,
  getLogsUsers,
  getLogFile,
};
