import { useState } from "react";
import { useAppDispatch } from "app/hooks";
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";

// components
import { Menu } from "antd";
import {
  BarsOutlined,
  UsergroupAddOutlined,
  HistoryOutlined,
  ControlOutlined,
} from "@ant-design/icons";

// utils
import { mobileMenuSlice } from "common/reducers";

const { setMobileMenuOpen } = mobileMenuSlice.actions;

export const AdminMenu: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [selectedKeys, setSelectedKeys] = useState([location.pathname]);

  const onKeyClick = ({ key }: { key: string }) => {
    dispatch(setMobileMenuOpen(false));
    setSelectedKeys([key]);
  };

  return (
    <Menu
      theme="light"
      mode="inline"
      triggerSubMenuAction="click"
      onClick={onKeyClick}
      selectedKeys={selectedKeys}
    >
      <Menu.Item key="/admin/users" icon={<UsergroupAddOutlined />}>
        <Link to="/admin/users">
          <FormattedMessage id="app.users" defaultMessage="Staff" />
        </Link>
      </Menu.Item>

      <Menu.Item key="/admin/logs" icon={<HistoryOutlined />}>
        <Link to="/admin/logs">
          <FormattedMessage id="app.logs" defaultMessage="Logs" />
        </Link>
      </Menu.Item>

      <Menu.Item key="/admin/directories" icon={<BarsOutlined />}>
        <Link to="/admin/directories">
          <FormattedMessage
            id="app.directories"
            defaultMessage="Reference books"
          />
        </Link>
      </Menu.Item>

      <Menu.Item key="/admin/configurations" icon={<ControlOutlined />}>
        <Link to="/admin/configurations">
          <FormattedMessage
            id="app.configurations"
            defaultMessage="Configurations"
          />
        </Link>
      </Menu.Item>
    </Menu>
  );
};
