import { useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/styles";

// components
import { Drawer, Typography, Input } from "antd";

import { DeviceInfo } from "./DeviceInfo";

// utils
import { intl } from "lib/locale";

// actions
import { getDeviceInfo } from "feat/devices/actions";

const { Text } = Typography;
const { Search } = Input;

export const DeviceSearch: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const { loading, deviceInfo } = useAppSelector((state) => state.deviceInfo);

  const toggleOpen = () => setOpen((v) => !v);

  const onSearch = async (term: string) => {
    if (term) {
      dispatch(getDeviceInfo(term));
    }
  };

  return (
    <>
      <div className={classes.button} onClick={toggleOpen}>
        <Text className={classes.buttonText}>
          <FormattedMessage id="ui.search" />
        </Text>
      </div>

      <Drawer
        placement="right"
        visible={open}
        onClose={toggleOpen}
        title={<FormattedMessage id="ui.search" />}
        destroyOnClose
      >
        <Search
          placeholder={intl.formatMessage({ id: "ui.emeter-num" })}
          allowClear
          enterButton={<FormattedMessage id="ui.find" />}
          onSearch={onSearch}
          loading={loading}
        />

        <DeviceInfo data={deviceInfo} />
      </Drawer>
    </>
  );
};

const useStyles = makeStyles({
  button: {
    display: "flex",
    alignItems: "center",
    height: "100%",
    paddingLeft: "12px",
    paddingRight: "12px",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#252a3d",
    },
  },
  buttonText: {
    paddingLeft: 8,
    color: "#fff",
  },
});
