interface IProps {
  color?: string;
}

export const ManualModeIcon: React.FC<IProps> = ({ color = "#000" }) => (
  <svg
    viewBox="0 0 32 32"
    height="25px"
    xmlns="http://www.w3.org/2000/svg"
    fill={color}
  >
    <g id="touch">
      <path d="M27.34,23s0,.06,0,.08a.27.27,0,0,1,0,.09l-1.38,8A1,1,0,0,1,25,32H24.8A1,1,0,0,1,24,30.83l1.36-7.91V17a1.07,1.07,0,0,0-2.13,0v6a1,1,0,0,1-2,0V14.33a1.07,1.07,0,0,0-2.14,0V23a1,1,0,0,1-2,0V11.67a1.07,1.07,0,0,0-2.14,0V23s0,.07,0,.1a1.42,1.42,0,0,1,0,.2.87.87,0,0,1-.07.17.91.91,0,0,1-.11.16.93.93,0,0,1-.14.14l-.08.07-2,1.27-.11,0-.09.05a1.13,1.13,0,0,1-.31.06h0a1.13,1.13,0,0,1-.32-.06l-.07,0a1,1,0,0,1-.29-.19h0L9.22,23a1.16,1.16,0,0,0-1.56,0,1,1,0,0,0-.28.69,1,1,0,0,0,.3.71l5.7,5.9a1,1,0,0,1,0,1.41,1,1,0,0,1-.69.28,1,1,0,0,1-.72-.31L6.26,25.81a3,3,0,0,1,0-4.24,3.21,3.21,0,0,1,4.33,0L12.1,23l.83-.54V11.67a3.07,3.07,0,0,1,6.13-.13,3,3,0,0,1,1.08-.21,3,3,0,0,1,3.05,2.87,3.24,3.24,0,0,1,1.09-.2,3,3,0,0,1,3.06,3Zm-12-16.33a4.56,4.56,0,0,1,3.92,2.18,1,1,0,0,0,1.72-1,6.61,6.61,0,0,0-5.64-3.15A6.44,6.44,0,0,0,8.79,11a6.24,6.24,0,0,0,.88,3.18,1,1,0,0,0,.86.49,1,1,0,0,0,.51-.14,1,1,0,0,0,.35-1.38,4.17,4.17,0,0,1-.6-2.15A4.43,4.43,0,0,1,15.31,6.67ZM7,16.65a1,1,0,0,0,.51-.15,1,1,0,0,0,.34-1.37A8.66,8.66,0,1,1,23.71,8.57a1,1,0,0,0,1.21.73,1,1,0,0,0,.73-1.21A10.65,10.65,0,1,0,6.18,16.16,1,1,0,0,0,7,16.65Z" />
    </g>
  </svg>
);
