import { createAsyncThunk } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

// components
import { notification } from "antd";

// actions
import { updateLightningLinesState } from "feat/indications/actions";

// types
import {
  ThunkAPI,
  IConfiguration,
  IConnectionFields,
  IConnectionInput,
  IReplaceModemInput,
  IReplaceDeviceInput,
  IReplaceDevicesInput,
  ILightingLineGroup,
} from "types";

// Получение всех конфигураций
export const getAllConfigurations = createAsyncThunk<
  IConfiguration[],
  undefined,
  ThunkAPI
>(
  "configurations/getAllConfigurations",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.asuno.getAllConfigurations();

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Создание новой конфигурации
export const addConfiguration = createAsyncThunk<
  IConfiguration[],
  Omit<IConfiguration, "id">,
  ThunkAPI
>(
  "configurations/addConfigurations",
  async (conf, { extra: { api }, rejectWithValue }) => {
    const body = JSON.stringify(conf);
    try {
      const data = await api.asuno.addConfiguration(body);

      notification.open({
        message: conf.name,
        description: intl.formatMessage({
          id: "devices.saved-success-msg",
          defaultMessage: "Saved Successfully",
        }),
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Обновление конфигурации
export const updConfiguration = createAsyncThunk<
  number,
  { config_id: number; name: string },
  ThunkAPI
>(
  "configurations/updConfiguration",
  async ({ config_id, name }, { extra: { api }, rejectWithValue }) => {
    try {
      await api.asuno.updConfiguration(config_id, name);

      notification.open({
        message: name,
        description: intl.formatMessage({
          id: "devices.saved-success-msg",
          defaultMessage: "Saved Successfully",
        }),
      });

      return config_id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Удаление конфигурации
export const delConfiguration = createAsyncThunk<number, number, ThunkAPI>(
  "configurations/delConfiguration",
  async (config_id, { extra: { api }, rejectWithValue }) => {
    try {
      await api.asuno.delConfiguration(config_id);

      return config_id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получить конфигурацию группы
export const getGroupConfiguration = createAsyncThunk<
  IConfiguration,
  number,
  ThunkAPI
>(
  "groupConfiguration/getGroupConfiguration",
  async (group_id, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.asuno.getGroupConfiguration(group_id);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Настроить конфигурацию группы
export const updGroupConfiguration = createAsyncThunk<
  null,
  { group_id: number; config_id: number },
  ThunkAPI
>(
  "groupConfiguration/updGroupConfiguration",
  async (
    { group_id, config_id },
    { extra: { api }, rejectWithValue, dispatch, getState }
  ) => {
    const currentGroup = getState().lightingLineGroups.currentGroup;
    try {
      await api.asuno.updGroupConfiguration(group_id, config_id);
      await dispatch(getGroupConfiguration(group_id));

      notification.open({
        message: currentGroup.group_name,
        description: intl.formatMessage({
          id: "devices.saved-success-msg",
          defaultMessage: "Saved Successfully",
        }),
      });

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получить конфигурацию линии освещения
export const getLineConfiguration = createAsyncThunk<
  IConfiguration,
  string,
  ThunkAPI
>(
  "lineConfiguration/getLineConfiguration",
  async (lineId, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.asuno.getLineConfiguration(lineId);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получить поля, которые необходимо заполнить для подключения устройств по данной конфигурации
export const getConnectionFields = createAsyncThunk<
  IConnectionFields,
  number,
  ThunkAPI
>(
  "lineConfiguration/getConnectionFields",
  async (configId, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.asuno.getConnectionFields(configId);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Подключить новые устройства к ШУНО.
// Работает только для тех ШУНО, к которым ещё не подключены устройства.
export const connectDevices = createAsyncThunk<
  IConnectionFields,
  {
    lineName: string;
    lineId: string;
    modifier: IConnectionInput;
  },
  ThunkAPI
>(
  "lineConfiguration/connectDevices",
  async (
    { lineName, lineId, modifier },
    { extra: { api }, rejectWithValue, dispatch }
  ) => {
    const body = JSON.stringify(modifier);
    try {
      const data = await api.asuno.connectDevices(lineId, body);
      await dispatch(updateLightningLinesState());

      notification.open({
        message: lineName,
        description: intl.formatMessage({
          id: "devices.saved-success-msg",
          defaultMessage: "Saved Successfully",
        }),
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Замена модема
export const replaceModem = createAsyncThunk<
  null,
  {
    lineName: string;
    lineId: string;
    modifier: IReplaceModemInput;
  },
  ThunkAPI
>(
  "lineConfiguration/replaceModem",
  async (
    { lineName, lineId, modifier },
    { extra: { api }, rejectWithValue, dispatch }
  ) => {
    const body = JSON.stringify(modifier);
    try {
      await api.asuno.replaceModem(lineId, body);
      await dispatch(updateLightningLinesState());

      notification.open({
        message: lineName,
        description: intl.formatMessage({
          id: "devices.saved-success-msg",
          defaultMessage: "Saved Successfully",
        }),
      });

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Меняет серийный номер счётчика и / или управляющего устройства.
// Позволяет сделать замену на устройство той же модели. Для смены модели устройста нужно менять конфигурацию шкафа.
export const replaceDevice = createAsyncThunk<
  null,
  {
    lineName: string;
    lineId: string;
    modifier: IReplaceDeviceInput;
  },
  ThunkAPI
>(
  "lineConfiguration/replaceDevice",
  async (
    { lineName, lineId, modifier },
    { extra: { api }, rejectWithValue, dispatch }
  ) => {
    const body = JSON.stringify(modifier);
    try {
      await api.asuno.replaceDevice(lineId, body);
      await dispatch(updateLightningLinesState());

      notification.open({
        message: lineName,
        description: intl.formatMessage({
          id: "devices.saved-success-msg",
          defaultMessage: "Saved Successfully",
        }),
      });

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Заменить устройства ШУНО, опционально - поменять конфигурацию шкафа.
// Работает только для тех ШУНО, к которым подключены устройства.
export const replaceDevices = createAsyncThunk<
  ILightingLineGroup,
  {
    lineName: string;
    lineId: string;
    modifier: IReplaceDevicesInput;
  },
  ThunkAPI
>(
  "lineConfiguration/replaceDevices",
  async (
    { lineName, lineId, modifier },
    { extra: { api }, rejectWithValue, dispatch, getState }
  ) => {
    const body = JSON.stringify(modifier);
    const group_id = getState().lightingLineGroups.currentGroupId;
    try {
      await api.asuno.replaceDevices(lineId, body);
      await dispatch(updateLightningLinesState());
      const data = await api.objects.getLightingLineGroup(group_id);

      notification.open({
        message: lineName,
        description: intl.formatMessage({
          id: "devices.saved-success-msg",
          defaultMessage: "Saved Successfully",
        }),
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получить поля, которые необходимо заполнить для замены устройств по данной конфигурации
export const getReplacementFields = createAsyncThunk<
  IConnectionFields,
  number,
  ThunkAPI
>(
  "lineConfiguration/getReplacementFields",
  async (configId, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.asuno.getReplacementFields(configId);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
