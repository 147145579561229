import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";

//actions
import { getLightingLineGroups, getLightingLineGroup } from "../actions";
import {
  сreateLightingLineGroup,
  editLightingLineGroup,
  updLightningLineTariff,
  replaceDevices,
} from "feat/asuno/actions";
import {
  addLightingLine,
  updateLightingLine,
  deleteLightingLine,
} from "feat/devices/actions";
import { logout } from "feat/auth/actions";

//types
import { IError, ILightingLineGroup } from "types";

const getCurrentGroupId = () => {
  const id = localStorage.getItem("id");
  if (id) {
    return JSON.parse(id);
  }
  return 0;
};

interface IInitialState {
  currentGroupId: number;
  currentGroup: ILightingLineGroup | any;
  groups: Record<string, ILightingLineGroup>;
  current: number;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  currentGroupId: getCurrentGroupId(),
  currentGroup: {},
  groups: {},
  current: getCurrentGroupId(),
  loading: false,
  error: null,
};

export const lightingLineGroupsSlice = createSlice({
  name: "lightingLineGroups",
  initialState,
  reducers: {
    setCurrentGroup: {
      reducer: (state, action: PayloadAction<ILightingLineGroup>) => {
        state.currentGroup = action.payload;

        if (Object.keys(action.payload).length) {
          state.currentGroupId = action.payload.group_id;
          state.current = current(state).currentGroup.group_id;
        } else {
          state.currentGroupId = 0;
          state.current = 0;
        }
      },
      prepare: (group) => {
        localStorage.setItem(
          "id",
          JSON.stringify(group.group_id ? group.group_id : 0)
        );

        return { payload: group };
      },
    },
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
    // Обновяем список линий в группе при изменении сортировки
    setSortedLines: (state, action) => {
      const groups = current(state).groups;
      const currentGroup = current(state).current;

      state.currentGroup = action.payload;
      state.groups = { ...groups, [currentGroup]: action.payload };
    },
    // обновляем ЛО после добавления счетчика/модема выставляем флаг is_linked: true
    // обновляем ЛО после успешного тестирования, ставим in_maintenance=true
    setLinkedLine: (state, action) => {
      const currentGroup: ILightingLineGroup = current(state).currentGroup;
      const updatedLine = action.payload;
      const updatedGroup = {
        ...currentGroup,
        lines: currentGroup.lines.map((line) =>
          line.id === updatedLine.id ? updatedLine : line
        ),
      };

      state.currentGroup = updatedGroup;
      state.groups = {
        ...current(state).groups,
        [currentGroup.group_id]: updatedGroup,
      };
    },
  },
  extraReducers: (builder) => {
    // Получение списка групп
    builder.addCase(getLightingLineGroups.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLightingLineGroups.fulfilled, (state, action) => {
      state.loading = false;
      state.groups = action.payload.reduce(
        (acc, group) => ({ ...acc, [group.group_id]: group }),
        {}
      );
    });
    builder.addCase(getLightingLineGroups.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    // Получение текущей группы
    builder.addCase(getLightingLineGroup.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLightingLineGroup.fulfilled, (state, action) => {
      state.loading = false;
      state.currentGroup = action.payload;
    });
    builder.addCase(getLightingLineGroup.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    // создание новой АСУНО
    builder.addCase(сreateLightingLineGroup.fulfilled, (state, action) => {
      const groups = current(state).groups;
      const newAsuno = action.payload;

      state.loading = false;
      state.groups = { ...groups, [newAsuno.group_id]: newAsuno };
    });

    // редактирвоание АСУНО
    builder.addCase(editLightingLineGroup.fulfilled, (state, action) => {
      const groups = current(state).groups;
      const asuno = action.payload;

      state.loading = false;
      state.groups = {
        ...groups,
        [asuno.group_id]: asuno,
      };
      state.currentGroup = asuno;
    });

    //добавляем новую линию освещения
    builder.addCase(addLightingLine.fulfilled, (state, action) => {
      const currentGroup = current(state).currentGroup;
      const groups = current(state).groups;

      const newLine = action.payload;
      const updatedGroup = {
        ...currentGroup,
        lines: [...currentGroup.lines, newLine],
      };

      state.currentGroup = updatedGroup;

      state.groups = {
        ...groups,
        [currentGroup.group_id]: updatedGroup,
      };
    });

    // редактируем линию освещения включает в себя замену счетчика/модема
    builder.addCase(updateLightingLine.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateLightingLine.fulfilled, (state, action) => {
      const currentGroup = current(state).currentGroup;
      const groups = current(state).groups;

      const updatedLines = action.payload;
      const updatedGroup = {
        ...currentGroup,
        lines: updatedLines,
      };

      state.currentGroup = updatedGroup;

      state.groups = {
        ...groups,
        [currentGroup.group_id]: updatedGroup,
      };
      state.loading = false;
    });
    builder.addCase(updateLightingLine.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    // удаление линии освещения
    builder.addCase(deleteLightingLine.pending, (state) => {
      state.error = null;
    });
    builder.addCase(deleteLightingLine.fulfilled, (state, action) => {
      const currentGroup: ILightingLineGroup = current(state).currentGroup;
      const groups = current(state).groups;

      const updatedGroup = {
        ...currentGroup,
        lines: currentGroup.lines.filter((line) => line.id !== action.payload),
      };

      state.currentGroup = updatedGroup;

      state.groups = {
        ...groups,
        [currentGroup.group_id]: updatedGroup,
      };
    });
    builder.addCase(deleteLightingLine.rejected, (state) => {
      state.error = "Произошла ошибка при загрузке";
    });

    // Обновить информацию о тарифе ШУНО
    builder.addCase(updLightningLineTariff.pending, (state) => {
      state.error = null;
    });
    builder.addCase(updLightningLineTariff.fulfilled, (state, action) => {
      state.currentGroup = action.payload;
    });
    builder.addCase(updLightningLineTariff.rejected, (state) => {
      state.error = "Произошла ошибка при загрузке";
    });

    // Заменить устройства ШУНО, опционально - поменять конфигурацию шкафа.
    builder.addCase(replaceDevices.pending, (state) => {
      state.error = null;
    });
    builder.addCase(replaceDevices.fulfilled, (state, action) => {
      const currentGroupId = current(state).currentGroupId;
      const groups = current(state).groups;

      state.currentGroup = action.payload;
      state.groups = {
        ...groups,
        [currentGroupId]: action.payload,
      };
    });
    builder.addCase(replaceDevices.rejected, (state) => {
      state.error = "Произошла ошибка при загрузке";
    });

    // logout
    builder.addCase(logout.fulfilled, (state) => {
      state.currentGroupId = 0;
      state.current = 0;
    });
  },
});
