import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";

const getLightingLineGroups = async () => {
  const resp = await request(`/v1/lighting-line-group`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

const getLightingLineGroup = async (group_id: number) => {
  const resp = await request(`/v1/lighting-line-group/${group_id}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const objects = {
  getLightingLineGroups,
  getLightingLineGroup,
};
