import { createSlice } from "@reduxjs/toolkit";

//actions
import { getBurningHoursData } from "../actions";

//types
import { IError, ILinesBurningHoursReport } from "types";

interface IInitialState {
  linesWorkingHistory: { [k: string]: ILinesBurningHoursReport };
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  linesWorkingHistory: {},
  loading: false,
  error: null,
};

export const lightingLineWorkingHistorySlice = createSlice({
  name: "lightingLine",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Получение списка линий освещения
    builder.addCase(getBurningHoursData.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getBurningHoursData.fulfilled, (state, action) => {
      state.loading = false;
      state.linesWorkingHistory = Object.fromEntries(
        action.payload.map((elem) => [elem.ll_id, elem])
      );
    });
    builder.addCase(getBurningHoursData.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });
  },
});
