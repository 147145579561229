import { useAppSelector } from "app/hooks";
import { Redirect, Route, RouteProps } from "react-router-dom";

export const PrivateRoute: React.FC<RouteProps> = (props) => {
  const loggedUser = useAppSelector((state) => state.currentUser.loggedUser);

  const user = localStorage.getItem("user");

  if (!loggedUser || !user) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
};
