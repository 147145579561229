import React, { useContext, useState, useEffect, useCallback } from "react";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { intl } from "lib/locale";
import * as Sentry from "@sentry/react";
import moment from "moment";

// components
import { notification } from "antd";

// actions
import { changeState } from "feat/substations/actions";
import { cleanMessages } from "common/reducers";
import { logout } from "feat/auth/actions";

// utils
import { websocket } from "lib/websocket";
import { sid } from "utils/sid";
import { parseWsMsg } from "utils/parseWsMsg";
import { isProduction } from "utils/env";

// types
import { ILightingLineGroup, IChangeStateWS } from "types";

const WebsocketProviderCtx = React.createContext({
  wsClose: () => {},
});

export const WebsocketProvider: React.FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const [ws, setWs] = useState<WebSocket | null>(null);
  const [time, setTime] = useState<number>(0);
  const [timer, setTimer] = useState<any>(null);

  const loggedUser = useAppSelector((state) => state.currentUser.loggedUser);
  const currentGroup: ILightingLineGroup = useAppSelector(
    (state) => state.lightingLineGroups.currentGroup
  );
  const messages = useAppSelector((state) => state.messages);

  const isAsunoChecked = Object.keys(currentGroup).length;
  const workingLines = currentGroup?.lines
    ?.filter((line) => line.in_maintenance)
    .map((line) => ({
      type: "line",
      id: line.id,
    }));

  const devices = currentGroup?.flags?.use_photorelay
    ? currentGroup?.photorelays.map((item) => ({
        type: "photorelay",
        id: item.id,
      }))
    : [];

  useEffect(() => {
    if (timer) {
      clearTimeout(timer);
    }
    if (messages.length) {
      setTimer(
        setTimeout(async () => {
          await parseWsMsg(messages, currentGroup);
          await dispatch(cleanMessages());
        }, time)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, messages, time]);

  const connect = useCallback(() => {
    const ws = websocket("/ws");
    setWs(ws);

    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          ver: 1,
          ts: parseInt(moment().format("x")) * 1000,
          sid: sid(),
          proto: "subToObjUpdatesReq",
          objects: [...workingLines, ...devices],
        })
      );
    };

    ws.onmessage = (e) => {
      const msg: IChangeStateWS = JSON.parse(e.data);

      if (isAsunoChecked && msg?.changedStates) {
        setTime(2000);
        dispatch(changeState(msg));
      }

      if (isAsunoChecked && msg?.proto === "errorResp") {
        const description =
          msg?.message ??
          intl.formatMessage({
            id: "error.ws-command-error-msg",
            defaultMessage: "Command not sent",
          });

        //выводим сообщение об ошибке
        notification["error"]({
          message: intl.formatMessage({
            id: "error.error",
            defaultMessage: "Error",
          }),
          description,
        });
      }
    };

    ws.onclose = (e) => {
      if (e.code !== 1000 && e.code !== 1001 && e.code !== 1005) {
        if (e.code === 4004) {
          dispatch(logout());
        } else {
          setTimeout(() => {
            connect();
          }, 1000);
        }
      }
    };

    ws.onerror = (e) => {
      Sentry.captureException(e);
      ws.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAsunoChecked]);

  useEffect(() => {
    if ((!isAsunoChecked || !loggedUser) && ws) {
      ws.close();
      setWs(null);
    }
  }, [isAsunoChecked, ws, loggedUser]);

  useEffect(() => {
    if (loggedUser && isAsunoChecked && isProduction && !ws) {
      connect();
    }
  }, [connect, isAsunoChecked, loggedUser, ws]);

  const wsClose = () => {
    dispatch(cleanMessages());

    if (ws) {
      ws.close();
      setWs(null);
    }
  };

  return (
    <WebsocketProviderCtx.Provider
      value={{
        wsClose,
      }}
    >
      {children}
    </WebsocketProviderCtx.Provider>
  );
};

export const useWebsocket = () => useContext(WebsocketProviderCtx);
