import { createAsyncThunk } from "@reduxjs/toolkit";

//types
import { ThunkAPI, ICustomer, IService, ILightingLineGroup } from "types";

//Получение всех заказчиков
export const getCustomers = createAsyncThunk<ICustomer[], undefined, ThunkAPI>(
  "directory/getCustomers",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.directories.getCustomers();

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Создание заказчика
export const addCustomer = createAsyncThunk<any, string, ThunkAPI>(
  "directory/addCustomer",
  async (name, { extra: { api }, rejectWithValue, dispatch }) => {
    try {
      await api.directories.addCustomer(name);
      await dispatch(getCustomers());

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Изменение заказчика
export const updCustomer = createAsyncThunk<any, ICustomer, ThunkAPI>(
  "directory/updCustomer",
  async ({ name, id }, { extra: { api }, rejectWithValue, dispatch }) => {
    try {
      await api.directories.updCustomer(name, id);
      await dispatch(getCustomers());

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Удаление заказчика
export const delCustomer = createAsyncThunk<any, number, ThunkAPI>(
  "directory/delCustomer",
  async (id, { extra: { api }, rejectWithValue, dispatch }) => {
    try {
      await api.directories.delCustomer(id);
      await dispatch(getCustomers());

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/////////////////////ОБСЛУЖИВАЮЩИЕ ОРГАНИЗАЦИИ//////////////////////////////

//Получение всех обслуживающих организаций 🔧
export const getServices = createAsyncThunk<IService[], undefined, ThunkAPI>(
  "directory/getServices",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.directories.getServices();

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Создание обслуживающей организации 🔧
export const addService = createAsyncThunk<any, string, ThunkAPI>(
  "directory/addService",
  async (name, { extra: { api }, rejectWithValue, dispatch }) => {
    try {
      await api.directories.addService(name);
      dispatch(getServices());

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Изменение обслуживающей организации
export const updService = createAsyncThunk<any, IService, ThunkAPI>(
  "directory/updService",
  async ({ id, name }, { extra: { api }, rejectWithValue, dispatch }) => {
    try {
      await api.directories.updService(id, name);
      dispatch(getServices());

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Удаление обслуживающей организации
export const delService = createAsyncThunk<any, number, ThunkAPI>(
  "directory/delService",
  async (id, { extra: { api }, rejectWithValue, dispatch }) => {
    try {
      await api.directories.delService(id);
      dispatch(getServices());

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

//Привязка обслуживающей организации к группе 🔧
export const addServicesLinks = createAsyncThunk<any, number[], ThunkAPI>(
  "directory/addServicesLinks",
  async (servicesIds, { extra: { api }, rejectWithValue, getState }) => {
    const currentGroup: ILightingLineGroup =
      getState().lightingLineGroups.currentGroup;
    const currentServices = currentGroup.services
      ? Object.keys(currentGroup.services).map((item) => parseInt(item, 10))
      : [];

    const added = servicesIds.filter((id) => !currentServices.includes(id));
    const deleted = currentServices.filter(
      (id) => !servicesIds.includes(id) && !added.includes(id)
    );

    const addBody = added.map((service_id) => ({
      service_id,
      group_id: currentGroup.group_id,
    }));
    const deletedBody = deleted.map((service_id) => ({
      service_id,
      group_id: currentGroup.group_id,
    }));

    try {
      await api.directories.addServicesLinks(addBody);
      await api.directories.delServicesLinks(deletedBody);

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
