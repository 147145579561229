import { useState } from "react";
import { useAppSelector, useAppDispatch } from "app/hooks";
import { Link, useLocation } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { makeStyles } from "@material-ui/styles";

// components
import { MainMenu } from "common/MainMenu";
import { Drawer, Menu, Divider } from "antd";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import { GroupSelect } from "common/GroupSelect";
import { PageFooter } from "common/MenuLayout/Footer";
import { TelegramNotification } from "ui/TelegramNotification";
import { DocsMenu } from "feat/docs/components/DocsMenu";
import { AdminMenu } from "common/MenuLayout";

// slice
import { mobileMenuSlice } from "common/reducers";
import { lightingLineGroupsSlice } from "feat/objects/reducers/lightingLineGroupsSlice";
import { asunoCheckedSlice } from "common/reducers";

// actions
import { logout } from "feat/auth/actions";

// utils
import { useWidth } from "utils/useWidth";

const { setAsunoChecked } = asunoCheckedSlice.actions;
const { setCurrentGroup } = lightingLineGroupsSlice.actions;
const { setMobileMenuOpen } = mobileMenuSlice.actions;

interface IProps {
  isSuperAdmin: boolean;
}

const docPages = ["/docs", "/changelog", "/help"];

export const MobileMainMenu: React.FC<IProps> = ({ isSuperAdmin }) => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isMobile } = useWidth();

  const currentGroup = useAppSelector(
    (state) => state.lightingLineGroups.currentGroup
  );
  const currentUser = useAppSelector((state) => state.currentUser.user);
  const loggedUser = useAppSelector((state) => state.currentUser.loggedUser);
  const visible = useAppSelector((state) => state.mobileMenu);
  const [selectedKeys, setSelectedKeys] = useState([location.pathname]);

  if (!isMobile) {
    return null;
  }

  const fullname = `${currentUser?.name ?? ""} ${currentUser?.surname ?? ""}`;

  const isAsunoChecked = Object.keys(currentGroup).length;
  const isDocsPages = docPages.includes(location.pathname);
  const isAdminPage = location.pathname.includes("/admin");
  const isSuperAdminPage = isAdminPage && isSuperAdmin;

  const onKeyClick = ({ key }: { key: string }) => {
    dispatch(setMobileMenuOpen(false));
    setSelectedKeys([key]);
  };

  const onLogoutClick = async () => {
    dispatch(logout());
  };

  const onClose = () => {
    dispatch(setMobileMenuOpen(false));
  };

  const toggleCollapsed = () => {
    dispatch(setMobileMenuOpen(true));
  };

  const onRootClick = () => {
    dispatch(setCurrentGroup({}));
    dispatch(setAsunoChecked(false));
  };

  return (
    <Drawer
      title={
        isAsunoChecked ? (
          <GroupSelect mobileMenu />
        ) : (
          <span className={classes.userName}>USPherum 3</span>
        )
      }
      placement="left"
      handler={
        <div
          className={classes.collapsedButton}
          onClick={() => toggleCollapsed()}
        >
          {visible ? <CloseOutlined /> : <MenuOutlined />}
        </div>
      }
      onClose={onClose}
      visible={visible}
      bodyStyle={{
        padding: 8,
      }}
      className={classes.root}
      headerStyle={{ paddingLeft: 8 }}
    >
      {isAsunoChecked ? (
        //Если не выбрана асуно не показываем главное меню
        <>
          <TelegramNotification mobile />

          <Divider />

          {isDocsPages ? <DocsMenu /> : <MainMenu />}

          <Divider />
        </>
      ) : isDocsPages && loggedUser ? (
        <>
          <DocsMenu />
          <Divider />
        </>
      ) : isSuperAdminPage ? (
        <>
          <AdminMenu />
          <Divider />
        </>
      ) : null}

      {loggedUser ? (
        <>
          <span className={classes.userName}>{fullname}</span>

          <Menu
            theme="light"
            mode="inline"
            onClick={onKeyClick}
            selectedKeys={selectedKeys}
          >
            {isSuperAdmin ? (
              <>
                <Menu.Item key="/">
                  <Link to="/" onClick={onRootClick}>
                    <FormattedMessage id="app.asuno" defaultMessage="Objects" />
                  </Link>
                </Menu.Item>

                <Menu.Item key="admin" onClick={onRootClick}>
                  <Link to="/admin/users">
                    <FormattedMessage
                      id="app.control"
                      defaultMessage="Control"
                    />
                  </Link>
                </Menu.Item>
              </>
            ) : null}

            <Menu.Item key="/users/profile">
              <Link to="/users/profile">
                <FormattedMessage id="users.profile" defaultMessage="Profile" />
              </Link>
            </Menu.Item>

            <Menu.Item key="3" onClick={onLogoutClick}>
              Выход
            </Menu.Item>
          </Menu>

          <Divider />
        </>
      ) : (
        <>
          <DocsMenu />
          <Divider />
        </>
      )}

      <PageFooter />
    </Drawer>
  );
};

const useStyles = makeStyles({
  root: {
    "& .ant-drawer-content-wrapper": {
      width: "100% !important",
    },
  },
  collapsedButton: {
    right: "-40px",
    position: "absolute",
    top: "5px",
    width: "41px",
    height: "40px",
    cursor: "pointer",
    zIndex: 0,
    textAlign: "center",
    lineHeight: "40px",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#000",
    boxShadow: "2px 0 8px #00000026",
    borderRadius: "0 4px 4px 0",
    "@media (min-width: 768px)": {
      display: "none",
    },
    "& svg": {
      height: 18,
      width: 18,
      color: "#fff",
    },
  },
  userName: {
    paddingLeft: "25px",
    fontWeight: 600,
    fontSize: "12pt",
  },
});
