import { Middleware } from "redux";
import { RootState } from "app/store";
import { intl } from "lib/locale";

// components
import { notification } from "antd";

// utils
import { history } from "utils/history";

// actions
import { logout } from "feat/auth/actions";

export const authMiddleware: Middleware<{}, RootState> =
  (store) => (next) => (action) => {
    const errorCode = action.payload?.code;
    const errorName = action?.payload?.name;

    if (errorCode === 401) {
      action.payload?.data.json().then((data: any) => {
        const detail = data?.detail;
        if (
          detail &&
          (detail.includes("password") || detail.includes("пароль"))
        ) {
          notification["error"]({
            message: intl.formatMessage({
              id: "error.error",
              defaultMessage: "Error",
            }),
            description: intl.formatMessage({
              id: "error.bad-login",
              defaultMessage: "Incorrect login or password",
            }),
          });
        }
      });
      // @ts-expect-error
      store.dispatch(logout());
      history.push("/login");
    }

    if (
      errorName === "ApiError" &&
      errorCode >= 400 &&
      errorCode !== 401 &&
      errorCode !== 500
    ) {
      action.payload?.data.json().then((data: any) => {
        notification["error"]({
          message: data?.detail,
        });
      });
    }

    if (errorName === "ApiError" && errorCode === 500) {
      notification["error"]({
        message: intl.formatMessage({
          id: "error.error",
          defaultMessage: "Error",
        }),
        description: intl.formatMessage({
          id: "error.refresh-page",
          defaultMessage: "Refresh the page",
        }),
      });
    }

    return next(action);
  };
