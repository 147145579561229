import { createAsyncThunk } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

// types
import { ThunkAPI, ILogAnswer, ILogFilter } from "types";

// получение логов
export const getLogs = createAsyncThunk<
  ILogAnswer,
  { isSuperAdmin: boolean; filter: ILogFilter },
  ThunkAPI
>(
  "history/getLogs",
  async (
    { isSuperAdmin, filter },
    { extra: { api }, rejectWithValue, getState }
  ) => {
    //если админ => не передаем группу и идет запрос на все асуно
    const group_id = isSuperAdmin
      ? null
      : getState().lightingLineGroups.currentGroup.group_id;

    const limit = getState().pagination;

    try {
      const data = await api.logs.getLogs(group_id, filter, limit);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получение типой действий для фильтра логов
export const getLogsActions = createAsyncThunk<
  Record<string, string>,
  boolean,
  ThunkAPI
>(
  "history/getLogsActions",
  async (isSuperAdmin, { extra: { api }, rejectWithValue, getState }) => {
    const group_id = getState().lightingLineGroups.currentGroupId;

    try {
      const data = await api.logs.getLogsActions(
        !isSuperAdmin ? group_id : null
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получение пользователей для фильтра логов
export const getLogsUsers = createAsyncThunk<
  Record<string, string>,
  boolean,
  ThunkAPI
>(
  "history/getLogsUsers",
  async (isSuperAdmin, { extra: { api }, rejectWithValue, getState }) => {
    const group_id = getState().lightingLineGroups.currentGroup?.group_id;

    try {
      const data = await api.logs.getLogsUsers(!isSuperAdmin ? group_id : null);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// получение логов действий в формате xlsx
export const getLogFile = createAsyncThunk<
  null,
  { isSuperAdmin: boolean; filter: ILogFilter },
  ThunkAPI
>(
  "history/getLogFile",
  async (
    { filter, isSuperAdmin },
    { extra: { api }, rejectWithValue, getState }
  ) => {
    const { group_name, group_id } = getState().lightingLineGroups.currentGroup;

    // если админ => не передаем группу и идет запрос на все асуно
    const groupId = isSuperAdmin ? null : group_id;

    const fileName = `${group_name} - ${intl.formatMessage({
      id: "app.logs",
    })}.xlsx`;

    try {
      const data = await api.logs.getLogFile(groupId, filter);

      var xlsxURL = window.URL.createObjectURL(data);
      let tempLink = document.createElement("a");
      tempLink.href = xlsxURL;
      tempLink.setAttribute("download", fileName);
      tempLink.click();

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
