import { createSlice } from "@reduxjs/toolkit";

//actions
import { getLogs, getLogsActions, getLogsUsers, getLogFile } from "../actions";

//types
import { IError, ILog, ILogActions, ILogUsers } from "types";

interface IInitialState {
  logs: ILog[];
  loading: boolean;
  loadingActions: boolean;
  loadingUsers: boolean;
  loadingFile: boolean;
  quantity: number;
  actions: ILogActions[];
  users: ILogUsers[];
  error: IError;
}

const initialState: IInitialState = {
  logs: [],

  loading: false,
  loadingActions: false,
  loadingUsers: false,
  loadingFile: false,

  quantity: 0,
  actions: [],
  users: [],
  error: null,
};

export const logsSlice = createSlice({
  name: "logs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //получение логов
    builder.addCase(getLogs.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLogs.fulfilled, (state, action) => {
      state.loading = false;
      state.logs = action.payload.logs;
      state.quantity = action.payload.quantity;
    });
    builder.addCase(getLogs.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //Получение типой действий для фильтра логов
    builder.addCase(getLogsActions.pending, (state) => {
      state.loadingActions = true;
      state.error = null;
    });
    builder.addCase(getLogsActions.fulfilled, (state, action) => {
      state.loadingActions = false;
      state.actions = Object.keys(action.payload)
        .map((item) => ({
          id: item,
          action: action.payload[item],
        }))
        .sort((a, b) => a.action.localeCompare(b.action));
    });
    builder.addCase(getLogsActions.rejected, (state) => {
      state.loadingActions = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //Получение пользователей для фильтра логов
    builder.addCase(getLogsUsers.pending, (state) => {
      state.loadingUsers = true;
      state.error = null;
    });
    builder.addCase(getLogsUsers.fulfilled, (state, action) => {
      state.loadingUsers = false;
      state.users = Object.keys(action.payload)
        .map((item) => ({
          id: item,
          name: action.payload[item],
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
    });
    builder.addCase(getLogsUsers.rejected, (state) => {
      state.loadingUsers = false;
      state.error = "Произошла ошибка при загрузке";
    });

    // получение логов действий в формате xlsx
    builder.addCase(getLogFile.pending, (state) => {
      state.loadingFile = true;
      state.error = null;
    });
    builder.addCase(getLogFile.fulfilled, (state, action) => {
      state.loadingFile = false;
    });
    builder.addCase(getLogFile.rejected, (state) => {
      state.loadingFile = false;
      state.error = "Произошла ошибка при загрузке";
    });
  },
});
