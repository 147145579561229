import { createSlice, current } from "@reduxjs/toolkit";

// actions
import { changeState } from "feat/substations/actions";
import { lightingLineGroupsSlice } from "feat/objects/reducers/lightingLineGroupsSlice";

// types
import { IError, IPhotoRelayState } from "types";

const { setCurrentGroup } = lightingLineGroupsSlice.actions;

interface IInitialState {
  devicesState: { [k: string]: { state: IPhotoRelayState; ts: number } };
  error: IError;
}

const initialState: IInitialState = {
  devicesState: {},
  error: null,
};

export const devicesStatesSlice = createSlice({
  name: "devicesStates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Получение списка фотореле
    builder.addCase(setCurrentGroup, (state, action) => {
      let group = action.payload;

      if (group?.flags?.use_photorelay && group.photorelays.length) {
        state.devicesState = Object.fromEntries(
          group.photorelays.map((item: any) => [
            item.id,
            { state: item.state, ts: item.ts },
          ])
        );
      }
    });

    // изменение состояния фотореле
    builder.addCase(changeState.fulfilled, (state, action) => {
      let objId = action.payload?.id;
      let newState = action.payload?.state;
      let ts = action.payload?.ts;

      if (action.payload && action.payload?.isPhotorelay) {
        state.devicesState = {
          ...current(state).devicesState,
          [objId]: { state: newState, ts },
        };
      }
    });
  },
});
