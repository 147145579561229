import { createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";

// types
import { ThunkAPI, IEmeterState } from "types";

export const getState = createAsyncThunk<IEmeterState, undefined, ThunkAPI>(
  "service/getState",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.service.getState();

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const testEmeter = createAsyncThunk<null, undefined, ThunkAPI>(
  "service/testEmeter",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      await api.service.testEmeter();

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const relayEnable = createAsyncThunk<null, undefined, ThunkAPI>(
  "service/relayEnable",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      await api.service.relayEnable();

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const relayDisable = createAsyncThunk<null, undefined, ThunkAPI>(
  "service/relayDisable",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      await api.service.relayDisable();

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const relayAuto = createAsyncThunk<null, undefined, ThunkAPI>(
  "service/relayAuto",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      await api.service.relayAuto();

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const relayManual = createAsyncThunk<null, undefined, ThunkAPI>(
  "service/relayManual",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      await api.service.relayManual();

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const flashSmartButton = createAsyncThunk<null, number, ThunkAPI>(
  "service/flashSmartButton",
  async (group, { extra: { api }, rejectWithValue }) => {
    try {
      const success = await api.service.flashSmartButton(group);

      if (success) {
        notification.success({
          message: "Операция завершена успешно",
        });
      }

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
