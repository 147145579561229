import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { StrictMode } from "react";
import { HelmetProvider } from "react-helmet-async";
import * as Sentry from "@sentry/react";

//components
import { App } from "app/App";
import { LocaleProvider } from "lib/locale";
import { WebsocketProvider } from "common/WebsocketProvider";

//utils
import { isProduction } from "utils/env";
import store from "app/store";
import reportWebVitals from "./reportWebVitals";
import { history } from "utils/history";
import "./lib/yup";

//actions
import { getCurrentUser } from "feat/users/actions";

if (isProduction) {
  Sentry.init({
    dsn: "https://30df23e7ace240548141fef6a534b905@o1176111.ingest.sentry.io/6273484",
    environment: process.env.NODE_ENV,
  });
}

const user = localStorage.getItem("user");

if (user) {
  store.dispatch(getCurrentUser());
}

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <LocaleProvider>
        <Router history={history}>
          <HelmetProvider>
            <WebsocketProvider>
              <App />
            </WebsocketProvider>
          </HelmetProvider>
        </Router>
      </LocaleProvider>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
