import { getCurrentLocale } from "lib/locale";

export const request = (fetchUrl: string, method: string, body?: any) => {
  const url = `/api${fetchUrl}`;

  const locale = getCurrentLocale();

  const options = {
    method,
    headers: {
      "Content-Type": "application/json",
      "Accept-Language": locale,
      accept: "application/json",
    },
    body,
  };

  return fetch(url, options);
};
