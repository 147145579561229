import { objects } from "feat/objects/api";
import { indications } from "feat/indications/api";
import { burningHours } from "feat/burningHours/api";
import { schedule } from "feat/schedule/api";
import { chart } from "feat/chart/api";
import { reporting } from "feat/reporting/api";
import { auth } from "feat/auth/api";
import { users } from "feat/users/api";
import { devices } from "feat/devices/api";
import { substations } from "feat/substations/api";
import { logs } from "feat/logs/api";
import { asuno } from "feat/asuno/api";
import { directories } from "feat/admin/api";
import { control } from "feat/control/api";
import { lamps } from "feat/lamps/api";
import { service } from "feat/service/api";

const api = {
  asuno,
  auth,
  burningHours,
  chart,
  control,
  devices,
  directories,
  indications,
  lamps,
  logs,
  objects,
  reporting,
  schedule,
  service,
  substations,
  users,
};

export default api;
export type IApi = typeof api;
