import { createSlice, PayloadAction } from "@reduxjs/toolkit";

//utils
import { createIntlCtx, getCurrentLocale } from "lib/locale";
import { getPagination } from "utils/getPagination";

//types
import { IChangeStateWS } from "types";
import { ILocale } from "lib/enums";

const getCurrentGroupId = () => {
  const id = localStorage.getItem("id");
  if (id) {
    return !!JSON.parse(id);
  } else {
    return false;
  }
};

export const localeSlice = createSlice({
  name: "locale",
  initialState: getCurrentLocale(),
  reducers: {
    setLocale: {
      reducer: (_, action: PayloadAction<ILocale>) => {
        return action.payload;
      },
      prepare: (locale) => {
        createIntlCtx(locale);
        localStorage.setItem("locale", locale);

        return { payload: locale };
      },
    },
  },
});

export const paginationSlice = createSlice({
  name: "pagination",
  initialState: getPagination(),
  reducers: {
    setPagination: {
      reducer: (_, action: PayloadAction<number>) => {
        return action.payload;
      },
      prepare: (pagination) => {
        localStorage.setItem("pageSize", pagination);
        return { payload: pagination };
      },
    },
  },
});

export const mobileMenuSlice = createSlice({
  name: "mobileMenu",
  initialState: false,
  reducers: {
    setMobileMenuOpen: (state, action: PayloadAction<boolean>) =>
      (state = action.payload),
  },
});

const initialState: IChangeStateWS[] = [];

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<IChangeStateWS>) => {
      state.push(action.payload);
    },
    cleanMessages: () => {
      return [];
    },
  },
});

export const asunoCheckedSlice = createSlice({
  name: "asunoChecked",
  initialState: getCurrentGroupId(),
  reducers: {
    setAsunoChecked: (state, action: PayloadAction<boolean>) =>
      (state = action.payload),
  },
});

const { setLocale } = localeSlice.actions;
export { setLocale };

const { setPagination } = paginationSlice.actions;
export { setPagination };

const { setMobileMenuOpen } = mobileMenuSlice.actions;
export { setMobileMenuOpen };

const { setMessages, cleanMessages } = messagesSlice.actions;
export { setMessages, cleanMessages };

const { setAsunoChecked } = asunoCheckedSlice.actions;
export { setAsunoChecked };
