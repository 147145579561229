import { createSlice } from "@reduxjs/toolkit";

//actions
import { getLightningLineReadout, getCrartZoomData } from "../actions";

//types
import { IError, Maybe, IChartData } from "types";

interface IInitialState {
  data: Maybe<IChartData>;
  initPeriod: { from?: string; to?: string };
  initLine: string;
  zoomed: boolean;
  loading: boolean;
  loadingZoom: boolean;
  error: IError;
}

const initialState: IInitialState = {
  data: null,
  initPeriod: {},
  initLine: "",
  zoomed: false,
  loading: false,
  loadingZoom: false,
  error: null,
};

export const lightingLineReadoutSlice = createSlice({
  name: "lightingLineReadout",
  initialState,
  reducers: {
    setZoomed: (state, action) => {
      state.zoomed = action.payload;
    },
    cleanState: (state) => {
      state.zoomed = false;
      state.initLine = "";
      state.initPeriod = {};
      state.data = null;
    },
  },
  extraReducers: (builder) => {
    //Получение показаний по линиям освещения
    builder.addCase(getLightningLineReadout.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLightningLineReadout.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload.data[0];
      state.initPeriod = action.payload.period;
      state.initLine = action.payload.line;
      state.zoomed = false;
    });
    builder.addCase(getLightningLineReadout.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //Получение показаний по линиям освещения для зума графика
    builder.addCase(getCrartZoomData.pending, (state) => {
      state.loadingZoom = true;
      state.error = null;
    });
    builder.addCase(getCrartZoomData.fulfilled, (state, action) => {
      state.loadingZoom = false;
      state.data = action.payload[0];
    });
    builder.addCase(getCrartZoomData.rejected, (state) => {
      state.loadingZoom = false;
      state.error = "Произошла ошибка при загрузке";
    });
  },
});
