import { makeStyles } from "@material-ui/styles";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";

// components
import { Layout } from "antd";
import { LangSelect } from "./LangSelect";

const { Footer } = Layout;

interface IProps {
  mobileFooter?: boolean;
}

export const PageFooter: React.FC<IProps> = ({ mobileFooter }) => {
  const classes = useStyles();

  return (
    <Footer
      className={classNames(classes.footer, {
        [classes.mobileFooter]: mobileFooter,
      })}
    >
      <FormattedMessage
        id="app.support"
        defaultMessage="Technical&nbsp;support:"
      />
      <a href="tel:+79004572872" className={classes.text}>
        {" "}
        +7 900 457-28-72
      </a>
      ,<span>{"\n"}</span>
      <a href="mailto:service@i-sberg.net" className={classes.text}>
        {" "}
        service@i-sberg.net
      </a>
      .{" "}
      <span className={classes.timeText}>
        <FormattedMessage
          id="app.support-msg"
          defaultMessage="We&nbsp;work &nbsp;on&nbsp;weekdays&nbsp;from&nbsp;9&nbsp;to&nbsp;18
          Moscow&nbsp;time."
        />
      </span>{" "}
      ©USPherum&nbsp;v3.7, build {process.env.REACT_APP_VERSION}
      <LangSelect />
    </Footer>
  );
};

const useStyles = makeStyles({
  footer: {
    textAlign: "center",
    paddingLeft: 24,
    "@media (max-width: 768px)": {
      padding: 8,
      backgroundColor: "#fff",
      width: "100%",
    },
  },
  text: {
    whiteSpace: "nowrap",
  },
  timeText: {
    "@media (min-width: 612px)": {
      whiteSpace: "nowrap",
    },
  },
  enter: {
    "@media (min-width: 768px)": {
      display: "none",
    },
  },
  mobileFooter: {
    backgroundColor: "#f0f2f5",
  },
});
