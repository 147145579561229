import { useAppSelector } from "app/hooks";

export const useRole = (scope: string) => {
  const currentUser = useAppSelector((state) => state.currentUser.user);
  const userScopes = useAppSelector((state) => state.currentUser.scopes);
  const current = useAppSelector((state) => state.lightingLineGroups.current);
  const scopes = userScopes[current];

  const isGlobalAdmin = currentUser?.global_admin;

  if (isGlobalAdmin) {
    // суперадмину все и везде можно
    return true;
  } else if (scopes) {
    return scopes[scope];
  }
};

interface IProps {
  has?: string;
}

export const Role: React.FC<IProps> = ({ has = "", children }) => {
  const isAccessAllowed = useRole(has);

  if (isAccessAllowed) {
    return <>{children}</>;
  }

  return null;
};
