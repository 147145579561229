import { createSlice } from "@reduxjs/toolkit";

// actions
import { getDeviceInfo } from "feat/devices/actions";

// types
import { IError, IDeviceInfo } from "types";

interface IInitialState {
  deviceInfo: IDeviceInfo[];
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  deviceInfo: [],
  loading: false,
  error: null,
};

export const deviceInfoSlice = createSlice({
  name: "deviceInfo",
  initialState,
  reducers: {
    clearInfo: (state) => {
      state.deviceInfo = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDeviceInfo.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getDeviceInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.deviceInfo = action.payload;
    });
    builder.addCase(getDeviceInfo.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });
  },
});
