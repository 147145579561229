import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";
import { ILineRequest } from "types";

// Получение показаний по линиям освещения
const getLightningLineReadout = async (body: ILineRequest) => {
  const resp = await request(
    `/v1/lighting-line-readout/get`,
    "POST",
    JSON.stringify(body)
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Формирование и создание отчёта для графиков в формате .xlsx
const getLightningLineReadoutReport = async (
  group_id: number,
  body: ILineRequest
) => {
  const reqBody = JSON.stringify(body);
  const params = `group_id=${group_id}`;
  const resp = await request(
    `/v1/get-report-lighting-lines-readouts?${params}`,
    "POST",
    reqBody
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.blob();
  return data;
};

export const chart = {
  getLightningLineReadout,
  getLightningLineReadoutReport,
};
