import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

// types
import { ThunkAPI, ILamp, ILampsGroup } from "types";

// настраиваем состав группы
export const changeGroupLamps = createAction<{
  groupId: string;
  lamps: string[];
}>("lightningControl/changeGroupLamps");

// Получение списка светильников
export const getLamps = createAsyncThunk<
  Record<string, ILamp>,
  string,
  ThunkAPI
>(
  "lightningControl/getLamps",
  async (lineId, { extra: { api }, rejectWithValue, getState }) => {
    try {
      const data = await api.control.getLamps(lineId);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получение списка групп
export const getGroups = createAsyncThunk<
  Record<string, ILampsGroup>,
  string,
  ThunkAPI
>(
  "lightningControl/getGroups",
  async (lineId, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.control.getGroups(lineId);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
