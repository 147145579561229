import { createSlice } from "@reduxjs/toolkit";

//actions
import {
  getLightningLineConsForecast,
  getLightningLineConsumptionSlices,
} from "../actions";

//types
import {
  IError,
  ILightningLineConsForecast,
  ILineConsumptionSlice,
  Maybe,
} from "types";

interface IInitialState {
  consForecastSlice: Maybe<ILightningLineConsForecast>;
  consFactSlice: { [k: string]: ILineConsumptionSlice } | {};
  eapSums: number[];
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  consForecastSlice: null,
  consFactSlice: {},
  eapSums: [],
  loading: false,
  error: null,
};

export const lightingLineConsumptionSlice = createSlice({
  name: "consumption",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //Получение показаний по линиям освещения
    builder.addCase(getLightningLineConsForecast.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getLightningLineConsForecast.fulfilled, (state, action) => {
      state.loading = false;
      state.consForecastSlice = action.payload;
    });
    builder.addCase(getLightningLineConsForecast.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //Получение показаний по линиям освещения
    builder.addCase(getLightningLineConsumptionSlices.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      getLightningLineConsumptionSlices.fulfilled,
      (state, action) => {
        state.loading = false;
        state.eapSums = action.payload.sum_eap;
        state.consFactSlice = Object.fromEntries(
          action.payload.slices.map((elem) => [elem.line_id, elem])
        );
      }
    );
    builder.addCase(getLightningLineConsumptionSlices.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });
  },
});
