import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";

// Получение среза показаний по энергопотреблению за выбранный период
// Потребление - факт
const getLightningLineConsumptionSlices = async (body: string) => {
  const resp = await request(`/v1/get-consumption-slices`, "POST", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Формирование и создание отчётности в формате .xlsx
// Потребление - факт
const createReportConsumptionSlices = async (reqBody: string) => {
  const resp = await request(
    `/v1/get-consumption-slices/xlsx`,
    "POST",
    reqBody
  );

  if (!resp.ok) {
    handleErrors(resp);
  }

  const data = await resp.blob();
  return data;
};

// Получение прогноза потребления по линиям освещения
// Потребление - прогноз
const getLightningLineConsForecast = async (body: string) => {
  const resp = await request(`/v1/get-lighting-line-forecast`, "POST", body);

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Получение прогноза потребления по линиям освещения в формате .xlsx
// Потребление - прогноз
const getLightningLineConsForecastXlsx = async (body: string) => {
  const resp = await request(
    `/v1/get-lighting-line-forecast/xlsx`,
    "POST",
    body
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.blob();
  return data;
};

// Получение среза показаний по энергопотреблению за выбранный период
// Потребление - факт (по-умолчанию)
const getLightningLineConsumptionSlicesDefault = async (body: string) => {
  const resp = await request(
    `/v1/get-consumption-slices-default`,
    "POST",
    body
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Формирование и создание отчётности в формате .xlsx
// Потребление - факт (по-умолчанию)
const createReportConsumptionSlicesDefault = async (reqBody: string) => {
  const resp = await request(
    `/v1/get-consumption-slices-default/xlsx`,
    "POST",
    reqBody
  );

  if (!resp.ok) {
    handleErrors(resp);
  }

  const data = await resp.blob();
  return data;
};

export const reporting = {
  getLightningLineConsumptionSlices,
  createReportConsumptionSlices,
  getLightningLineConsForecast,
  getLightningLineConsForecastXlsx,
  getLightningLineConsumptionSlicesDefault,
  createReportConsumptionSlicesDefault,
};
