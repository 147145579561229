import {
  createIntl,
  createIntlCache,
  RawIntlProvider,
  IntlShape,
} from "react-intl";
import { useAppSelector } from "app/hooks";
import { ConfigProvider } from "antd";
import { setLocale } from "yup";
import moment from "moment";
import { ILocale } from "lib/enums";

// locales
import locale_ru from "lang/ru.json";
import locale_en from "lang/en.json";
import yupRu from "lang/yupru.json";
import yupEn from "lang/yupen.json";

// antd locale
import enUs from "antd/lib/locale/en_US";
import ruRu from "antd/lib/locale/ru_RU";

//moment
import "moment/locale/ru";

export const availableLocales: Record<string, string> = {
  ru: "Русский",
  en: "English",
};

const yupMessages: Record<string, any> = {
  en: yupEn,
  ru: yupRu,
};

const messages: Record<string, any> = {
  ru: locale_ru,
  en: locale_en,
};

const antdLocale = {
  ru: ruRu,
  en: enUs,
};

const flattenMessages = (nestedMessages: any, prefix = "") => {
  if (nestedMessages === null) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "string") {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

export const getCurrentLocale = () => {
  const locale = localStorage.getItem("locale");
  const browserLocale = navigator.language.split(/[-_]/)[0];

  if (!locale) {
    const newLocale = availableLocales[browserLocale]
      ? browserLocale
      : ILocale.RU;

    localStorage.setItem("locale", newLocale);

    return newLocale as ILocale;
  }

  return locale as ILocale;
};

const cache = createIntlCache();
const initiaLocale = getCurrentLocale();
export let intl: IntlShape;

export const createIntlCtx = (locale = initiaLocale) => {
  intl = createIntl(
    {
      locale,
      messages: flattenMessages(messages[locale]),
    },
    cache
  );

  setLocale(yupMessages[locale]);

  document.documentElement.lang = locale;
};

createIntlCtx();

export const LocaleProvider: React.FC = ({ children }) => {
  const locale = useAppSelector((state) => state.locale);

  moment.locale(locale);

  return (
    <ConfigProvider locale={antdLocale[locale]}>
      <RawIntlProvider key={locale} value={intl}>
        {children}
      </RawIntlProvider>
    </ConfigProvider>
  );
};
