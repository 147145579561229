import { createAsyncThunk } from "@reduxjs/toolkit";
import { setMessages } from "common/reducers";
import moment from "moment";
import { sid } from "utils/sid";

// utils
import { parseWsMsg } from "utils/parseWsMsg";

// types
import {
  ThunkAPI,
  IChangeStateWS,
  ILightingLineGroup,
  ILineArg,
  ILineState,
} from "types";

const checkLampGroupsRelay = (prevState: ILineState, msg: IChangeStateWS) => {
  if (
    !msg.changedStates?.states ||
    !msg.changedStates?.states?.[0]?.group_num
  ) {
    return [];
  }

  const newStates = msg.changedStates.states;
  const relay = prevState.lamp_groups?.relay;

  if (!relay) {
    return [];
  }

  const changedGroups = newStates.filter(({ group_num, light_on }) =>
    group_num ? relay[group_num].val !== light_on : false
  );

  return changedGroups;
};

// Уведомление о изменении состоянии линии освещения и светильников
export const changeState = createAsyncThunk<any, IChangeStateWS, ThunkAPI>(
  "lightingLine/changeState",
  (msg, { getState, dispatch }) => {
    const linesStates = getState().lightingLine.linesState;
    const currentGroup: ILightingLineGroup =
      getState().lightingLineGroups.currentGroup;

    const prevState = linesStates[msg.objId];
    const photorelayPrevState = currentGroup?.photorelays?.find(
      (i) => i.id === msg.objId
    );

    const prevLampGroupColor =
      prevState.lamp_groups?.color[msg.changedStates?.group_num ?? ""];
    const prevLampGroupMode =
      prevState.lamp_groups?.mode[msg.changedStates?.group_num ?? ""];

    const isColorChanged =
      msg.changedStates?.color &&
      (msg.changedStates.color.r !== prevLampGroupColor?.r ||
        msg.changedStates.color.g !== prevLampGroupColor?.g ||
        msg.changedStates.color.b !== prevLampGroupColor?.b ||
        msg.changedStates.color.w !== prevLampGroupColor?.w);
    const isModeChanged =
      msg.changedStates?.group_num &&
      msg.changedStates?.ctl_mode &&
      (msg.changedStates.ctl_mode !== prevLampGroupMode?.mode ||
        prevLampGroupMode.schedule_id !== msg.changedStates?.schedule_id);

    const showNotification = (data: IChangeStateWS) => {
      if (msg.notifyImmediately) {
        // мгновенный вывод оповещений об успехе управления ШУНО
        return parseWsMsg([data], currentGroup);
      }
      // ставим сообщения в очередь
      return dispatch(setMessages(data));
    };

    // состояние линии освещения
    // в массиве states должен быть один элемент и не должно быть group_num
    if (
      msg.changedStates?.states?.length === 1 &&
      !msg.changedStates?.states[0].group_num &&
      msg.changedStates?.states[0].light_on !== prevState?.relay.val
    ) {
      showNotification(msg);

      return {
        ...prevState,
        relay: { val: msg.changedStates.states[0].light_on, ts: msg.ts },
      };
    }

    // режим линии освещения
    if (
      msg.changedStates?.ctl_mode !== prevState?.ctl_mode.val &&
      typeof msg.changedStates?.ctl_mode === "string" &&
      !msg.changedStates.group_num
    ) {
      showNotification(msg);

      return {
        ...prevState,
        ctl_mode: { val: msg.changedStates?.ctl_mode, ts: msg.ts },
      };
    }

    // Фотореле
    if (
      currentGroup.flags.use_photorelay &&
      msg.changedStates?.photorelayState !== photorelayPrevState?.state &&
      typeof msg.changedStates?.photorelayState === "string"
    ) {
      showNotification(msg);

      return {
        isPhotorelay: true,
        id: msg.objId,
        state: msg.changedStates.photorelayState,
        ts: msg.changedStates.lastActivityTs,
      };
    }

    // Поменяли цвет группы светильников в дэшборде
    if (msg.changedStates?.color && isColorChanged) {
      showNotification(msg);

      return {
        color: msg.changedStates.color,
        group_num: msg.changedStates.group_num,
        line_id: msg.objId,
        ts: msg.changedStates.lastActivityTs,
      };
    }

    // управляем реле группы светильников
    if (msg.changedStates?.states && msg.changedStates?.states[0].group_num) {
      const states = msg.changedStates.states;

      const changedStates = checkLampGroupsRelay(prevState, msg);

      changedStates.map((state) =>
        showNotification({
          ...msg,
          changedStates: {
            ...msg.changedStates,
            states: [state],
          },
        })
      );

      return {
        states,
        line_id: msg.objId,
        ts: msg.changedStates.lastActivityTs,
      };
    }

    // режим группы светильников
    if (
      msg.changedStates?.ctl_mode &&
      typeof msg.changedStates?.ctl_mode === "string" &&
      msg.changedStates.group_num &&
      isModeChanged
    ) {
      showNotification(msg);

      return {
        line_id: msg.objId,
        group_num: msg.changedStates.group_num,
        ctl_mode: msg.changedStates?.ctl_mode,
        mode: {
          mode: msg.changedStates?.ctl_mode,
          ts: msg.ts,
          schedule_id: msg.changedStates?.schedule_id,
          schedule_name: msg.changedStates?.schedule_name,
        },
        scheduleCounters: msg.changedStates?.schedule_counters,
        ts: msg.changedStates.lastActivityTs,
      };
    }

    return {
      ...prevState,
      lst_activ_ts: { ts: msg.ts },
    };
  }
);

// Отправка команды на управление объектом (ЛО или светильником)
export const setLinesOnOffMode = createAsyncThunk<null, ILineArg, ThunkAPI>(
  "lightingLine/setLinesOnOffMode",
  async (args, { extra: { api }, rejectWithValue }) => {
    const data = {
      ver: 1,
      ts: parseInt(moment().format("x")) * 1000,
      sid: sid(),
      ...args,
    };

    try {
      if (data?.cmd) {
        await api.substations.setLinesOnOff(data);
      } else {
        // Задание режима управления линиям освещения и светильникам
        await api.substations.setLinesMode(data);
      }

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Отправка команды на управление объектом (ЛО или светильником)
export const updateLampGroups = createAsyncThunk<
  null,
  Record<string, Record<"name", string>>,
  ThunkAPI
>(
  "lightingLine/updateLampGroups",
  async (data, { extra: { api }, rejectWithValue }) => {
    try {
      await api.substations.updateLampGroups(JSON.stringify(data));

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
