import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";

// types
import { IEditLightingLineGroup, INotifyParams } from "types";

// Создание группы освещения
const сreateLightingLineGroup = async (body: string) => {
  const resp = await request(`/v1/lighting-line-group`, "POST", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Редактирование группы освещения
const editLightingLineGroup = async (body: IEditLightingLineGroup) => {
  const req = JSON.stringify(body);
  const resp = await request(
    `/v1/lighting-line-group/${body.group_id}`,
    "PUT",
    req
  );
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Получение параметров уведомления линии освещения
const getLightingLineNotifyParams = async (line_id: string) => {
  const resp = await request(
    `/v1/lighting-line-notify-params/${line_id}`,
    "GET"
  );
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// редактирование параметров уведомления линии освещения
const editLightingLineNotifyParams = async (
  line_id: string,
  body: INotifyParams
) => {
  const req = JSON.stringify(body);
  const resp = await request(
    `/v1/lighting-line-notify-params/${line_id}`,
    "PUT",
    req
  );
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// порядок объектов в отчете
const reorderLightningLines = async (body: { [k: string]: number }) => {
  const req = JSON.stringify(body);
  const resp = await request("/v1/reorder-lighting-lines", "POST", req);
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Обновить информацию о тарифе ШУНО
const updLightningLineTariff = async (body: string) => {
  const resp = await request("/v1/lighting-line-tariff", "PUT", body);
  if (!resp.ok) {
    handleErrors(resp);
  }
  return null;
};

export const groups = {
  сreateLightingLineGroup,
  editLightingLineGroup,
  getLightingLineNotifyParams,
  editLightingLineNotifyParams,
  reorderLightningLines,
  updLightningLineTariff,
};
