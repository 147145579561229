import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { intl } from "lib/locale";
import moment from "moment";

// components
import { notification } from "antd";

// utils
import { getPeriod } from "./utils/getPeriod";

// types
import {
  ThunkAPI,
  IGroupSchedule,
  IScheduleRecord,
  ISchedule,
  ICreateScheduleArgs,
  ISunSchedule,
  RgbwColor,
} from "types";

// Открываем модалку дня на странице расписания
export const setEditDay = createAction<number>("schedule/setEditDay");

export const setCurrentSchedule = createAction<ISchedule | null>(
  "schedule/setCurrentSchedule"
);

// Получение списка расписаний по линии освещения
export const getLightingLineSchedule = createAsyncThunk<
  IGroupSchedule[],
  { scheduleId?: string },
  ThunkAPI
>(
  "schedule/getLightingLineSchedule",
  async (
    { scheduleId },
    { extra: { api }, rejectWithValue, getState, dispatch }
  ) => {
    const group_id = getState().lightingLineGroups.current;
    const currentGroup = getState().lightingLineGroups.currentGroup;
    const currentMonth = getState().lightingLineSchedule.currentMonth;
    const period = getPeriod(currentMonth);
    const useNewScheduleSystem = currentGroup?.flags?.use_new_schedules_system;

    try {
      if (useNewScheduleSystem && scheduleId) {
        const data = await api.schedule.getScheduleWithRecords({
          scheduleId,
          from: period.min,
          to: period.max,
        });

        dispatch(setCurrentSchedule(data));

        return data?.records ?? [];
      }

      const data = await api.schedule.getLightingLineSchedule({
        group_id,
        from: parseInt(moment(period.min).format("x")) * 1000,
        to: parseInt(moment(period.max).format("x")) * 1000,
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Создание нового расписания
// Форма регистрации нового расписания
export const createLightingLineSchedule = createAsyncThunk<
  IScheduleRecord & { sched_id: number },
  IScheduleRecord,
  ThunkAPI
>(
  "schedule/createLightingLineSchedule",
  async (body, { extra: { api }, rejectWithValue, getState }) => {
    const currentGroup = getState().lightingLineGroups.currentGroup;
    const group_id = getState().lightingLineGroups.current;
    const scheduleId = getState().schedules.currentSchedule?.id;

    const useNewScheduleSystem = currentGroup?.flags?.use_new_schedules_system;

    try {
      if (useNewScheduleSystem && scheduleId) {
        const modifier = JSON.stringify({ ...body });
        const data = await api.schedule.addScheduleRecord(scheduleId, modifier);
        return { ...body, sched_id: data.sched_id };
      }

      const toSend = JSON.stringify({ ...body, group_id });
      const data = await api.schedule.createLightingLineSchedule(toSend);

      setTimeout(() => {
        notification["success"]({
          message: `${intl.formatMessage({
            id: "schedule.create-sch-msg",
            defaultMessage: "Schedule created",
          })} №${data.sched_id}`,
        });
      }, 1800);

      return { ...body, sched_id: data.sched_id };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Удаление расписания
export const deleteLightingLineSchedule = createAsyncThunk<
  number,
  number,
  ThunkAPI
>(
  "schedule/deleteLightingLineSchedule",
  async (recordId, { extra: { api }, rejectWithValue, getState }) => {
    const currentGroup = getState().lightingLineGroups.currentGroup;
    const scheduleId = getState().schedules.currentSchedule?.id;

    const useNewScheduleSystem = currentGroup?.flags?.use_new_schedules_system;

    try {
      if (useNewScheduleSystem && scheduleId) {
        await api.schedule.deleteScheduleRecord(scheduleId, recordId);
      } else {
        await api.schedule.deleteLightingLineSchedule(recordId);
      }

      notification["success"]({
        message: `${intl.formatMessage({
          id: "schedule.schedule",
          defaultMessage: "Schedule",
        })} №${recordId} ${intl.formatMessage({
          id: "schedule.has-deleted",
          defaultMessage: "has deleted",
        })}`,
      });

      return recordId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Обновление расписания
export const editLightingLineSchedule = createAsyncThunk<
  number,
  {
    recordId: number;
    body: IScheduleRecord;
  },
  ThunkAPI
>(
  "schedule/editLightingLineSchedule",
  async ({ recordId, body }, { extra: { api }, rejectWithValue, getState }) => {
    const group_id = getState().lightingLineGroups.current;
    const currentGroup = getState().lightingLineGroups.currentGroup;
    const scheduleId = getState().schedules.currentSchedule?.id;

    const useNewScheduleSystem = currentGroup?.flags?.use_new_schedules_system;
    const reqBody = JSON.stringify({ ...body, group_id });

    try {
      if (useNewScheduleSystem && scheduleId) {
        const modifier = JSON.stringify({ ...body });

        await api.schedule.updateScheduleRecord(scheduleId, recordId, modifier);

        return recordId;
      }

      await api.schedule.editLightingLineSchedule(recordId, reqBody);

      notification["success"]({
        message: `${intl.formatMessage({
          id: "schedule.schedule",
          defaultMessage: "Schedule",
        })} №${recordId} ${intl.formatMessage({
          id: "schedule.has-updated",
          defaultMessage: "has updated",
        })}`,
      });

      return recordId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Создание списка расписаний
// Форма регистрации расписаний
export const createLightingLineScheduleList = createAsyncThunk<
  null,
  IScheduleRecord[],
  ThunkAPI
>(
  "schedule/createLightingLineScheduleList",
  async (records, { extra: { api }, rejectWithValue, getState }) => {
    const currentGroup = getState().lightingLineGroups.currentGroup;
    const scheduleId = getState().schedules.currentSchedule?.id;

    const useNewScheduleSystem = currentGroup?.flags?.use_new_schedules_system;
    const body = JSON.stringify(records);

    try {
      if (useNewScheduleSystem && scheduleId) {
        await api.schedule.addScheduleRecords(scheduleId, body);
      } else {
        await api.schedule.createLightingLineScheduleList(body);
      }

      setTimeout(() => {
        notification["success"]({
          message: intl.formatMessage({
            id: "schedule.create-sch-list-msg",
            defaultMessage: "Created schedule list",
          }),
        });
      }, 1800);

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Создание астрорасписания
// Форма добавления астрорасписания расписания
export const createLightingLineSunSchedule = createAsyncThunk<
  null,
  {
    ts_st: any;
    ts_end: any;
    start_shift: number;
    end_shift: number;
    color?: RgbwColor;
  },
  ThunkAPI
>(
  "schedule/createLightingLineSunSchedule",
  async (
    { ts_st, ts_end, start_shift, end_shift, color },
    { extra: { api }, rejectWithValue, getState }
  ) => {
    const group_id = getState().lightingLineGroups.current;
    const currentGroup = getState().lightingLineGroups.currentGroup;
    const scheduleId = getState().schedules.currentSchedule?.id; // только в новой системе расписаний
    const useNewSchedule = currentGroup?.flags?.use_new_schedules_system;

    try {
      if (useNewSchedule && scheduleId) {
        // только в новой системе расписаний
        await api.schedule.addScheduleRecordsUsingSunriseAndSunset(
          JSON.stringify({
            sun_params: {
              group_id,
              ts_st,
              ts_end,
              start_shift,
              end_shift,
            },
            color,
          }),
          scheduleId
        );
      } else {
        await api.schedule.createLightingLineSunSchedule(
          JSON.stringify({ group_id, ts_st, ts_end, start_shift, end_shift })
        );
      }

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Удаление списка расписаний
export const deleteLightingLineScheduleList = createAsyncThunk<
  null,
  { to: string; from: string },
  ThunkAPI
>(
  "schedule/deleteLightingLineScheduleList",
  async ({ to, from }, { extra: { api }, rejectWithValue, getState }) => {
    const groupId = getState().lightingLineGroups.current;
    const currentGroup = getState().lightingLineGroups.currentGroup;
    const scheduleId = getState().schedules.currentSchedule?.id;

    const useNewScheduleSystem = currentGroup?.flags?.use_new_schedules_system;

    try {
      if (useNewScheduleSystem && scheduleId) {
        await api.schedule.deleteScheduleRecordsInTimeInterval(
          from,
          to,
          scheduleId
        );
      } else {
        await api.schedule.deleteLightingLineScheduleList(to, from, groupId);
      }

      notification["success"]({
        message: intl.formatMessage({
          id: "schedule.delete-sch-list-msg",
          defaultMessage: "Schedule list deleted",
        }),
      });

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Проверка файла с расписанием на наличие ошибок
export const checkScheduleFile = createAsyncThunk<
  unknown,
  { formdata: FormData; useNewSchedule: boolean; scheduleId?: string },
  ThunkAPI
>(
  "schedule/checkScheduleFile",
  async (
    { formdata, useNewSchedule, scheduleId },
    { extra: { api }, rejectWithValue, dispatch }
  ) => {
    try {
      await api.schedule.checkScheduleFile(formdata, useNewSchedule);

      await dispatch(
        uploadScheduleFile({ formdata, useNewSchedule, scheduleId })
      );

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Запись данных расписания с файла в бд
export const uploadScheduleFile = createAsyncThunk<
  unknown,
  { formdata: FormData; useNewSchedule: boolean; scheduleId?: string },
  ThunkAPI
>(
  "schedule/checkScheduleFile",
  async (
    { formdata, useNewSchedule, scheduleId },
    { extra: { api }, rejectWithValue, getState }
  ) => {
    const group_id = getState().lightingLineGroups.current;

    try {
      const data = await api.schedule.uploadScheduleFile({
        formdata,
        group_id,
        useNewSchedule,
        scheduleId,
      });

      notification["success"]({
        message: intl.formatMessage({
          id: "schedule.upload-file-msg",
          defaultMessage: "Schedule file uploaded successfully",
        }),
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Формирование расписания в xlsx файл
export const createScheduleToFile = createAsyncThunk<
  null,
  { from: string; to?: string },
  ThunkAPI
>(
  "schedule/createScheduleToFile",
  async ({ from, to }, { extra: { api }, rejectWithValue, getState }) => {
    const { group_name } = getState().lightingLineGroups.currentGroup;
    const group_id = getState().lightingLineGroups.current;
    const currentGroup = getState().lightingLineGroups.currentGroup;
    const scheduleId = getState().schedules.currentSchedule?.id;

    const useNewScheduleSystem = currentGroup?.flags?.use_new_schedules_system;

    const name = `${group_name} - ${intl.formatMessage({
      id: "schedule.schedule",
      defaultMessage: "Schedule",
    })}.xlsx`;

    try {
      if (useNewScheduleSystem && scheduleId) {
        const data = await api.schedule.downloadScheduleFile(
          scheduleId,
          from,
          to
        );

        let xlsxURL = window.URL.createObjectURL(data);
        let tempLink = document.createElement("a");
        tempLink.href = xlsxURL;
        tempLink.setAttribute("download", name);
        tempLink.click();

        return null;
      }

      const data = await api.schedule.createScheduleToFile({
        group_id,
        from: Number(moment(from).format("x")) * 1000,
        to: to ? Number(moment(to).format("x")) * 1000 : undefined,
      });

      let xlsxURL = window.URL.createObjectURL(data);
      let tempLink = document.createElement("a");
      tempLink.href = xlsxURL;
      tempLink.setAttribute("download", name);
      tempLink.click();

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Создание астрорасписаний для заданного периода времени (не пишет его в БД)
export const getLightingLineSunSchedule = createAsyncThunk<
  ISunSchedule[],
  undefined,
  ThunkAPI
>(
  "schedule/getLightingLineSunSchedule",
  async (_, { extra: { api }, rejectWithValue, getState }) => {
    const group_id = getState().lightingLineGroups.current;
    const currentMonth = getState().lightingLineSchedule.currentMonth;
    const period = getPeriod(currentMonth);

    try {
      const data = await api.schedule.getLightingLineSunSchedule({
        group_id,
        from: period.min,
        to: period.max,
      });

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const loadSchedule = createAsyncThunk<
  string,
  {
    lineId: string;
    lineName: string;
  },
  ThunkAPI
>(
  "schedule/loadSchedule",
  async ({ lineId, lineName }, { extra: { api }, rejectWithValue }) => {
    try {
      await api.schedule.loadSchedule(lineId);

      notification["success"]({
        message: lineName,
        description: "Загрузка прошла успешно",
      });

      return lineId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const checkSchedule = createAsyncThunk<
  string,
  {
    lineId: string;
    lineName: string;
  },
  ThunkAPI
>(
  "schedule/checkSchedule",
  async ({ lineId, lineName }, { extra: { api }, rejectWithValue }) => {
    try {
      await api.schedule.checkSchedule(lineId);

      notification["success"]({
        message: lineName,
        description: "Проверка прошла успешно",
      });

      return lineId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получение списка расписаний по АСУНО
export const getAsunoSchedules = createAsyncThunk<
  ISchedule[],
  undefined,
  ThunkAPI
>(
  "schedule/getAsunoSchedules",
  async (_, { extra: { api }, rejectWithValue, getState }) => {
    const group_id = getState().lightingLineGroups.current;

    try {
      const data = await api.schedule.getAsunoSchedules(group_id);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// создание нового расписания АСУНО
export const createAsunoSchedules = createAsyncThunk<
  ISchedule[],
  ICreateScheduleArgs,
  ThunkAPI
>(
  "schedule/createAsunoSchedule",
  async (schedule, { extra: { api }, rejectWithValue, getState }) => {
    const group_id = getState().lightingLineGroups.current;

    const body = JSON.stringify(schedule);

    try {
      await api.schedule.createAsunoSchedule(group_id, body);
      const data = await api.schedule.getAsunoSchedules(group_id);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Удаление расписания АСУНО
export const delAsunoSchedule = createAsyncThunk<number, number, ThunkAPI>(
  "schedule/delAsunoSchedule",
  async (scheduleId, { extra: { api }, rejectWithValue }) => {
    try {
      await api.schedule.delAsunoSchedule(scheduleId);

      return scheduleId;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Установить / отменить указанное расписание в переданных группах светильников.
export const setScheduleForLampGroups = createAsyncThunk<
  null,
  { modifier: Record<"set" | "unset", string[]>; scheduleId: number },
  ThunkAPI
>(
  "schedule/setScheduleForLampGroups",
  async ({ scheduleId, modifier }, { extra: { api }, rejectWithValue }) => {
    const body = JSON.stringify(modifier);

    try {
      await api.schedule.setScheduleForLampGroups(scheduleId, body);

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получить ID групп светильников с указанным расписанием
export const getLampGroupsWithThisSchedule = createAsyncThunk<
  string[],
  number,
  ThunkAPI
>(
  "schedule/getLampGroupsWithThisSchedule",
  async (scheduleId, { extra: { api }, rejectWithValue }) => {
    try {
      const data = await api.schedule.getLampGroupsWithThisSchedule(scheduleId);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
