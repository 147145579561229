import type { AppDispatch } from "app/store";

//utils
import { history } from "utils/history";

//actions
import { lightingLineGroupsSlice } from "feat/objects/reducers/lightingLineGroupsSlice";
import { lightingLineSlice } from "feat/indications/reducers/lightingLineSlice";
import { mobileMenuSlice } from "common/reducers";
import { asunoCheckedSlice } from "common/reducers";
const { setAsunoChecked } = asunoCheckedSlice.actions;
const { setCurrentGroup } = lightingLineGroupsSlice.actions;
const { setMobileMenuOpen } = mobileMenuSlice.actions;
const { cleanLinesState } = lightingLineSlice.actions;

export const setRootPage = (dispatch: AppDispatch, wsClose: () => void) => {
  history.push("/");
  wsClose();
  dispatch(setCurrentGroup({}));
  dispatch(cleanLinesState());
  dispatch(setAsunoChecked(false));
  dispatch(setMobileMenuOpen(false));
};
