// components
import Icon from "@ant-design/icons";

interface IProps {
  color: string;
  relay: boolean;
}

export const LampIcon: React.FC<IProps> = ({ color, relay }) => {
  if (!relay) {
    return (
      <Icon
        component={() => (
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path
                d="M22 10.5C22 14.6421 18.6421 18 14.5 18C10.3579 18 7 14.6421 7 10.5C7 6.35786 10.3579 3 14.5 3C18.6421 3 22 6.35786 22 10.5Z"
                fill="#D9D9D9"
              />
              <path
                d="M14.5 19.5C19.4706 19.5 23.5 15.4706 23.5 10.5C23.5 5.52944 19.4706 1.5 14.5 1.5C9.52944 1.5 5.5 5.52944 5.5 10.5C5.5 15.4706 9.52944 19.5 14.5 19.5Z"
                stroke="#A5A2A2"
                strokeWidth="3"
              />
            </g>
            <line
              x1="9.61589"
              y1="16.6799"
              x2="19.6159"
              y2="4.67991"
              stroke="#FA0303"
            />
          </svg>
        )}
      />
    );
  }

  return (
    <Icon
      component={() => (
        <svg
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="14.5" cy="10.5" r="7.5" fill={color} />
          <circle cx="14.5" cy="10.5" r="9" stroke="black" strokeWidth="3" />
        </svg>
      )}
    />
  );
};
