import { createAsyncThunk } from "@reduxjs/toolkit";
import { intl } from "lib/locale";

// types
import { ThunkAPI, ILines, ILineState } from "types";

// Получение списка линий освещения
export const getLightningLines = createAsyncThunk<
  ILines[],
  undefined,
  ThunkAPI
>(
  "objects/getLightningLines",
  async (_, { extra: { api }, rejectWithValue, getState }) => {
    const group_id = getState().lightingLineGroups.currentGroup.group_id;

    try {
      const data = await api.indications.getLightningLines(group_id);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Получение списка состоянии линии освещения
export const getLightningLinesState = createAsyncThunk<
  ILineState[],
  undefined,
  ThunkAPI
>(
  "objects/getLightningLinesState",
  async (_, { extra: { api }, rejectWithValue, getState }) => {
    const group_id = getState().lightingLineGroups.currentGroupId;

    try {
      const data = await api.indications.getLightningLinesState(group_id);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Формирование и создание отчёта показаний в формате .xlsx
export const createReportIndications = createAsyncThunk<
  null,
  undefined,
  ThunkAPI
>(
  "objects/createReportIndications",
  async (_, { extra: { api }, rejectWithValue, getState }) => {
    const { group_id, group_name } = getState().lightingLineGroups.currentGroup;

    const name = `${group_name} - ${intl.formatMessage({
      id: "indications.report-name",
      defaultMessage: "Current values",
    })}.xlsx`;

    try {
      const data = await api.indications.createReportIndications(group_id);

      var xlsxURL = window.URL.createObjectURL(data);
      let tempLink = document.createElement("a");
      tempLink.href = xlsxURL;
      tempLink.setAttribute("download", name);
      tempLink.click();

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// обновление списка состоянии линии освещения
// для страницы подстанций, работать в фоне
export const updateLightningLinesState = createAsyncThunk<
  ILineState[],
  undefined,
  ThunkAPI
>(
  "objects/updateLightningLinesState",
  async (_, { extra: { api }, rejectWithValue, getState }) => {
    const group_id = getState().lightingLineGroups.currentGroup.group_id;

    try {
      const data = await api.indications.getLightningLinesState(group_id);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
