import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";

// Получение списка светильников всей асуно
const getAsunoLamps = async (groupId: number) => {
  const resp = await request(`/lamps/asuno-lamps/${groupId}`, "GET");
  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

export const lamps = {
  getAsunoLamps,
};
