import { createSlice, current } from "@reduxjs/toolkit";

//actions
import {
  getUsers,
  getUserById,
  checkUserByEmail,
  updateUser,
} from "../actions";

//types
import { IError, IUser } from "types";

interface IInitialState {
  usersList: IUser[];
  profile: IUser | null;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  usersList: [],
  profile: null,
  loading: false,
  error: null,
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    cleanProfile: (state) => {
      state.profile = null;
    },
  },
  extraReducers: (builder) => {
    //Возвращает список пользователей.
    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.usersList = action.payload;
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //Возвращает пользователя по его id.
    builder.addCase(getUserById.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getUserById.fulfilled, (state, action) => {
      state.loading = false;
      state.profile = action.payload;
    });
    builder.addCase(getUserById.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //Возвращает пользователя по его email.
    builder.addCase(checkUserByEmail.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(checkUserByEmail.fulfilled, (state, action) => {
      state.loading = false;
      state.profile = action.payload;
    });
    builder.addCase(checkUserByEmail.rejected, (state) => {
      state.loading = false;
      state.error = "Произошла ошибка при загрузке";
    });

    //Редактируем профиль пользователя
    builder.addCase(updateUser.fulfilled, (state, action) => {
      const updated = action.payload;
      const user = current(state).profile;

      //@ts-ignore
      state.profile = { ...user, ...updated };

      if (action.payload?.roles?.length === 0) {
        state.usersList = current(state).usersList.filter(
          (user) => user.id !== action.payload.id
        );
      }
    });
  },
});
