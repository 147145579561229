import { makeStyles } from "@material-ui/styles";

// components
import { Typography, Empty, Space } from "antd";

// utils
import { EmeterType, ControlDeviceType } from "lib/enums";

// types
import { IDeviceInfo } from "types";

interface IProps {
  data: IDeviceInfo[];
}

const { Text } = Typography;

export const DeviceInfo: React.FC<IProps> = ({ data }) => {
  const classes = useStyles();

  if (!data.length) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  }

  const models = { ...EmeterType, ...ControlDeviceType };

  return (
    <div className={classes.wrapper}>
      {data.map(({ group_name, ll_name, device: { id, model } }) => (
        <Space direction="vertical">
          <Text>Идентификатор: {id}</Text>
          <Text>АСУНО: {group_name}</Text>
          <Text>ШУНО: {ll_name}</Text>
          <Text>Модель: {models[model]}</Text>
        </Space>
      ))}
    </div>
  );
};

const useStyles = makeStyles({
  wrapper: {
    marginTop: 24,
  },
});
