import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { intl } from "lib/locale";

// types
import { ThunkAPI, ILinesBurningHoursReport, ILineRequest } from "types";

// Получение списка линий освещения
export const getBurningHoursData = createAsyncThunk<
  ILinesBurningHoursReport[],
  ILineRequest,
  ThunkAPI
>(
  "objects/getBurningHoursData",
  async ({ from, to, lines }, { extra: { api }, rejectWithValue }) => {
    const body = JSON.stringify({
      from,
      to,
      lines,
    });

    try {
      const data = await api.burningHours.getBurningHoursData(body);

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Формирование и создание отчёта по часам горения в формате .xlsx
export const сreateReportBurningHours = createAsyncThunk<
  null,
  ILineRequest,
  ThunkAPI
>(
  "objects/сreateReportBurningHours",
  async (
    { lines, from, to },
    { extra: { api }, rejectWithValue, getState }
  ) => {
    const { group_name } = getState().lightingLineGroups.currentGroup;
    const body = JSON.stringify({
      from,
      to,
      lines,
    });

    const name = `${group_name} - ${intl.formatMessage({
      id: "app.burning-hours",
      defaultMessage: "Burning hours",
    })} ${moment(from).format("DD.MM.YYYY")}-${moment(to).format(
      "DD.MM.YYYY"
    )}.xlsx`;

    try {
      const data = await api.burningHours.сreateReportBurningHours(body);

      var xlsxURL = window.URL.createObjectURL(data);
      let tempLink = document.createElement("a");
      tempLink.href = xlsxURL;
      tempLink.setAttribute("download", name);
      tempLink.click();

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
