import { createSlice } from "@reduxjs/toolkit";

// actions
import {
  getLineConfiguration,
  getConnectionFields,
  getReplacementFields,
} from "../actions";

// types
import { IError, Maybe, IConnectionFields, IConfiguration } from "types";

interface IInitialState {
  lineConfiguration: Maybe<IConfiguration>;
  connectionFields: Maybe<IConnectionFields>;
  loadingConnectionFields: boolean;
  loading: boolean;
  error: IError;
}

const initialState: IInitialState = {
  lineConfiguration: null,
  connectionFields: null,
  loadingConnectionFields: false,
  loading: false,
  error: null,
};

export const lineConfigurationSlice = createSlice({
  name: "lineConfiguration",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Получить конфигурацию группы
    builder.addCase(getLineConfiguration.pending, (state) => {
      state.loading = true;
      state.lineConfiguration = null;
      state.error = null;
    });
    builder.addCase(getLineConfiguration.fulfilled, (state, action) => {
      state.loading = false;
      state.lineConfiguration = action.payload;
    });
    builder.addCase(getLineConfiguration.rejected, (state) => {
      state.loading = false;
      state.lineConfiguration = null;
      state.error = "Произошла ошибка при загрузке";
    });

    //  Получить поля, которые необходимо заполнить для подключения устройств по данной конфигурации
    builder.addCase(getConnectionFields.pending, (state) => {
      state.loadingConnectionFields = true;
      state.connectionFields = null;
      state.error = null;
    });
    builder.addCase(getConnectionFields.fulfilled, (state, action) => {
      state.loadingConnectionFields = false;
      state.connectionFields = action.payload;
    });
    builder.addCase(getConnectionFields.rejected, (state) => {
      state.loadingConnectionFields = false;
      state.connectionFields = null;
      state.error = "Произошла ошибка при загрузке";
    });

    // Получить поля, которые необходимо заполнить для замены устройств по данной конфигурации
    builder.addCase(getReplacementFields.pending, (state) => {
      state.loadingConnectionFields = true;
      state.connectionFields = null;
      state.error = null;
    });
    builder.addCase(getReplacementFields.fulfilled, (state, action) => {
      state.loadingConnectionFields = false;
      state.connectionFields = action.payload;
    });
    builder.addCase(getReplacementFields.rejected, (state) => {
      state.loadingConnectionFields = false;
      state.connectionFields = null;
      state.error = "Произошла ошибка при загрузке";
    });
  },
});
