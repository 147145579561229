import { request } from "lib/api";
import { handleErrors } from "lib/handleErrors";

// Получение списка линий освещения
const getLightningLines = async (group_id: number) => {
  const resp = await request(`/v1/lighting-line?group_id=${group_id}`, "GET");

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Получение списка состоянии линии освещения
const getLightningLinesState = async (group_id: number) => {
  const resp = await request(
    `/v1/lighting-line-state?group_id=${group_id}`,
    "GET"
  );

  if (!resp.ok) {
    handleErrors(resp);
  }
  const data = await resp.json();
  return data;
};

// Формирование и создание отчёта показаний в формате .xlsx
const createReportIndications = async (group_id: number) => {
  const resp = await request(
    `/v1/get-report-indications?group_id=${group_id}`,
    "GET"
  );

  if (!resp.ok) {
    handleErrors(resp);
  }

  const data = await resp.blob();
  return data;
};

export const indications = {
  getLightningLines,
  getLightningLinesState,
  createReportIndications,
};
