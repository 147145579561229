import { createAsyncThunk } from "@reduxjs/toolkit";

// actions
import { getCurrentUser } from "feat/users/actions";

// utils
import { history } from "utils/history";

// types
import { IUserLogin, ThunkAPI } from "types";

export const login = createAsyncThunk<any, IUserLogin, ThunkAPI>(
  "auth/login",
  async (
    { email, password },
    { extra: { api }, rejectWithValue, dispatch }
  ) => {
    try {
      const data = await api.auth.login({ email, password });
      localStorage.setItem("user", email);

      await dispatch(getCurrentUser());

      history.push("/");

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk<any, undefined, ThunkAPI>(
  "auth/logout",
  async (_, { extra: { api }, rejectWithValue }) => {
    try {
      await api.auth.logout();

      history.push("/login");

      return null;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
