import classNames from "classnames";
import { makeStyles } from "@material-ui/styles";

interface IProps {
  link: number;
}

export const LinkLevelIcon: React.FC<IProps> = ({ link }) => {
  const classes = useStyles();

  return (
    <div className={classes.icon}>
      <span
        className={classNames(classes.bar, classes.bar1, {
          [classes.good]: link > 0,
        })}
      ></span>
      <span
        className={classNames(classes.bar, classes.bar2, {
          [classes.good]: link > 5,
        })}
      ></span>
      <span
        className={classNames(classes.bar, classes.bar3, {
          [classes.good]: link > 14,
        })}
      ></span>
      <span
        className={classNames(classes.bar, classes.bar4, {
          [classes.good]: link > 29,
        })}
      ></span>

      <span className={classes.connection}>2G</span>
    </div>
  );
};

const useStyles = makeStyles({
  icon: {
    width: 40,
    height: 33,
    padding: 4,
    marginRight: 5,
  },
  bar: {
    opacity: "0.2",
    display: "inline-block",
    width: 6,
    marginLeft: 2,
    transformOrigin: "100% 100%",
    backgroundColor: "#00cc00",
    borderRadius: "2px",
  },
  bar1: {
    height: "25%",
  },
  bar2: {
    height: "50%",
  },
  bar3: {
    height: "75%",
  },
  bar4: {
    height: "100%",
  },
  good: {
    opacity: 1,
  },

  connection: {
    position: "relative",
    bottom: "38px",
    fontSize: "8px",
    fontWeight: 600,
  },
});
